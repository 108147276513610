import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';
import cookie from 'react-cookies';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import config from "../../config";



class InactivateBtn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      favorite: false,
      sale: [],
      rent:[],
    };
  }
  inactiveFunction = () => {
    const data = { product_id: this.props.id,status:this.props.status };
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#fda94f',
      cancelButtonColor: '#0e4b46',
      confirmButtonText: `Yes, ${this.props.text}!`,
    }).then((result) => {
      if (result.isConfirmed) {
         fetch(config.URL+"product/"+this.props.endpoint, {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': cookie.load('Tokken'),

            },
            body: JSON.stringify(data)
          }).then((result) => {
            Swal.fire(
              this.props.AlertTitle,
              this.props.AlertText,
              'success'
            ).then(()=>{
              window.location.href = "/account"
            })
          })
        
      }
    })          
  }

  render() {

    let publicUrl = ''
    let imagealt = 'image'


    return <>
    {this.props.endpoint=="propertyStatusChange"?
      <i style={{cursor:"pointer"}} className="fa fa-power-off" onClick={()=>{this.inactiveFunction()}}
       />
      :
      <button style={{ borderRadius: "25px", width: "100%" }} 
              type="button" class="btn btn-yellow mt-2" 
              onClick={()=>{this.inactiveFunction()}}>
                {this.props.text}
      </button>
      }

    </>

  }
}

export default InactivateBtn