import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';
import { TextField, Checkbox } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import Card from '@mui/material/Card';
import House from "../../assets/img/houseimage.png";
import config from "../../config";



const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#fda94f',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#fda94f',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#e5e5e9',
    },
    '&:hover fieldset': {
      borderColor: '#fda94f',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#fda94f',
    },
  },
});

class contact extends Component {

  constructor(props) {
    super(props);

    this.state = {
      rent: false,
      property_Type: 0,
      house_title:"",
      short_description:"",
      explanation:"",
      price:"",
      bed_rooms: 0,
      bathrooms: 0,
      area: 0,
      permit: 0,
      rara: 0,
      reference: 0,
      country:"",
      state:"",
      city:"",
      zip_code: 0,
    };
  }

   


  render() {

    let publicUrl = '' + '/'
    let imagealt = 'image'
    let data = sectiondata.aboutus

function saving_data() {
  alert("hi")
}
    return <div className="add-new-property-area  ">
      
      <div className="container " style={{maxWidth:"990px",paddingTop:"20px"}}>
      <div className='row '>
          <div className='col-3 col-sm-1 p-0'>
          <img src={House} width="100" style={{}} />
          </div>
          <div className='col-9 p-0'>
            <h3>Want to post your listings on mulqiat?</h3>
            <p>Join the UAE’s only agency backed property portal</p>
          </div>
      </div>
        <Card className="row justify-content-center" style={{backgroundColor:"#f9f9f9",paddingBottom:"50px",paddingTop:"50px"}}>
          <div className="col-xl-9 col-lg-10">
            <div className="row ">
              <div className="col-md-12" style={{paddingLeft:"50px"}}>
                <div className="section-title">
                  <CssTextField style={{backgroundColor:"white"}} fullWidth label="Your name" id="custom-css-outlined-input" onChange={(e) => {
                              this.setState({ house_title: e.target.value },console.log(e.target.value))
                            }} />
                  <CssTextField style={{backgroundColor:"white"}} fullWidth label="Company Name" id="custom-css-outlined-input" margin="dense" onChange={(e) => {
                              this.setState({ short_description: e.target.value },console.log(e.target.value))
                            }}/>
                  <CssTextField style={{backgroundColor:"white"}} fullWidth label="Email" id="custom-css-outlined-input" margin="dense" onChange={(e) => {
                              this.setState({ short_description: e.target.value },console.log(e.target.value))
                            }}/>
                  <CssTextField style={{backgroundColor:"white"}} fullWidth label="Phone" id="custom-css-outlined-input" margin="dense" onChange={(e) => {
                              this.setState({ short_description: e.target.value },console.log(e.target.value))
                            }}/>
                  <CssTextField style={{backgroundColor:"white"}} multiline rows={5} fullWidth label="Message" id="custom-css-outlined-input" margin="dense" onChange={(e) => {
                              this.setState({ explanation: e.target.value },console.log(e.target.value))
                            }}/>
                  


                </div>
               
              </div>
              <div className="col-12 ">
                    <button className="btn btn-yellow" style={{marginLeft:"35px"}} onClick={()=>{
                      const data = this.state
                      console.log(data)
                       fetch(config.URL+"/product/",{
                        method:'POST',
                        headers:{
                          'Accept':'application/json',
                          'Content-Type':'application/json',
                        },
                        body:JSON.stringify(data)
                      }).then((result)=>{
                        console.log(result)
                      })
                    }} >Send</button>
                  </div>
            </div>
            
          </div>
        </Card>
      </div>
    </div>

  }
}

export default contact