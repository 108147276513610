// import Cookies from "js-cookie";
import cookie from 'react-cookies'
import config from "../config";

const UploadMedia = async (data) => {
  console.warn("uploadMedia: ",data);
  return new Promise((resolve, reject) => {
    var path = "product/upload-property-images";
    var type = "post";

    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      cookie.load('Tokken')
    );

    var formdata = new FormData();
    formdata.append(
      "media",
      data,
      "WhatsApp Video 2021-05-19 at 12.54.25 AM.jpg"
    );

    var requestOptions = {
      method: type,
      headers: myHeaders,
      body: formdata
    };

    fetch(config.URL + path, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        resolve(result.data)
      })
      .catch((error) => { console.log("error", error); reject(error) });
  })

};


export default {
  UploadMedia,
};
