import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';
import { TextField, Checkbox } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import main from "./../../video/main.mp4";
import style from "../../assets/css/style.css";
import AuthHelper from '../../Helpers/AuthHelper';
import Button from '@mui/material/Button';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import cookie from 'react-cookies';
import { Link } from 'react-router-dom';
import config from "../../config";





const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#fda94f',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#fda94f',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#e5e5e9',
    },
    '&:hover fieldset': {
      borderColor: '#fda94f',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#fda94f',
    },
  },
});

class forgetComponent extends Component {

  constructor(props) {
    super(props);

    this.state = {
      agency: false,
      email: '',
      messages: '',
      loading: false

    };
  }























  forget = (endpoint) => {
    this.setState({ loading: true })

    fetch(config.URL + "users/forget/" + endpoint, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },

      body: JSON.stringify({ email: this.state.email })
    })
      .then((result) => {
        result.json().then((res) => {
          console.log(res)
          if (res.status == "success") {
            this.Alert("success", "Password reset successfully", `We're sending you the new password on ${res.email}.Check your email.`)
          }
          else {
            this.setState({ loading: false });
            this.Alert("error", "Oops...", res.status)

          }


        })
      })

  };

  Alert = (icon, title, msg) => {
    Swal.fire({
      position: 'center',
      icon: icon,
      title: title,
      text: msg,
      showConfirmButton: true,
      width: "500px",

    }).then((result)=>{
      if (result.isConfirmed) {
        if (icon=="success"){
          window.location.href = "/login"
        }

      }
    })
  }





  render() {

    let publicUrl = '' + '/'
    let imagealt = 'image'
    let data = sectiondata.aboutus
    const Input = styled('input')({
      display: 'none',
    });



    return <div className='log_background_image'>
      <div className="col-xl-4 col-lg-5 col-md-6" style={{ marginLeft: "auto", paddingTop: "100px" }}>
        <div className="contact-form-wrap " style={{ marginTop: "150px", marginBottom: '150px' }}>
          <div className="row ">
            <div className="col-lg-6 col-12 pt-2"><h4>Recover As</h4></div>
            <div className="col-lg-3 col-5 mb-3">
              <a className={`btn ${this.state.agency == false ? "btn-yellow" : "btn-yellow-outline"} float-right text-white ml-2`} onClick={() => this.setState({ agency: false })}>User</a>
            </div>
            <div className="col-lg-3 col-5 mb-3">
              <a className={`btn ${this.state.agency == true ? "btn-yellow" : "btn-yellow-outline"} float-right text-white`} onClick={() => this.setState({ agency: true })}>Agency</a>
            </div>
          </div>


          <div className="col-md-12">
            <div className="section-title">
              <CssTextField fullWidth label="Email" id="custom-css-outlined-input" onChange={(e) => {
                this.setState({ email: e.target.value }, console.log(e.target.value))
              }} />

            </div>
          </div>
          <div>
            <div className="btn-wrap " style={{ textAlign: "center" }}>
              {this.state.loading == true ?
                <button className="btn btn-yellow" disabled>
                  <i class="fa fa-refresh fa-spin"></i> Loading...
                </button>
                :
                <button
                  className="btn btn-yellow"
                  style={{ width: "330px", margin: '0px' }}
                  onClick={() => this.forget(this.state.agency == false ? "user" : "agency")}
                >
                  Recover My Password
                </button>
              }
              

            </div>
          </div>

        </div>
      </div>
    </div>

  }
}

export default forgetComponent