import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Link } from 'react-router-dom';
import 'react-tabs/style/react-tabs.css';
import Flip from 'react-reveal/Flip';
import Fade from 'react-reveal/Fade';


class PropertiesByCities extends Component {


  render() {

    let publicUrl = ''
    let imagealt = 'image'
    let data = sectiondata.propertiesbycities

    const inlineStyle = {
      backgroundImage: 'url(' + publicUrl + '/assets/img/bg/2.png)'
    }

    return <div className="city-intro-area pd-bottom-70" style={inlineStyle}>

      <div className="intro-area pd-top-70">
        <div className="container">
          <div className="row">
            <Flip top left>
            <div className="col-md col-sm-6-6">
              <a className={"single-intro-media media single-intro-media-active"}>
                <div className="media-left">
                  <img src={publicUrl + "assets/img/icons/9.png"} alt={imagealt} />
                </div>
                <div className="media-body">
                  <h4>Looking for the new home?</h4>
                  <p>10 new offers every day. 350 offers on site, trusted by a community of thousands of users.</p>
                </div>
              </a>
            </div>
            </Flip>
            <Flip top right>
            <div className="col-md col-sm-6-6">
              <a className={"single-intro-media media "}>
                <div className="media-left">
                  <img src={publicUrl + "assets/img/icons/10.png"} alt={imagealt} />
                </div>
                <div className="media-body">
                  <h4>Want to sell your home?</h4>
                  <p>10 new offers every day. 350 offers on site, trusted by a community of thousands of users.</p>
                </div>
              </a>
            </div>
            </Flip>
            {/* {data.intro.map( ( item, i )=>
                 <div key={ i } className="col-md col-sm-6-6">
                    <a  className={"single-intro-media media "+item.class}>
                      <div className="media-left">
                        <img src={ publicUrl+item.icon } alt={ imagealt } />
                      </div>
                      <div className="media-body">
                        <h4>{ item.title }</h4>
                        <p>{ item.content }</p>
                      </div>
                    </a>
                  </div>
              )} */}

          </div>
        </div>
      </div>
      {/* intro area start */}
      {/* city area start */}
      {/* <div className="city-area pd-top-92">
        <div className="container">
          <div className="section-title text-center">
            <h2 className="title">{data.title}</h2>
          </div>
          <Tabs>
            <TabList className="react-tabs__tab-list" >
              <div className="row px-3">

                <Tab className="col-sm-2 col-6 btn" ><div class="" ><b >Dubai</b></div></Tab>

                <Tab className="col-sm-2 col-6 btn" ><div class="" ><b>Abu Dhabi</b></div></Tab>
              </div>
            </TabList>

            <TabPanel>
              <div className="city-filter-area row">
                <div className="city-sizer " />

                {data.items.map((item, i) =>
                  <div key={i} className={"rld-city-item col-lg-4 col-sm-6"}>
                    <Fade left>
                    <div className={"single-city "}>
                      <div className="sc-overlay" />
                      <div className="thumb-img">
                        <img src={publicUrl + item.image} alt={imagealt} />
                      </div>
                      <div className="details">
                        <h5 className="title"><Link to={item.url}>{item.city}</Link></h5>
                        <p>{item.content}</p>
                        <p>{item.content}</p>
                      </div>
                    </div>
                    </Fade>
                  </div>
                )
                }

              </div>
            </TabPanel>
            <TabPanel>

              <div className="city-filter-area row">
                <div className="city-sizer " />

                {data.items_2.map((item, i) =>
                  <div key={i} className={"rld-city-item col-lg-4 col-sm-6"}>
                    <Fade right>
                    <div className={"single-city "}>
                      <div className="sc-overlay" />
                      <div className="thumb-img">
                        <img src={publicUrl + item.image} alt={imagealt} />
                      </div>
                      <div className="details">
                        <h5 className="title"><Link to={item.url}>{item.city}</Link></h5>
                        <p>{item.content}</p>
                      </div>
                    </div>
                    </Fade>
                  </div>
                )
                }

              </div>

            </TabPanel>
          </Tabs>

        </div>
      </div> */}

    </div>


  }
}

export default PropertiesByCities