import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';


class RecomandProperties extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data
    }
    console.info(JSON.parse(this.props.filter));
  };

  render() {

    let publicUrl = '' + '/'
    let imagealt = 'image'
    let data = sectiondata.recinabdproperties

    return <div className="recommended-area pd-bottom-70">
      <div className="container">
        <div className="section-title">
          <h2 className="title">{data.title}</h2>
          <a className="btn-view-all" href="">View All</a>
        </div>
        <div className="row">
          {this.state.data.map((item, i) => (
            <div key={i} className="col-xl-3 col-sm-6">
              <div className="single-feature">
                <div className="thumb">
                  <img src="https://images-cdn2.houza.com/images/properties/2021/12/06/AxJyEBFwb4_sKHh.jpg?profile=xs" alt="img" />
                </div>
                <div className="details">
                  <a href="" className="feature-logo">
                    <img src="https://images-cdn2.houza.com/images/properties/2021/12/06/AxJyEBFwb4_sKHh.jpg?profile=xs" alt={imagealt} />
                  </a>
                  <p className="author"><i className="fa fa-user" /> {item.user.name}</p>
                  <p className="location"><Link to={{
                    pathname: `/property-details/${item._id}/${(this.props.filter)}`,
                    query: item
                  }}>{item.location}</Link></p>
                  <h6 className="title readeal-top"><Link to={{
                    pathname: `/property-details/${item._id}/${(this.props.filter)}`,
                    query: item
                  }}>{item.title}</Link></h6>
                  <h6 className="price">AED {item.price}</h6><del>{item.olderprice}</del>
                  <ul className="info-list">
                    <li ><i className={"fa fa-bed"} /> {item.bed} Bed</li>
                    <li ><i className={"fa fa-bath"} /> {item.bath} Bath</li>
                    <li><img src={publicUrl + "assets/img/icons/7.png"} alt={imagealt} /> {item.area}  sq. </li>
                  </ul>
                  <ul className="contact-list">
                    <li><a className="phone" href=""><i className="fa fa-phone" /></a></li>
                    <li><a className="message" href=""><img src={publicUrl + "assets/img/icons/8.png"} alt="img" /></a></li>
                    <li className="readeal-top"><Link className="btn btn-yellow"
                      to={{
                        pathname: `/property-details/${item._id}/${(this.props.filter)}`,
                        query: item
                      }}
                    >View Details</Link></li>
                  </ul>
                </div>

              </div>
            </div>
          ))}
        </div>
      </div>
    </div>

  }
}

export default RecomandProperties