import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';
import Banner from '../../assets/img/welcomebanner.jpg';
import Magane from '../../assets/img/manage.svg';
import Stars from '../../assets/img/stars.svg';
import Profile from '../../assets/img/profiles.svg';
import Leads from '../../assets/img/leads.svg';
import Mobile from '../../assets/img/mobile_mockup.png';
import MobileLeft from '../../assets/img/mobile_mockup_left.png';



class HubComponent extends Component {

  constructor(props) {
    super(props);


  }




  render() {

    let publicUrl = '' + '/'
    let imagealt = 'image'
    let data = sectiondata.aboutus

    return <div className="add-new-property-area pd-top-90 ">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-9 col-lg-10">
            
            <div className="section-title text-center">
              <h3 style={{ marginTop: "15px" }}>The Mulqiat Hub</h3>
            </div>
            <div className='row'>
              <div className='col-12' style={{ backgroundColor: "#f9f9f9", borderRadius: "80px" }}>
                <div style={{ padding: "25px 35px" }}>
                  <div className='row' >
                    <div className='col-12 col-sm-3 ' >
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <div >
                          <div style={{ display: "flex", justifyContent: "center" }}>
                            <img src={Magane} />
                          </div>
                          <p >Manage all listings</p>
                        </div>
                      </div>
                    </div>
                    <div className='col-12 col-sm-3 ' >
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <div>
                          <div style={{ display: "flex", justifyContent: "center" }}>
                            <img src={Stars} />
                          </div>
                          <p >Maximise listing exposure</p>
                        </div>
                      </div>
                    </div>
                    <div className='col-12 col-sm-3 ' >
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <div>
                          <div style={{ display: "flex", justifyContent: "center" }}>
                            <img src={Profile} />
                          </div>
                          <p >Complete buyer profiles</p>
                        </div>
                      </div>
                    </div>
                    <div className='col-12 col-sm-3 ' >
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <div>
                          <div style={{ display: "flex", justifyContent: "center" }}>
                            <img src={Leads} />
                          </div>
                          <p >View leads in real time</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12'>
                <div style={{padding:"50px 156px"}}>
                  <div className='row'>
                    <div className='col-5'>
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <img src={Mobile} width={200} />
                      </div>
                    </div>
                    <div className='col-7'>
                      <h3>Manage all listings</h3>
                      <p>We start with the basics. Under the properties tab,
                        view all properties in your feed to see which are live on
                        Mulqiat, listings which are inactive or those with errors
                        which need rectifying. Here you also have the ability
                        to see a high level view on how this property is
                        performing on Mulqiat.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12'>
                <div style={{padding:"50px 156px"}}>
                  <div className='row'> 
                    <div className='col-7'>
                      <h3>Maximise listing performance</h3>
                      <p>Impressions lead to clicks and clicks lead to
                         inquiries. As you click into each property, we show
                         you exactly how your properties are performing with
                         a 5-star ranking against similar properties on Mulqiat.
                         By clicking on each metric, we give you clear steps 
                         on how to improve them and take your listing to the 
                         next level. The higher your star count, the higher you 
                         will rank on Mulqiat.</p>
                    </div>
                    <div className='col-5'>
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <img src={MobileLeft} width={200} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12'>
                <div style={{padding:"50px 156px"}}>
                  <div className='row'>
                    <div className='col-5'>
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <img src={Mobile} width={200} />
                      </div>
                    </div>
                    <div className='col-7'>
                      <h3>Unique transaction data</h3>
                      <p>Showing you the data that matters. 
                        By scrolling down on a property 
                        page, we show you price trends and 
                        transaction data. With sales 
                        and rental trend data dating back 12 
                        months, and previous sales 
                        transactions, the hub allows you 
                        manage your clients pricing 
                        expectations more accurately. Like 
                        what you see? Simply screenshot 
                        and send it to your clients.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12'>
                <div style={{padding:"50px 156px"}}>
                  <div className='row'> 
                    <div className='col-7'>
                      <h3>View leads in real time</h3>
                      <p>Minimise the delay in responding to your 
                         inquiries. With instant sync, you can now access 
                         rent and buyer details immediately by seeing 
                         missed calls, listening to call recordings and 
                         viewing all email leads. Under the ‘leads’ section, 
                         you can view contact cards and understand a 
                         property seekers behaviour and properties they 
                         have made leads on. You can only see your 
                         properties or properties within your branch.</p>
                    </div>
                    <div className='col-5'>
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <img src={MobileLeft} width={200} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12'>
                <div style={{padding:"50px 156px"}}>
                  <div className='row'>
                    <div className='col-5'>
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <img src={Mobile} width={200} />
                      </div>
                    </div>
                    <div className='col-7'>
                      <h3>On the go</h3>
                      <p>The Mulqiat hub is a mobile-first experience. 
                        Bookmark the Mulqiat hub webpage on your 
                        phone to enable easy access to the hub, 
                        whenever you need it. The hub will appear as 
                        an app on your home screen.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12'>
                <div style={{padding:"0px 156px"}}>
                  <div className='row'>
                    <div className='col-12'>
                      <h3>Ready to get started?</h3>
                    </div>
                    <div className='col-12'>
                      <div style={{padding:"20px 160px"}}>
                      <button type="button" class="btn btn-primary btn-lg btn-block" style={{borderRadius:"80px",backgroundColor:"#fda94f"}}>Login</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>



          </div>
        </div>
      </div>
    </div>

  }
}

export default HubComponent