// import Cookies from "js-cookie";
import cookie from 'react-cookies'
import config from "../config";

const jwt = require('jsonwebtoken');

const Create = async (data) => {
  return new Promise((resolve, reject) => {
    var path="product/"
    var type='POST'
    fetch(config.URL + path, {
      method: type,
      headers:{"Content-Type":"application/json","Authorization": cookie.load('Tokken')},
      body:JSON.stringify(data)
    })
      .then((res) => res.json())
      .then(async (resjson) => {
        resolve({
          status: true,
          data: resjson
        });
      })
      .catch((err) => {
        console.log("failed", err);
      });
  });
};

const Get = async (id) => {
  return new Promise((resolve, reject) => {
    var path=`product/`
    var type='GET'
    fetch(config.URL + path, {
      method: type,
      headers:{"Content-Type":"application/json","Authorization": cookie.load('Tokken')},
    })
      .then((res) => res.json())
      .then(async (resjson) => {
        console.warn(2000);
        resolve({
          status: true,
          data: resjson
        });
      })
      .catch((err) => {
        console.log("failed", err);
      });
  });
};

const GetById = async (id) => {
  return new Promise((resolve, reject) => {
    var path="product/"+id
    var type='GET'
    fetch(config.URL + path, {
      method: type,
      headers:{"Content-Type":"application/json","Authorization": cookie.load('Tokken')},
    })
      .then((res) => res.json())
      .then(async (resjson) => {
        resolve({
          status: true,
          data: resjson
        });
      })
      .catch((err) => {
        console.log("failed", err);
      });
  });
};

const propertyByAgency = async (data) => {
  return new Promise((resolve, reject) => {
    var path="product/property-by-agency/"
    var type='POST'
    fetch(config.URL + path, {
      method: type,
      body:JSON.stringify(data),
      headers:{"Content-Type":"application/json","Authorization": cookie.load('Tokken')},
    })
      .then((res) => res.json())
      .then(async (resjson) => {
        resolve({
          status: true,
          data: resjson
        });
      })
      .catch((err) => {
        console.log("failed", err);
      });
  });
};

const Update = async (id,data) => {
  return new Promise((resolve, reject) => {
    var path="product/"+id
    var type='PATCH'
    fetch(config.URL + path, {
      method: type,
      headers:{"Content-Type":"application/json","Authorization": cookie.load('Tokken')},
      body:JSON.stringify(data)

    })
      .then((res) => res.json())
      .then(async (resjson) => {
        resolve({
          status: true,
          data: resjson
        });
      })
      .catch((err) => {
        console.log("failed", err);
      });
  });
};

const Delete = async (id,data) => {
  return new Promise((resolve, reject) => {
    var path="product/"+id
    var type='DELETE'
    fetch(config.URL + path, {
      method: type,
      headers:{"Content-Type":"application/json","Authorization": cookie.load('Tokken')},

    })
      .then((res) => res.json())
      .then(async (resjson) => {
        resolve({
          status: true,
          data: resjson
        });
      })
      .catch((err) => {
        console.log("failed", err);
      });
  });
};

const FilterProduct = async (data) => {
  
  return new Promise((resolve, reject) => {
    var path="product/findProducts"
    var type='post'
    fetch(config.URL + path, {
      method: type,
      headers:{"Content-Type":"application/json","Authorization": cookie.load('Tokken')},
      body:JSON.stringify(data)
    })
      .then((res) => res.json())
      .then(async (resjson) => {
        console.log("Response ",resjson);
        if(resjson.status!="fail"){
          resolve({
            status: true,
            data: resjson
          });
        }else{
          reject({
            status:false,
            msg: resjson.message
          })
        }
        
      })
      .catch((err) => {
        console.log("failed", err);
      });
  });
};
const RecommendedFilterProduct = async (data) => {
  
  return new Promise((resolve, reject) => {
    var path="product/recommendedProduct"
    var type='post'
    fetch(config.URL + path, {
      method: type,
      headers:{"Content-Type":"application/json","Authorization": cookie.load('Tokken')},
      body:JSON.stringify(data)
    })
      .then((res) => res.json())
      .then(async (resjson) => {
        if(resjson.status!="fail"){
          resolve({
            status: true,
            data: resjson
          });
        }else{
          reject({
            status:false,
            msg: resjson.message
          })
        }
        
      })
      .catch((err) => {
        console.log("failed", err);
      });
  });
};


export default {
  Create,
  Get,
  Update,
  Delete,
  GetById,
  FilterProduct,
  RecommendedFilterProduct,
  propertyByAgency
};
