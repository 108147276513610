import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import SearchGridSection from './section-components/search-grid';
import SearchListSection from './section-components/search-list';
import Footer from './global-components/footer';
import main from "./../assets/img/BuyBanner.jpeg"
import { useEffect,useMemo  } from 'react';
import { useParams } from "react-router-dom";



const SearchGrid = (props) => {
   
      
    
    
    const { type } = useParams();
    return <div>
        {console.log(props.location)}
        <Navbar  /> 
        <PageHeader video={main} headertitle="Properties" />
        <SearchGridSection  data={props.location.state}/>
        {/* <SearchListSection data={props.location.state}/> */}
        <Footer />
    </div>
}

export default SearchGrid

