import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import AuthHelper from '../../Helpers/AuthHelper';
import cookie from 'react-cookies'
import parse from 'html-react-parser';
import  { Redirect } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

class Registration extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      name:'',
      email:'',
      email_login:'',
      phone:'',
      password_login:'',
      c_password:'',
      messages:'',
    };
  }
  handleLogin = () => {
    var data = {
      email: this.state.email_login,
      password: this.state.password_login,
    };
    AuthHelper.Login(data).then((resp) => {
      if (resp.data.status != "success") {
        this.setState({ messages: resp.data.status });
        alert(resp.data.msg)
      }else {
        cookie.save('Tokken', resp.data.data.tokken , { path: '/' })
        cookie.save('user_id', resp.data.data.user_id , { path: '/' })
        cookie.save('user_name', resp.data.data.user_name , { path: '/' })         
      }
    });
    
  };
  hendalRegister= () => {

    if (this.state.password != this.state.c_password) {
      this.setState({ messages: 'password dose not match' });
      return false;
    }
    var data = {
      name:this.state.name,
      email:this.state.email,
      phone:this.state.phone,
      password:this.state.password
    };
    AuthHelper.Signup(data).then((resp) => {
      if (resp.data.status != "success" && resp.data.status !=undefined ) {
        console.log(resp.data.status);
        let str=JSON.stringify(resp.data.status)
         str = str.replace(/"/g, " ");
         str = str.replace(/`/g, " ");
        str = str.substring(1, str.length - 1);
        this.setState({ messages: str });
      } else {
        this.setState({ messages: 'Successfully Registered' });

      }
    });

  };
    render() {

        let publicUrl = ''
        let imagealt = 'image'
        let data = sectiondata.whychooseus


    return <div className="register-page-area pd-bottom-100">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-4 col-lg-5 col-md-6 mb-5 mb-md-0">
                <div className="contact-form-wrap contact-form-bg">
                  <h4>Login</h4>
                  <div className="rld-single-input">
                    <input onChange={(e)=>this.setState({email_login:e.target.value})} type="text" placeholder="Entry Login" />
                  </div>
                  <div className="rld-single-input">
                    <input onChange={(e)=>this.setState({password_login:e.target.value})} type="password" placeholder="Entry Password" />
                  </div>
                  <div className="btn-wrap">
                    <button onClick={()=>this.handleLogin()} className="btn btn-yellow">Sign In</button>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-5 col-md-6">
                <div className="contact-form-wrap contact-form-bg">
                  <h4>Registration</h4>
                  <div className="rld-single-input">
                    <input onChange={(e)=>this.setState({name:e.target.value})} type="text" placeholder=" Name" />
                  </div>
                  <div className="rld-single-input">
                    <input onChange={(e)=>this.setState({email:e.target.value})} type="email" placeholder="Email" />
                  </div>
                  <div className="rld-single-input">
                    <input onChange={(e)=>this.setState({phone:e.target.value})} type="text" placeholder="Phone" />
                  </div>
                  <div className="rld-single-input">
                    <input onChange={(e)=>this.setState({password:e.target.value})} type="password" placeholder="Password" />
                  </div>
                  <div className="rld-single-input">
                    <input onChange={(e)=>this.setState({c_password:e.target.value})} type="password" placeholder="Re-enter password" />
                  </div>
                  <p class="form_message">{this.state.messages}</p>
                  <div className="btn-wrap">
                    <button onClick={()=>this.hendalRegister()} className="btn btn-yellow">Register</button>
                  </div>
                  <ul className="social-icon">
                    <li className="ml-0">
                      <a href="" target="_blank"><i className="fa fa-facebook  " /></a>
                    </li>
                    <li>
                      <a href="" target="_blank"><i className="fa fa-twitter  " /></a>
                    </li>
                    <li>
                      <a href="" target="_blank"><i className="fa fa-linkedin" /></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        }
}

export default Registration