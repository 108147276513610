import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';
import Banner from '../../assets/img/welcomebanner.jpg';
import Magane from '../../assets/img/manage.svg';
import Stars from '../../assets/img/stars.svg';
import Profile from '../../assets/img/profiles.svg';
import Leads from '../../assets/img/leads.svg';
import Mobile from '../../assets/img/mobile.jpg';




class FaqComponent extends Component {

  constructor(props) {
    super(props);


  }




  render() {

    let publicUrl = '' + '/'
    let imagealt = 'image'
    let data = sectiondata.faq

    return <div className="add-new-property-area pd-top-90 ">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-6">
            <div className='row'>
            <div className="accordion" id="accordion" style={{width:"100%"}}>
                  {/* single accordion */}
                  { data.items.map( ( item, i )=>
                    <div key={ i } className="single-accordion card">
                        <div className="card-header" id= { "headingOne"+i }>
                          <h2 className="mb-0">
                            <button className="btn-link collapsed" type="button" data-toggle="collapse" data-target={ "#collapseOne"+i} aria-expanded="true" aria-controls={ "collapseOne"+i}>{ item.title }</button>
                          </h2>
                        </div>
                        <div id={ "collapseOne"+i} className={ "collapse "+item.class} aria-labelledby= { "headingOne"+i } data-parent="#accordion">
                          <div className="card-body">
                           { item.content }
                          </div>
                        </div>
                      </div>
                   ) }
                  


                </div>

            </div>



          </div>
        </div>
      </div>
    </div>

  }
}

export default FaqComponent