import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';
import { TextField, Checkbox } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import cookie from 'react-cookies';
import ActivateBtn from './activate-btn';
import StatusBtn from './inactivate-btn';
import DeclineBtn from './decline-btn';
import Update_Icon from '../../assets/img/update.svg';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import config from '../../config';
import ProductHelper from '../../Helpers/ProductHelper';
import DeleteBtn from '../section-components/delete-btn';



const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#fda94f',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#fda94f',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#e5e5e9',
    },
    '&:hover fieldset': {
      borderColor: '#fda94f',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#fda94f',
    },
  },
});

class ViewAgenciesComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      phone: "Call Agency",
      email: "Email Agency",
      status: this.props.data.status,
      sliceText: this.props.data.about_agency.slice(0, 419),
      readMore: false,
      properties: [],
      role: cookie.load('role'),
      pageNo: 0
    };
    this.propertiesBygency()
  }

  propertiesBygency = (skip = 0) => {
    ProductHelper.propertyByAgency({ agencyID: this.props.data._id, propertieSkip: skip }).then((resp) => {
      if (this.state.pageNo > 0) {
        this.setState({ properties: [...this.state.properties, ...resp.data.data] })
      } else {
        this.setState({ properties: resp.data.data })
      }
      console.log(resp);
    }).catch((e) => {
      alert(e.msg)
    })
  }



  render() {
    let publicUrl = ''
    let imagealt = 'image'
    return <div>
      <div className='container '>
        <div >
          <div className='container mt-5'>
            <div className='row'>
              <div className='col-md-8' style={{ border: "1px solid #e9f0f4", padding: "40px 0px" }}>
                <div className='row'>
                  <div className='col-5'>
                    <img className='' src={this.props.data.img} alt="img" />
                  </div>
                  <div className='col-7'>
                    <h3>{this.props.data.title}</h3>
                    <p>{this.state.readMore == false ? this.state.sliceText + "..." : this.props.data.about_agency}</p>
                    <p onClick={() => { this.setState({ readMore: !this.state.readMore }) }} style={{ cursor: "pointer", textDecoration: "underline", color: "#fda94f" }}>{this.state.readMore == false ? "Read More" : "Read Less"}</p>
                  </div>
                </div>
              </div>
              <div className='col-md-4' style={{ paddingLeft: "40px" }}>
                <div className='row'>
                  <div className='col-12' style={{ border: "1px solid gray", border: "1px solid #e9f0f4", padding: "20px" }}>
                    <div><h4 style={{ textAlign: "center" }}>Contact The Agency</h4></div>
                    <div><span>ORN: </span><p style={{ display: "inline-block", color: "black" }}>{this.props.data.orn}</p></div>
                    <div><span>Location: </span><p style={{ display: "contents", color: "black" }}>{this.props.data.location}</p></div>
                    <button style={{ borderRadius: "25px", width: "100%" }} type="button" class="btn btn-yellow" onClick={() => { this.setState({ phone: this.props.data.phone }) }}>{this.state.phone}</button>
                    <button style={{ borderRadius: "25px", width: "100%" }} type="button" class="btn btn-yellow mt-2" onClick={() => { this.setState({ email: this.props.data.email }) }}>{this.state.email}</button>

                    {this.state.role == "Admin" ?

                      this.state.status == "pending" ?
                        <div>
                          <StatusBtn id={this.props.data._id} text="Active Agency" endpoint="agencyStatusChange" status="active" AlertTitle="Activated" AlertText="Agency Activated Successfully"/>
                          <StatusBtn id={this.props.data._id} text="Decline Agency" endpoint="agencyStatusChange" status="declined" AlertTitle="Declined" AlertText="Agency Declined Successfully"/>
                        </div>
                        :
                        this.state.status == "active" ?
                          <StatusBtn id={this.props.data._id} text="Deactivated Agency" endpoint="agencyStatusChange" status="inactive" AlertTitle="Deactivated " AlertText="Agency Deactivated Successfully"/>
                          :
                          <StatusBtn id={this.props.data._id} text="Active Agency" endpoint="agencyStatusChange" status="active" AlertTitle="Activated" AlertText="Agency Activated Successfully" />
                      :
                      null
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.state.properties.length == 0 ?
            <h3 className='pt-5'>This Agency Have No Properties Yet</h3>
            :
            <div className='container mt-5'>
              <div className='row'>
                {
                  this.state.properties.map((item, i) =>
                    <div key={i} className="col-xl-3 col-sm-6 col-12">
                      <div className="single-feature">
                        <div className="">
                          <div className={`statusBadge ${item.status == 'active' ? "activeBG" : "deactiveBG"}`}>
                            {item.status=="inactive"?"Dactive":"Active"}
                          </div>
                          <Link to={{
                            pathname: "/propertydetails",
                            state: { i: i, data: item }
                          }}>
                            <img src={item.propertyImages ? JSON.parse(item.propertyImages)[0] : config.product_image_url + item.img} alt="img" />
                          </Link>
                        </div>
                        <div className="details">
                          <a href="" className="feature-logo">
                            <img src={this.props.data.img} alt={imagealt} />
                          </a>
                          {/* <p className="author"><i className="fa fa-user" /></p> */}
                          <p className="location"><Link
                            to={{
                              pathname: "/propertydetails",
                              state: { i: i, data: item }
                            }}>
                            {item.location}</Link></p>
                          <h6 className="title readeal-top"><Link
                            to={{
                              pathname: "/propertydetails",
                              state: { i: i, data: item }
                            }}>
                            {item.house_title.length < 60 ? item.house_title : item.house_title.slice(0, 60) + "..."}</Link></h6>
                          <h6 className="price">AED {item.price}</h6>
                          <ul className="info-list">
                            {/* { item.features.map( ( features, i )=>
                        <li key={ i } ><i className={ features.icon } /> { features.title }</li>
                        ) }  */}
                            <li ><i className={"fa fa-bed"} /> {item.bed_rooms} Bed</li>
                            <li ><i className={"fa fa-bath"} /> {item.bathrooms} Bath</li>
                            <li><img src={publicUrl + "/assets/img/icons/7.png"} alt={imagealt} /> {item.area}  sq. </li>
                          </ul>
                          {/* <ul className="contact-list">
                            <li><StatusBtn id={item._id} text="Active Agency" endpoint="propertyStatusChange" status="active" /></li>
                            <li><StatusBtn id={item._id} text="Deactive Agency" endpoint="propertyStatusChange" status="inactive" /></li>
                            <li className="readeal-top"><Link className="btn btn-yellow"
                              to={{
                                pathname: "/propertydetails",
                                state: { i: i, data: item }
                              }}
                            >View Details</Link></li>
                          </ul> */}
                        </div>

                      </div>
                    </div>
                  )
                }
              </div>
              <div className='d-flex justify-content-center'>
                <button style={{ borderRadius: "25px", width: "30%" }} onClick={() => {
                  this.setState({ pageNo: ++this.state.pageNo }, () => {
                    this.propertiesBygency(this.state.pageNo);
                  })
                }} type="button" class="btn btn-yellow mt-2">Load More</button>
              </div>
            </div>
          }
        </div>
      </div>
    </div>

  }
}

export default ViewAgenciesComponent