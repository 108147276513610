import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import AddNewProperty from './section-components/add-new-property';
import Footer from './global-components/footer';
import { useEffect } from 'react';
import banner from "./../assets/img/addproperty.jpg"

const AddProperty = (props) => {
    useEffect(() => {
      console.log(props.location.state.data);
    
      
    })
    
    return <div>
        
        <Navbar />
        <PageHeader headertitle={props.location.state.update=="false"?"Add Property":"Update Property"} video={banner}/>
        {props.location.state.update=="true"?

            <AddNewProperty data={props.location.state.data} update={props.location.state.update} />
            :
            <AddNewProperty  update={"false"} />

        }

        <Footer />
        
    </div>
}

export default AddProperty

