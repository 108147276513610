import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import  '../../assets/css/style.css'

export default function RangeSlider(props) {
  const [value, setValue] = React.useState([0, 5000000]);

  React.useEffect(() => {
    if(props.setValue){
      setValue(props.setValue) 
    }
  })


  const handleChange = (event, newValue) => {
    props.getValue(newValue)
    setValue(newValue);
  };

  const valuetext = (value) => {
    return `AED ${value}`;
  }


  return (
    <Box>
      <Slider
        min={0}
        step={100000}
        max={9900000}
        getAriaLabel={() => 'Price range'}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        color="secondary"
      />
    </Box>
  );
}
