import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import ViewAgenciesComponent from './section-components/ViewAgenciesComponent';
import Footer from './global-components/footer';
import main from "./../assets/img/account.png"

import { useEffect, useState } from 'react';

const ViewAgencies = (props) => {
    
    

    return <div>

        <Navbar />
        <PageHeader video={main} headertitle={props.location.state.data.title} />
        <ViewAgenciesComponent data={props.location.state.data} />
        <Footer />

    </div>
}

export default ViewAgencies

