import * as React from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import SearchListSection from './search-list';
import { Link } from 'react-router-dom';
import DeleteBtn from './section-components/delete-btn';
import Registration from './registration';
import cookie from 'react-cookies';
import Update_Icon from '../assets/img/update.svg';
import AllAgenciesComponent from './section-components/allAgenciesComponent';
import config from "../config";







function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}




export default function BasicTabs() {
  const [value, setValue] = React.useState(0);
  const [location, setlocation] = React.useState("Location");
  const [active, setActive] = React.useState([]);
  const [inactive, setInactive] = React.useState([]);
  const [pending, setPending] = React.useState([]);
  const [declined, setDeclined] = React.useState([]);



  async function all_favorite() {

    let data = { user_id: cookie.load('user_id') };

    await fetch(config.URL+"users/all_agencies", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((result) => {
        // setData(result)

        var temp = result.agencies;
        var active = temp.filter((e, i) => {
          if (e.status == "active") {
            return e;
          }
        })
        var inactive = temp.filter((e, i) => {
          if (e.status == "inactive") {
            return e;
          }
        })
        var pending = temp.filter((e, i) => {
          if (e.status == "pending") {
            return e;
          }
        })
        var declined = temp.filter((e, i) => {
          if (e.status == "declined") {
            return e;
          }
        })

        


        // var rent = result.agencies.filter((e, i) => {
        //   if (e.my_properties.rent == true) {
        //     return e;
        //   }
        // })
        setActive(active);
        setInactive(inactive);
        setPending(pending);
        setDeclined(declined);
      })
  };
  useEffect(() => {
    all_favorite();


  }, []);

  let publicUrl = ''

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };





  return (
    <div style={{ position: 'relative' }}>


      <Box sx={{ width: '100%' }}>

        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" >
            <Tab label={<b>Active Agencies ({active.length})</b>} {...a11yProps(0)} />
            <Tab label={<b>Inactive Agencies ({inactive.length})</b>} {...a11yProps(1)} />
            <Tab label={<b>Pending Agencies ({pending.length})</b>} {...a11yProps(2)} />
            <Tab label={<b>Declined Agencies ({declined.length})</b>} {...a11yProps(3)} />
          </Tabs>
        </Box>

        <TabPanel value={value} index={0}>
          <Box className="single-feature style-two" >
            {active.map((item, i) =>
              <Box key={i} className="single-feature style-two" style={{ height: "255px" }}>
                
                <AllAgenciesComponent data={item} rent={item.my_properties.filter((e,i)=>{
                    if(e.rent==true){
                      return e;
                    }
                  }).length} sale={item.my_properties.filter((e,i)=>{
                    if(e.rent==false){
                      return e;
                    }
                  }).length} />
              </Box>
            )}
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Box className="single-feature style-two" >
            {inactive.map((item, i) =>
              <Box key={i} className="single-feature style-two" style={{ height: "255px" }}>
                <AllAgenciesComponent data={item} 
                    rent={item.my_properties.filter((e,i)=>{
                        if(e.rent==true){
                          return e;
                        }
                      }).length} 
                      
                      sale={item.my_properties.filter((e,i)=>{
                        if(e.rent==false){
                          return e;
                        }
                      }).length} />
              </Box>
            )}
          </Box>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Box className="single-feature style-two" >
            {pending.map((item, i) =>
              <Box key={i} className="single-feature style-two" style={{ height: "255px" }}>
                <AllAgenciesComponent data={item} 
                    rent={item.my_properties.filter((e,i)=>{
                      if(e.rent==true){
                        return e;
                      }
                    }).length}
                    
                    sale={item.my_properties.filter((e,i)=>{
                      if(e.rent==false){
                        return e;
                      }
                    }).length} />
              </Box>
            )}
          </Box>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Box className="single-feature style-two" >
            {declined.map((item, i) =>
              <Box key={i} className="single-feature style-two" style={{ height: "255px" }}>
                <AllAgenciesComponent data={item} 
                    rent={item.my_properties.filter((e,i)=>{
                      if(e.rent==true){
                        return e;
                      }
                    }).length} 
                    
                    sale={item.my_properties.filter((e,i)=>{
                      if(e.rent==false){
                        return e;
                      }
                    }).length} />
              </Box>
            )}
          </Box>
        </TabPanel>
        <div className="nav-right-part nav-right-part-mobile">
          <Link className="btn btn-yellow" to="/registration"><i class="fa fa-user"></i> SIGN IN <span className="right"></span></Link>
        </div>
      </Box>
    </div>
  );
}
