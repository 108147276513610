import React from 'react'
import SearchGrid from './search-grid'

export default function CommercialRent(props) {
  return (
    <>
        <SearchGrid location={props.location}/>
    </>
  )
}
