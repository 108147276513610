import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade';


class Service extends Component {


    render() {

        let publicUrl = ''
        let imagealt = 'image'
        let data = sectiondata.services


    return <div className="service-area h1-service-slider-area col-12">
      <Fade bottom>
        <div className="container col-12">
          <div className="col-md-8 col-12" style={{margin :"0 auto"}}>
            <div className='row'>
              { data.items.map( ( item, i )  =>
                  <div key={ i } className="item col-md-4 pt-md-0 pt-2">
                    <div className="single-service text-center">
                      <div className="thumb double-img">
                        <img src={ item.icon } alt="icons" />
                      </div>
                      <div className="details readeal-top">
                        <h4>{ item.title }</h4>
                        <p>{ item.text }</p>
                        <Link className="readmore-btn" to={item.url}>{ item.btntxt } <i className="la la-arrow-right" /></Link>
                      </div>
                    </div>
                  </div>
              ) }
             </div>
          </div>
        </div>
        </Fade>
      </div>


        }
}

export default Service