import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';
import Banner from '../../assets/img/welcomebanner.jpg';
import Magane from '../../assets/img/manage.svg';
import Stars from '../../assets/img/stars.svg';
import Profile from '../../assets/img/profiles.svg';
import Leads from '../../assets/img/leads.svg';
import Mobile from '../../assets/img/mobile.jpg';
import cssStyle from "../../assets/css/style.css"
import { Link } from 'react-router-dom';




class SideNav extends Component {

  constructor(props) {
    super(props);


  }




  render() {

    let publicUrl = '' + '/'
    let imagealt = 'image'
    let data = sectiondata.aboutus

    return <div className="add-new-property-area pd-top-90 ">
      <div id="mySidenav" class="sidenav">
      <Link to="/"><img width="190px" src={publicUrl + "../../assets/img/logo-wb.png"} alt="logo" /></Link>
        <a href="javascript:void(0)" class="closebtn" onclick="closeNav()">&times;</a>
        <a href="">About</a>
        <a href="">Services</a>
        <a href="">Clients</a>
        <a href="">Contact</a>
      </div>
    </div>

  }
}

export default SideNav