import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';
import Carousel from 'react-material-ui-carousel'
import cookie from 'react-cookies';
import { TextField, Checkbox } from '@mui/material';
import config from "../../config";



class PropertyDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: { min: 2, max: 10 },
      data: [],
      phone: "Call Agency",
      email: "Email Agency",
      agencyTitle: "",
      agencyEmail: "",
      agencyPhone: "",
      agencyAddress: "Address",
      AC: this.props.item.AC,
      Balcony: this.props.item.Balcony,
      FrontYard: this.props.item.FrontYard,
      Pets: this.props.item.Pets,
      Pool: this.props.item.Pool,
      SeaView: this.props.item.SeaView,
      propertyImagesURL: this.props.item.propertyImages ? JSON.parse(this.props.item.propertyImages) : [config.product_image_url + this.props.item.img]

    };
  }


  


  // agencyInformation = () => {
  //   const data = { product_id: this.props.value };

  //        fetch(config.URL+"users/agencyInformation", {
  //           method: 'POST',
  //           headers: {
  //             'Accept': 'application/json',
  //             'Content-Type': 'application/json',
  //           },
  //           body: JSON.stringify(data)
  //   }).then((res) => res.json())
  //   .then((result) => {
  //     console.log(result.agency.title);
  //     this.setState({agencyTitle:result.agency.title })
  //     this.setState({agencyPhone:result.agency.phone })
  //     this.setState({agencyEmail:result.agency.email })
  //     // this.setState({agencyAddress:result.agency.address })
  //   })
  // }    

  render() {

    let publicUrl = ''
    let imagealt = 'image'


    return <div className="property-details-area">
      <div className="pt-2 pd-bottom-60 pt-5">
        <div className="container">
          <div className="row ">
            <div className="col-12 d-lg-none d-block" style={{position:"fixed",bottom:"0",overflow:"hidden",zIndex:"2000"}}>
              <div className="row">
              <a href={`tel:${this.props.item.agency.phone}`} style={{ borderRadius: "25px", width: "100%" }} type="button" class="col btn btn-yellow" onClick={() => { this.setState({ phone: this.props.item.agency.phone }) }}>{this.state.phone}</a>
              <a href={`mailto:${this.props.item.agency.email}`} style={{ borderRadius: "25px", width: "100%" }} type="button" class="col btn btn-yellow " onClick={() => { this.setState({ email: this.props.item.agency.email }) }}>{this.state.email}</a>
              <a href={`https://api.whatsapp.com/send?phone=${this.props.item.agency.phone}&text=Hi`} target="_blank" style={{ borderRadius: "25px", width: "100%" }} type="button" class="col btn btn-success ">WhatsApp Agency</a>

              </div>


            </div>
            <div className="col-xl-9 pt-1 col-lg-8">
              <h3>{this.props.item.house_title}{ }</h3>

              <div >

                <Carousel showDots={true}>
                  {
                    this.state.propertyImagesURL.length !==0?
                      this.state.propertyImagesURL.map((e, i) => (
                        <div key={i} style={{ justifyContent: 'center', display: 'flex' }}>
                          <img src={e} alt={imagealt} width="100%" style={{ borderRadius: "15px" }} />
                        </div>
                      ))
                    :
                    <div  style={{ justifyContent: 'center', display: 'flex',backgroundColor:"gray",height:"500px" }}>
                        <img style={{height:"200px!important",width:"200px",objectFit:"contain"}} src={"https://celestialcode.s3.us-west-2.amazonaws.com/mulqiat/media-1693397243903.jpg"} alt={imagealt} width="100%"  />
                    </div>

                  }
                </Carousel>
              </div>
              <div className="property-details-slider-info border-top">
                <h3><span>AED {this.props.item.price.toLocaleString()}</span></h3>
              </div>
              <h4>{this.props.item.short_description}</h4>
            </div>
            <div className="col-xl-3 col-lg-4 col-12">
              <div className="widget widget-owner-info mt-lg-0 mt-5">
                <div className="owner-info text-center">
                  <div className="thumb">
                    <img src={this.props.item.agency.img} alt={imagealt} />
                  </div>
                  <div className="details">
                    <h4>{this.props.item.agency.title}</h4>
                    {/* <span className="designation">Building Owner</span> */}
                    {/* <p className="reviews"><i className="fa fa-star" /><span>4.8</span> 70 Review</p> */}
                  </div>
                </div>

                <div className="contact-info d-lg-block d-none">
                  <h5 className="mb-3 ">Contact Info</h5>
                  <div className=' '>
                    <a href={`tel:${this.props.item.agency.phone}`} style={{ borderRadius: "25px", width: "100%" }} type="button" class="btn btn-yellow" onClick={() => { this.setState({ phone: this.props.item.agency.phone }) }}>{this.state.phone}</a>
                    <a href={`mailto:${this.props.item.agency.email}`} style={{ borderRadius: "25px", width: "100%" }} type="button" class="btn btn-yellow mt-2" onClick={() => { this.setState({ email: this.props.item.agency.email }) }}>{this.state.email}</a>
                    <a href={`https://api.whatsapp.com/send?phone=${this.props.item.agency.phone}&text=Hi`} target="_blank" style={{ borderRadius: "25px", width: "100%" }} type="button" class="btn btn-success mt-2">WhatsApp Agency</a>
                  </div>

                  <div className="media mt-3 d-lg-block">
                    <div className="media-left">
                      <img src={publicUrl + "/assets/img/icons/1.png"} alt={imagealt} />
                    </div>
                    <div className="media-body">
                      <p>Address</p>
                      <span>{this.props.item.location}</span>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">

        <div className="row pd-top-90">
          <div className="col-lg-9 ">
            <div className="property-info mb-5">
              <div className="row">
                {this.props.item.bed_rooms == 0 ?
                  null
                  :
                  <div className="col-md-3 col-sm-6">
                    <div className="single-property-info">
                      <h5>Bedrooms</h5>
                      <p><i className="fa fa-bed" />{this.props.item.bed_rooms}</p>
                    </div>
                  </div>
                }
                {this.props.item.bathrooms == 0 ?
                  null
                  :
                  <div className="col-md-3 col-sm-6">
                    <div className="single-property-info">
                      <h5>Bathrooms</h5>
                      <p><i className="fa fa-bath" />{this.props.item.bathrooms}</p>
                    </div>
                  </div>
                }
                <div className="col-md-3 col-sm-6">
                  <div className="single-property-info">
                    <h5>Area</h5>
                    <p><img src={publicUrl + "/assets/img/icons/7.png"} alt={imagealt} />{this.props.item.area} sq. ft.</p>
                  </div>
                </div>
                {/* <div className="col-md-3 col-sm-6">
                  <div className="single-property-info">
                    <h5>Parking</h5>
                    <p><i className="fa fa-car" />01 Indoor</p>
                  </div>
                </div> */}
                <div className="col-md-3 col-sm-6">
                  <div className="single-property-info">
                    <h5>RERA</h5>
                    <p><i class="fa fa-file" />{this.props.item.rara}</p>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6">
                  <div className="single-property-info">
                    <h5>Permit</h5>
                    <p><i class="fa fa-shield" /> {this.props.item.permit}</p>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6">
                  <div className="single-property-info">
                    <h5>Ref</h5>
                    <p><i class="fa fa-file" />{this.props.item.reference}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="property-news-single-card style-two border-bottom-yellow">
              <h4>Description:</h4>
              <p>{this.props.item.explanation}</p>
            </div>
            <div className="property-news-single-card style-two border-bottom-yellow">
              <div className="row pd-top-80">
                <div className="col-md-4">
                  <div className="section-title">
                    <h4><img src={publicUrl + "assets/img/icons/31.png"} alt="img" />Amenities</h4>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-sm-4">
                      <ul className="rld-list-style mb-3 mb-sm-0">
                        {this.state.AC == true ?
                          <li ><Checkbox checked icon={<i className="fa fa-chec" />} checkedIcon={<i className="fa fa-check" />} /> AC</li>
                          :
                          null
                        }
                        {this.state.Balcony == true ?
                          <li ><Checkbox checked icon={<i className="fa fa-chec" />} checkedIcon={<i className="fa fa-check" />} /> Balcony</li>
                          :
                          null
                        }
                      </ul>
                    </div>
                    <div className="col-sm-4">
                      <ul className="rld-list-style mb-3 mb-sm-0">
                        {this.state.FrontYard == true ?
                          <li ><Checkbox checked icon={<i className="fa fa-chec" />} checkedIcon={<i className="fa fa-check" />} /> FrontYard</li>
                          :
                          null
                        }
                        {this.state.Pets == true ?
                          <li ><Checkbox checked icon={<i className="fa fa-chec" />} checkedIcon={<i className="fa fa-check" />} /> Pets</li>
                          :
                          null
                        }

                      </ul>
                    </div>
                    <div className="col-sm-4">
                      <ul className="rld-list-style mb-3 mb-sm-0">
                        {this.state.Pool == true ?
                          <li ><Checkbox checked icon={<i className="fa fa-chec" />} checkedIcon={<i className="fa fa-check" />} /> Pool</li>
                          :
                          null
                        }
                        {this.state.SeaView == true ?
                          <li ><Checkbox checked icon={<i className="fa fa-chec" />} checkedIcon={<i className="fa fa-check" />} /> SeaView</li>
                          :
                          null
                        }

                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>




  }
}

export default PropertyDetails