import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';
import { TextField, Checkbox } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Stack from '@mui/material/Stack';
import config from "../../config";


const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#fda94f',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#fda94f',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#e5e5e9',
    },
    '&:hover fieldset': {
      borderColor: '#fda94f',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#fda94f',
    },
  },
});

class AddNew extends Component {

  constructor(props) {
    super(props);

    this.state = {
      title: "",
      email: "",
      password: "",
      phone: "",
      about_agency: "",
      location: "",
      orn: "",
      image: null,
    };
  }




  render() {

    let publicUrl = '' + '/'
    let imagealt = 'image'
    let data = sectiondata.aboutus
    const Input = styled('input')({
      display: 'none',
    });

    function saving_data() {
      alert("hi")
    }
    return <div className="add-new-property-area pd-top-90 mg-bottom-100">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-9 col-lg-10">
            <div className="section-title text-center">
              <h3>Add New Agency</h3>
            </div>
            <div className="border-bottom mb-4">
              <div className="row">
                <div className="col-md-4">
                  <div className="single-intro style-two text-center">
                    <div className="thumb">
                      1
                    </div>
                    <div className="details">
                      <h4 className="title">Choose Agency</h4>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="single-intro style-two text-center">
                    <div className="thumb">
                      2
                    </div>
                    <div className="details">
                      <h4 className="title">Add Property</h4>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="single-intro style-two text-center">
                    <div className="thumb">
                      3
                    </div>
                    <div className="details">
                      <h4 className="title">Start Selling</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row pd-top-100">
              <div className="col-md-4">
                <div className="section-title">
                  <h4><img src={publicUrl + "assets/img/icons/28.png"} alt="img" />About Agency</h4>
                </div>
              </div>
              <div className="col-md-8">
                <div className="section-title">
                  <CssTextField fullWidth label="Title" id="custom-css-outlined-input" onChange={(e) => {
                    this.setState({ title: e.target.value }, console.log(e.target.value))
                  }} />
                  <CssTextField fullWidth label="Email" id="custom-css-outlined-input" margin="dense" onChange={(e) => {
                    this.setState({ email: e.target.value }, console.log(e.target.value))
                  }} />
                  <CssTextField fullWidth label="Password" id="custom-css-outlined-input" margin="dense" onChange={(e) => {
                    this.setState({ password: e.target.value }, console.log(e.target.value))
                  }} />
                  <CssTextField fullWidth label="Phone" id="custom-css-outlined-input" margin="dense" onChange={(e) => {
                    this.setState({ phone: e.target.value }, console.log(e.target.value))
                  }} />
                  <CssTextField multiline rows={5} fullWidth label="About Agency" id="custom-css-outlined-input" margin="dense" onChange={(e) => {
                    this.setState({ about_agency: e.target.value }, console.log(e.target.value))
                  }} />
                  <CssTextField fullWidth label="Location" id="custom-css-outlined-input" margin="dense" onChange={(e) => {
                    this.setState({ location: e.target.value }, console.log(e.target.value))
                  }} />
                  <CssTextField fullWidth label="ORN" id="custom-css-outlined-input" margin="dense" onChange={(e) => {
                    this.setState({ orn: e.target.value }, console.log(e.target.value))
                  }} />



                </div>
                <div className="row">

                  <div className="col-lg-6 mb-3">
                    <label htmlFor="contained-button-file">
                      <Input accept="image/*" id="contained-button-file" multiple type="file" onChange={(e) => {
                          this.setState({ image: e.currentTarget.files[0] }, console.log(this.state.image))
                          }} > hi</Input>
                      <Button variant="contained" component="span" style={{backgroundColor:"#fda94f"}}>
                        Upload Your Logoo {this.state.image}
                      </Button>
                    </label>

                  </div>

                </div>
              </div>
            </div>


            <div className="row pd-top-80">
              <div className="col-md-8">
                <div className="row">

                  <div className="col-12 mt-5">
                    <button className="btn btn-yellow" onClick={() => {
                      const data = this.state
                      console.log(data)
                       fetch(config.URL+"/users/add_agency", {
                        method: 'POST',
                        headers: {
                          'Accept': 'application/json',
                          'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(data)
                      }).then((result) => {
                        console.log(result)
                      })
                    }} >Submit</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  }
}

export default AddNew