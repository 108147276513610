import * as React from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import SearchListSection from './search-list';
import { Link } from 'react-router-dom';
import SkeletonItem from "./section-components/skeleton";


import DeleteBtn from './section-components/delete-btn';
import Registration from './registration';
import cookie from 'react-cookies';
import Update_Icon from '../assets/img/update.svg';
import notFound from "../assets/img/notFound.png";

import config from "../config";
import StatusBtn from './section-components/inactivate-btn';
import { Fade } from 'react-reveal';







function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}




export default function BasicTabs(props) {
  const [value, setValue] = React.useState(0);
  const [location, setlocation] = React.useState("Location");
  const [loading, setloading] = React.useState(false);
  const [sale, setSale] = React.useState([]);
  const [rent, setRent] = React.useState([]);
  const [cRent, setCRent] = React.useState([]);
  const [cSale, setCSale] = React.useState([]);
  async function all_favorite() {
    setloading(true)
    let data = { user_id: cookie.load('user_id') };

    await fetch(config.URL + "users/my_properties", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': cookie.load('Tokken'),

      },
      body: JSON.stringify(data)
    })
      .then((res) => res.json())
      .then((result) => {
        // setData(result)
        console.log("My properties ",result);
        var sale = result.filter((e, i) => {
          if (e.rent == false && e.commercial == false) {
            return e;
          }
        })
        var rent = result.filter((e, i) => {
          if (e.rent == true && e.commercial == false) {
            return e;
          }
        })
        var commercialSale = result.filter((e, i) => {
          if (e.rent == false && e.commercial == true) {
            return e;
          }
        })
        var commercialRent = result.filter((e, i) => {
          if (e.rent == true && e.commercial == true) {
            return e;
          }
        })
        setSale(sale)
        setRent(rent)
        setCSale(commercialSale)
        setCRent(commercialRent)
        setloading(false)

      })
  };
  useEffect(() => {
    all_favorite();
  }, []);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };





  return (
      <div style={{ position: 'relative' }}>



        <Box sx={{ width: '100%' }}>
          {props.status == "active" ?
            <Link className='btn btn-yellow' to={{ pathname: "/add-property", state: { update: "false" } }}><i class="fa fa-plus"></i> Add Property <span className="right"></span></Link>
            :
            <button disabled className='btn btn-yellow' to={{ pathname: "/add-property", state: { update: "false" } }}><i class="fa fa-plus"></i> Add Property <span className="right"></span></button>
          }

          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs variant="scrollable" value={value} onChange={handleChange} aria-label="basic tabs example" >
              <Tab label={<b>Properties For Sale ({sale.length})</b>} {...a11yProps(0)} />
              <Tab label={<b>Properties For Rent ({rent.length})</b>} {...a11yProps(1)} />
              <Tab label={<b>Commercial for Sale ({cSale.length})</b>} {...a11yProps(2)} />
              <Tab label={<b>Commercial for Rent ({cRent.length})</b>} {...a11yProps(3)} />
            </Tabs>
          </Box>

          <TabPanel value={value} index={0}>
            <>
            {
            loading == true?
              <div>
                <h5 style={{fontSize:35,fontWeight:"bold",color:"rgb(253 170 79)"}}>
                  Loading...
                </h5>
              </div>
            :
            sale.length == 0?
              <Fade bottom>
                <div className="d-flex justify-content-center" style={{flexDirection:"column",alignItems:"center"}}>
                  <img src={notFound} width="80%" />
                  <h3 style={{fontSize:35,fontWeight:"bold",color:"rgb(253 170 79)"}}>No Property Fond</h3>
                </div>
              </Fade>
            :
              <div>
              {sale.map((item, i) =>
                <div key={i} className="single-feature style-two" style={{ height: "255px" }}>
                  <div className={`statusBadge ${item.status == 'active' ? "activeBG" : "deactiveBG"}`}>
                    {item.status == "inactive" ? "Dactive" : "Active"}
                  </div>
                  <div className="thumb">
                    <img style={{objectFit:"cover",height:"255px"}} src={item.propertyImages ? ((item.propertyImages != "" && item.propertyImages != "[]" ? JSON.parse(item.propertyImages)[0] : "https://images-cdn2.houza.com/images/properties/2021/12/06/AxJyEBFwb4_sKHh.jpg?profile=xs")) : "https://images-cdn2.houza.com/images/properties/2021/12/06/AxJyEBFwb4_sKHh.jpg?profile=xs"} alt="Mulqiat.com" />
                  </div>
                  <div className="details bg-white" style={{ height: "255px" }}>
                    <div className="details-wrap">
                      <a href="" className="feature-logo">
                        <img src={item.agency.img} alt="img" style={{width:"55px",height:"55px",objectFit:"cover"}} />
                      </a>
                      <p className="author"><i className="fa fa-user" /> {item.location}</p>
                      <h6 className="title readeal-top"><Link
                        to={{
                          pathname: "/propertydetails",
                          state: { i: i, data: item }
                        }}>
                        {item.house_title}</Link></h6>
                      <h6 className="price">AED {item.price.toLocaleString()}</h6>
                      <ul className="info-list">
                      </ul>
                      <ul className="contact-list bg-white">
                        <li><Link className="message" to={{ pathname: "/add-property", state: { data: item, update: "true" } }}><img style={{ width: "15px" }} src={Update_Icon} alt="img" /></Link></li>
                        <li data-toggle="tooltip" data-placement="top" title={item.status == "inactive" ? "Active Property" : "Deactivate Property"}>
                          <a className="phone" >
                            <StatusBtn
                              id={item._id}
                              text={item.status == "inactive" ? "Active This Property" : "Deactivate This Property"}
                              endpoint="propertyStatusChange"
                              status={item.status == "inactive" ? "active" : "inactive"}
                              AlertTitle={item.status == "inactive" ? "Activated" : "Deactivated"}
                              AlertText={item.status == "inactive" ? "Property Activated Successfully" : "Property Deactivated Successfully"} />
                          </a>
                        </li>

                        <DeleteBtn value={item._id} />
                        <li className="readeal-top"><Link className="btn btn-yellow" to={{
                          pathname: "/propertydetails",
                          state: { i: i, data: item }
                        }}>View Details</Link></li>
                      </ul>
                    </div>
                  </div>
                </div>
              )}
              </div>
            }
            </>
          </TabPanel>
          <TabPanel value={value} index={1}>
            {
              loading == true?
              <div>
                <h5 style={{fontSize:35,fontWeight:"bold",color:"rgb(253 170 79)"}}>
                  Loading...
                </h5>
              </div>
            :
            rent.length == 0?
              <Fade bottom>
                <div className="d-flex justify-content-center" style={{flexDirection:"column",alignItems:"center"}}>
                  <img src={notFound} width="80%" />
                  <h3 style={{fontSize:35,fontWeight:"bold",color:"rgb(253 170 79)"}}>No Property Fond</h3>
                </div>
              </Fade>
            :
              <div>
                {rent.map((item, i) =>

                  <div key={i} className="single-feature style-two" style={{ height: "255px" }}>
                    <div className={`statusBadge ${item.status == 'active' ? "activeBG" : "deactiveBG"}`}>
                      {item.status == "inactive" ? "Dactive" : "Active"}
                    </div>
                    <div className="thumb">
                      <img style={{objectFit:"cover",height:"255px"}}  src={item.propertyImages ? ((item.propertyImages != "" && item.propertyImages != "[]" ? JSON.parse(item.propertyImages)[0] : "https://celestialcode.s3.us-west-2.amazonaws.com/mulqiat/media-1693397243903.jpg")) : "https://celestialcode.s3.us-west-2.amazonaws.com/mulqiat/media-1693397243903.jpg"} alt="Mulqiat.com" />
                    </div>
                    <div className="details bg-white" style={{ height: "255px" }}>
                      <div className="details-wrap">
                        <a href="" className="feature-logo">
                          <img src={item.agency.img} alt="img" style={{width:"55px",height:"55px",objectFit:"cover"}}/>
                        </a>
                        <p className="author"><i className="fa fa-user" /> {item.location}</p>
                        <h6 className="title readeal-top"><Link to={{
                          pathname: "/propertydetails",
                          state: { i: i, data: item }
                        }}>{item.house_title}</Link></h6>
                        <h6 className="price">AED {item.price.toLocaleString()}</h6><del> {item.olderprice}</del>
                        <ul className="info-list">
                          {/* {item.features.map((features, i) =>
                                <li key={i} ><i className={features.icon} /> {features.title}</li>
                              )} */}
                          {/* <li><img src={publicUrl + "/assets/img/icons/7.png"} alt={imagealt} /> {item.area}  </li> */}
                        </ul>
                        <ul className="contact-list bg-white">
                          <li><Link className="message" to={{ pathname: "/add-property", state: { data: item, update: "true" } }}><img style={{ width: "15px" }} src={Update_Icon} alt="img" /></Link></li>
                          <li data-toggle="tooltip" data-placement="top" title={item.status == "inactive" ? "Active Property" : "Deactivate Property"}>
                            <a className="phone" >
                              <StatusBtn
                                id={item._id}
                                text={item.status == "inactive" ? "Active This Property" : "Deactivate This Property"}
                                endpoint="propertyStatusChange"
                                status={item.status == "inactive" ? "active" : "inactive"}
                                AlertTitle={item.status == "inactive" ? "Activated" : "Deactivated"}
                                AlertText={item.status == "inactive" ? "Property Activated Successfully" : "Property Deactivated Successfully"} />
                            </a>
                          </li>
                          <DeleteBtn value={item._id} />
                          <li className="readeal-top"><Link className="btn btn-yellow" to={{
                            pathname: "/propertydetails",
                            state: { i: i, data: item }
                          }} >View Details</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            }

          </TabPanel>
          <TabPanel value={value} index={2}>
          {
              loading == true?
              <div>
                <h5 style={{fontSize:35,fontWeight:"bold",color:"rgb(253 170 79)"}}>
                  Loading...
                </h5>
              </div>
            :
            cSale.length == 0?
              <Fade bottom>
                <div className="d-flex justify-content-center" style={{flexDirection:"column",alignItems:"center"}}>
                  <img src={notFound} width="80%" />
                  <h3 style={{fontSize:35,fontWeight:"bold",color:"rgb(253 170 79)"}}>No Property Fond</h3>
                </div>
              </Fade>
            :
              cSale.map((item, i) =>
              <div key={i} className="single-feature style-two" style={{ height: "255px" }}>
                <div className={`statusBadge ${item.status == 'active' ? "activeBG" : "deactiveBG"}`}>
                  {item.status == "inactive" ? "Dactive" : "Active"}
                </div>
                <div className="thumb">
                  <img style={{objectFit:"cover",height:"255px"}}  src={item.propertyImages ? ((item.propertyImages != "" && item.propertyImages != "[]" ? JSON.parse(item.propertyImages)[0] : "https://celestialcode.s3.us-west-2.amazonaws.com/mulqiat/media-1693397243903.jpg")) : "https://celestialcode.s3.us-west-2.amazonaws.com/mulqiat/media-1693397243903.jpg"} alt="Mulqiat.com" />
                </div>
                <div className="details bg-white" style={{ height: "255px" }}>
                  <div className="details-wrap">
                    <a href="" className="feature-logo">
                      <img src={item.agency.img} alt="img" style={{width:"55px",height:"55px",objectFit:"cover"}} />
                    </a>
                    <p className="author"><i className="fa fa-user" /> {item.location}</p>
                    <h6 className="title readeal-top"><Link to={{
                          pathname: "/propertydetails",
                          state: { i: i, data: item }
                        }}>{item.house_title}</Link></h6>
                    <h6 className="price">AED {item.price.toLocaleString()}</h6><del> {item.olderprice}</del>
                    <ul className="info-list">
                      {/* {item.features.map((features, i) =>
                            <li key={i} ><i className={features.icon} /> {features.title}</li>
                          )} */}
                      {/* <li><img src={publicUrl + "/assets/img/icons/7.png"} alt={imagealt} /> {item.area}  </li> */}
                    </ul>
                    <ul className="contact-list bg-white">
                      <li><Link className="message" to={{ pathname: "/add-property", state: { data: item, update: "true" } }}><img style={{ width: "15px" }} src={Update_Icon} alt="img" /></Link></li>
                      <li data-toggle="tooltip" data-placement="top" title={item.status == "inactive" ? "Active Property" : "Deactivate Property"}>
                        <a className="phone" >
                          <StatusBtn
                            id={item._id}
                            text={item.status == "inactive" ? "Active This Property" : "Deactivate This Property"}
                            endpoint="propertyStatusChange"
                            status={item.status == "inactive" ? "active" : "inactive"}
                            AlertTitle={item.status == "inactive" ? "Activated" : "Deactivated"}
                            AlertText={item.status == "inactive" ? "Property Activated Successfully" : "Property Deactivated Successfully"} />
                        </a>
                      </li>
                      <DeleteBtn value={item._id} />
                      <li className="readeal-top"><Link className="btn btn-yellow" to={{
                        pathname: "/propertydetails",
                        state: { i: i, data: item }
                      }} >View Details</Link></li>
                    </ul>
                  </div>
                </div>
              </div>
              
            )}
          </TabPanel>
          <TabPanel value={value} index={3}>
          {
              loading == true?
              <div>
                <h5 style={{fontSize:35,fontWeight:"bold",color:"rgb(253 170 79)"}}>
                  Loading...
                </h5>
              </div>
            :
            cRent.length == 0?
              <Fade bottom>
                <div className="d-flex justify-content-center" style={{flexDirection:"column",alignItems:"center"}}>
                  <img src={notFound} width="80%" />
                  <h3 style={{fontSize:35,fontWeight:"bold",color:"rgb(253 170 79)"}}>No Property Fond</h3>
                </div>
              </Fade>
            :
            cRent.map((item, i) =>
              <div key={i} className="single-feature style-two" style={{ height: "255px" }}>
                <div className={`statusBadge ${item.status == 'active' ? "activeBG" : "deactiveBG"}`}>
                  {item.status == "inactive" ? "Dactive" : "Active"}
                </div>
                <div className="thumb">
                  <img style={{objectFit:"cover",height:"255px"}}  src={item.propertyImages ? ((item.propertyImages != "" && item.propertyImages != "[]" ? JSON.parse(item.propertyImages)[0] : "https://celestialcode.s3.us-west-2.amazonaws.com/mulqiat/media-1693397243903.jpg")) : "https://celestialcode.s3.us-west-2.amazonaws.com/mulqiat/media-1693397243903.jpg"} alt="Mulqiat.com" />
                </div>
                <div className="details bg-white" style={{ height: "255px" }}>
                  <div className="details-wrap">
                    <a href="" className="feature-logo">
                      <img src={item.agency.img} alt="img" style={{width:"55px",height:"55px",objectFit:"cover"}}/>
                    </a>
                    <p className="author"><i className="fa fa-user" /> {item.location}</p>
                    <h6 className="title readeal-top"><Link to={{
                      pathname: `/property-details/${item._id}/${JSON.stringify('')}`,
                      state: item
                    }}>{item.house_title}</Link></h6>
                    <h6 className="price">AED {item.price.toLocaleString()}</h6><del> {item.olderprice}</del>
                    <ul className="info-list">
                      {/* {item.features.map((features, i) =>
                            <li key={i} ><i className={features.icon} /> {features.title}</li>
                          )} */}
                      {/* <li><img src={publicUrl + "/assets/img/icons/7.png"} alt={imagealt} /> {item.area}  </li> */}
                    </ul>
                    <ul className="contact-list bg-white">
                      <li><Link className="message" to={{ pathname: "/add-property", state: { data: item, update: "true" } }}><img style={{ width: "15px" }} src={Update_Icon} alt="img" /></Link></li>
                      <li data-toggle="tooltip" data-placement="top" title={item.status == "inactive" ? "Active Property" : "Deactivate Property"}>
                        <a className="phone" >
                          <StatusBtn
                            id={item._id}
                            text={item.status == "inactive" ? "Active This Property" : "Deactivate This Property"}
                            endpoint="propertyStatusChange"
                            status={item.status == "inactive" ? "active" : "inactive"}
                            AlertTitle={item.status == "inactive" ? "Activated" : "Deactivated"}
                            AlertText={item.status == "inactive" ? "Property Activated Successfully" : "Property Deactivated Successfully"} />
                        </a>
                      </li>
                      <DeleteBtn value={item._id} />
                      <li className="readeal-top"><Link className="btn btn-yellow" to={{
                        pathname: "/propertydetails",
                        state: { i: i, data: item }
                      }} >View Details</Link></li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </TabPanel>

        </Box>
      </div>
  );
}
