import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';
import { TextField, Checkbox } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import main from "./../../video/main.mp4";
import style from "../../assets/css/style.css";
import AuthHelper from '../../Helpers/AuthHelper';
import MediaHelper from '../../Helpers/Media';
import Button from '@mui/material/Button';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import config from "../../config";
import Dropzone from "./dropzone";
import UploadMedia from "../../Helpers/MediaHelper";
import { error } from 'jquery';





const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#fda94f',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#fda94f',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#e5e5e9',
    },
    '&:hover fieldset': {
      borderColor: '#fda94f',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#fda94f',
    },
  },
});

class RegistrationComponent extends Component {

  constructor(props) {
    super(props);

    this.state = {
      agency: false,
      name: '',
      email: '',
      email_login: '',
      phone: '',
      password_login: '',
      c_password: '',
      messages: '',

      title: "",
      password: "",
      phone: "",
      about_agency: "",
      location: "",
      orn: "",
      file: "",
      img_name: "",
      propertyImageURL: "",
      propertyImage: [],
      loading:false
    };
    this.uploadFileElem = React.createRef();

  }

  hendalRegister = () => {

    this.setState({loading:true})


    if (this.state.password != this.state.c_password) {
      this.Alerterror("error", "Oops...", "password dose not match")
      return false;
    }
    var data = {
      name: this.state.name,
      email: this.state.email,
      phone: this.state.phone,
      password: this.state.password
    };
    AuthHelper.Signup(data).then((resp) => {
      if (resp.data.status != "success" && resp.data.status != undefined) {
        console.log(resp.data.status);
        let str = JSON.stringify(resp.data.status)
        str = str.replace(/"/g, " ");
        str = str.replace(/`/g, " ");
        str = str.substring(1, str.length - 1);
        this.setState({ messages: str });
        this.Alerterror("error","Oops...",this.state.messages)
      } else {
        this.Alert("success", "Registered", "Successfully Registered");
        setTimeout(() => {
          window.location.href = "/login"
        }, 4000);

      }
    });

  };
  hendalAgencyRegister = () => {
    this.setState({loading:true})
    const data = this.state
    console.log(data)
    fetch(config.URL + "users/add_agency", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    }).then((result) => {
      if (result.status == 200){
        console.log("Result:", result);
        this.addImgName();
      }
      else{
        this.setState({loading:false})
        this.Alerterror("error", "Oops...", "Something went wrong!")
      }
    })
  };

  savingImage = async () => {
    var img = this.state.file;

    await MediaHelper.Upload(img).then((resp) => {
      var data = JSON.parse(resp.data)
      this.setState({ img_name: data.img[0] })
      this.addImgName();
    })


  };
  addImgName = () => {
    const data = this.state
    console.log(data)
    fetch(config.URL + "users/addImgName", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    }).then((result) => {
      this.Alert("success", "Registered", "Agency Successfully Registered")
    })

  };
  // alert = (icon) =>{
  //   Swal.fire({
  //     position: 'center',
  //     icon: icon,
  //     title: this.state.messages,
  //     showConfirmButton: false,
  //     timer: 3000,
  //     width:"500px",
  //     timerProgressBar:true,

  //   });
  //   window.location.href="/login"
  // };
  Alert = (icon, title, msg) => {
    Swal.fire({
      position: 'center',
      icon: icon,
      title: title,
      text: msg,
      showConfirmButton: false,
      timer: 3000,
      width: "500px",
      timerProgressBar: true,

    });
    setTimeout(() => {
      window.location.href = "/login"
    }, 4000);
  };
  Alerterror = (title) => {
    Swal.fire({
      position: 'center',
      icon: "error",
      title: title,
      showConfirmButton: false,
      timer: 3000,
      width: "500px",
      timerProgressBar: true,

    });
    setTimeout(() => {
    }, 4000);
    this.setState({loading:false})
  };

  validation = () => {
    this.state.name == ""?
      this.Alerterror("Name can't be empty")
    :
    this.state.email == ""?
      this.Alerterror("Email can't be empty")
    :
    this.isValidEmail(this.state.email) == false?
      this.Alerterror("Please enter a valid Email")
    :
    this.state.phone == ""?
      this.Alerterror("Phone Number can't be empty")
    :
    this.state.password == ""?
      this.Alerterror("Password can't be empty")
    :
    this.state.password != this.state.c_password?
      this.Alerterror("Passwords doesn't match")
    :
    this.hendalRegister()
  }
  agencyValidation = () => {
    this.state.title == ""?
      this.Alerterror("Agency Title can't be empty")
    :
    this.state.email == ""?
      this.Alerterror("Email can't be empty")
    :
    this.isValidEmail(this.state.email) == false?
      this.Alerterror("Please enter a valid Email")
    :
    this.state.password == ""?
    this.Alerterror("Password can't be empty")
    :
    this.state.phone == ""?
      this.Alerterror("Phone Number can't be empty")
    :
    this.state.about_agency.length < 50?
      this.Alerterror("Please write something about your agency (At least 50 letters)")
    :
    this.state.location == ""?
      this.Alerterror("Location can't be empty")
    :
    this.state.orn == ""?
      this.Alerterror("ORN can't be empty")
    :
    this.hendalAgencyRegister()
  }
  isValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    return emailRegex.test(email);
  };





  render() {

    let publicUrl = '' + '/'
    let imagealt = 'image'
    let data = sectiondata.aboutus
    const Input = styled('input')({
      display: 'none',
    });




    return <div className='reg_background_image'>
      <div className="col-xl-4 col-lg-5 col-md-6" style={{ marginLeft: "auto", paddingTop: "100px" }}>
        <div className="contact-form-wrap ">
          <div className="row ">
            <div className="col-lg-4"><h4>SignUp As</h4></div>
            <div className="col-lg-3 col-5 mb-3">
              <a className={`btn ${this.state.agency == false ? "btn-yellow" : "btn-yellow-outline"} float-right text-white ml-2`} onClick={() => this.setState({ agency: false })}>User</a>
            </div>
            <div className="col-lg-3 col-5 mb-3">
              <a className={`btn ${this.state.agency == true ? "btn-yellow" : "btn-yellow-outline"} float-right text-white`} onClick={() => this.setState({ agency: true })}>Agency</a>
            </div>
          </div>

          {this.state.agency == false ?

            <div className="col-md-12">
              <div className="section-title">
                <CssTextField fullWidth label="Name" id="custom-css-outlined-input" onChange={(e) => {
                  this.setState({ name: e.target.value }, console.log(e.target.value))
                }} />
                <CssTextField fullWidth  label="Email" id="custom-css-outlined-input" margin="dense" onChange={(e) => {
                  this.setState({ email: e.target.value }, console.log(e.target.value))
                }} />
                <CssTextField fullWidth label="Phone" id="custom-css-outlined-input" margin="dense" onChange={(e) => {
                  this.setState({ phone: e.target.value }, console.log(e.target.value))
                }} />
                <CssTextField  fullWidth type="password" label="Password" id="custom-css-outlined-input" margin="dense" onChange={(e) => {
                  this.setState({ password: e.target.value }, console.log(e.target.value))
                }} />
                <CssTextField fullWidth type="password" label="Re-enter Password" id="custom-css-outlined-input" margin="dense" onChange={(e) => {
                  this.setState({ c_password: e.target.value }, console.log(e.target.value))
                }} />
              </div>
            </div>
            :
            <div className="col-md-12">
              <div className="section-title">
                <CssTextField fullWidth label="Title" id="custom-css-outlined-input" onChange={(e) => {
                  this.setState({ title: e.target.value }, console.log(e.target.value))
                }} />
                <CssTextField fullWidth label="Email" id="custom-css-outlined-input" margin="dense" onChange={(e) => {
                  this.setState({ email: e.target.value }, console.log(e.target.value))
                }} />
                <CssTextField type="password" fullWidth label="Password" id="custom-css-outlined-input" margin="dense" onChange={(e) => {
                  this.setState({ password: e.target.value }, console.log(e.target.value))
                }} />
                <CssTextField fullWidth label="Phone" id="custom-css-outlined-input" margin="dense" onChange={(e) => {
                  this.setState({ phone: e.target.value }, console.log(e.target.value))
                }} />
                <CssTextField multiline rows={5} fullWidth label="About Agency" id="custom-css-outlined-input" margin="dense" onChange={(e) => {
                  this.setState({ about_agency: e.target.value }, console.log(e.target.value))
                }} />
                <CssTextField fullWidth label="Location" id="custom-css-outlined-input" margin="dense" onChange={(e) => {
                  this.setState({ location: e.target.value }, console.log(e.target.value))
                }} />
                <CssTextField fullWidth label="ORN" id="custom-css-outlined-input" margin="dense" onChange={(e) => {
                  this.setState({ orn: e.target.value }, console.log(e.target.value))
                }} />



              </div>
              <div className="row">

                <div className="col-lg-5 mb-3">
                  <label htmlFor="contained-button-file">
                    <Input ref={uploadFile => this.uploadFileElem = uploadFile} accept="image/*" id="contained-button-file" type="file" onChange={(e) => {
                      // this.imageUploaded(e);
                      this.setState({ file: e.currentTarget.files[0] })
                    }} />
                    <div>
                      <Dropzone propertyImageURL={this.state.propertyImageURL} getImage={(e) => this.setState({ propertyImage: e },()=>{
                        var file = this.state.propertyImage;
                        this.setState({loading:true});
                        UploadMedia.UploadMedia(file).then((url)=>{
                          console.warn("Url--->:",url);
                          this.setState({loading:false,img_name:url});
                          // setimgPath(url);
                          // setLoading(false);
                      });
                      })} index={1} />
                    </div>
                  </label>

                </div>

              </div>
              {/* <Mltiimg/> */}

            </div>
          }
          {this.state.agency == false ?
            <div>
              <div className="btn-wrap">
                {this.state.loading?
                <button  className="btn btn-yellow" disabled>
                  <i class="fa fa-refresh fa-spin"></i> Loading...
                </button>
                :
                <button onClick={() => !this.state.loading?this.validation():null} className="btn btn-yellow">Register</button>
                }
              </div>
            </div>
            :
            <div>
              <div className="btn-wrap">
                {this.state.loading?
                <button  className="btn btn-yellow" disabled>
                  <i class="fa fa-refresh fa-spin"></i> Loading...
                </button>
                :
                <button onClick={() => !this.state.loading?this.agencyValidation():null} className="btn btn-yellow">Register</button>
                }
              </div>
            </div>
          }

          <ul className="social-icon">
            <li className="ml-0">
              <a href="" target="_blank"><i className="fa fa-facebook  " /></a>
            </li>
            <li>
              <a href="" target="_blank"><i className="fa fa-twitter  " /></a>
            </li>
            <li>
              <a href="" target="_blank"><i className="fa fa-linkedin" /></a>
            </li>
          </ul>
        </div>
      </div>
    </div>

  }
}

export default RegistrationComponent