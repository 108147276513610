import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import SearchListSection from './section-components/search-list';
import Footer from './global-components/footer';
import main from "./../assets/img/bannerImg3.jpg"

const SearchList = () => {
    return <div>
        <Navbar />
        <PageHeader video={main} headertitle="Apartment For Sale"/>
        <SearchListSection />
        <Footer />
    </div>
}

export default SearchList

