import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import InputRange from 'react-input-range';
import ProductHelper from "../../Helpers/ProductHelper";
import { Box, Slider } from '@mui/material';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import 'react-input-range/lib/css/index.css';
import RangeSlider from '../../components/section-components/rangeSlider'
import RangeSize from '../../components/section-components/rangeSize'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import List from '../../assets/img/list.png';
import Grid from '../../assets/img/grid.png';
import config from "../../config";
import FavoriteBtn from './favorite-btn'

class SearchList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: { min: 2, max: 10 },
      data: [],
      priceRange: [0, 5000000],
      landmark: "",
      propertyType: 0,
      rangeSize: 600,
      parking: 1,
      bathroom: 0,
      bedroom: 0,
      pool: false,
      pets: false,
      ac: false,
      waterView: false,
      rent: false,
      favorite: false,
    };
  }

  componentDidMount = () => {
    if (this.props.data) {
      this.setState({
        bedroom: this.props.data.NumberOfRooms,
        priceRange: this.props.data.Price,
        landmark: this.props.data.landmark_location,
        propertyType: this.props.data.property_Type,
      }, () => {
        this.findProperty()
      })
    }
    ProductHelper.Get().then((resp) => {
      console.warn(resp.data.data.product);
      this.setState({ data: resp.data.data.product })
    })
  }

  findProperty = () => {
    var data = {
      landmark: this.state.landmark,
      propertyType: this.state.propertyType,
      priceRange: this.state.priceRange,
      rangeSize: this.state.rangeSize,
      bathroom: this.state.bathroom,
      bedroom: this.state.bedroom,
      parking: this.state.parking,
      pool: this.state.pool,
      pets: this.state.pets,
      ac: this.state.ac,
      waterView: this.state.waterView,
    }
    ProductHelper.FilterProduct(data).then((resp) => {
      this.setState({ data: resp.data.data.products })
    }).catch((e) => {
      alert(e.msg)
    })
  }
  findProperty = () => {
    var data = {
      landmark: this.state.landmark,
      propertyType: this.state.propertyType,
      priceRange: this.state.priceRange,
      rangeSize: this.state.rangeSize,
      bathroom: this.state.bathroom,
      bedroom: this.state.bedroom,
      parking: this.state.parking,
      pool: this.state.pool,
      pets: this.state.pets,
      ac: this.state.ac,
      waterView: this.state.waterView,
    }
    ProductHelper.FilterProduct(data).then((resp) => {
      this.setState({ data: resp.data.data.products })
    }).catch((e) => {
      alert(e.msg)
    })
  }
  // add_favorite = () => {
  //   const data = {user_id: cookie.load('Tokken')};
  //    fetch(config.URL+"/product/add_property",{
  //     method:'POST',
  //     headers:{
  //       'Accept':'application/json',
  //       'Content-Type':'application/json',
  //     },
  //     body:JSON.stringify(data)
  //   }).then((result)=>{
  //     console.log(result)
  //   })
  // }

  render() {

    let publicUrl = ''
    let imagealt = 'image'
    let data = sectiondata.searchlist


    return <div className="search-page-wrap">
      <div className="search-container">
        <div className="container mt-5">
          <div className="row ">
            <div className="col-xl-3 col-lg-4 sitebar">
              <h6 className="filter-title mb-4"><img className="mr-3" src={publicUrl + "/assets/img/icons/18.png"} alt="img" />Filter</h6>
              <form className="widget widget-sidebar-search-wrap">
                <div className="widget-sidebar-search">
                  <div className="title">Any Price</div>
                  <div className="widget-sidebar-item-wrap btn-area" style={{ justifyContent: "space-between", display: "flex" }}>
                    <a className={`btn ${this.state.rent == false ? "btn-yellow" : "btn-yellow-outline"} float-right text-white`} onClick={() => this.setState({ rent: !this.state.rent })}>For Buy</a>
                    <a className={`btn ${this.state.rent == true ? "btn-yellow" : "btn-yellow-outline"} float-right text-white`} onClick={() => this.setState({ rent: !this.state.rent })}>For Rent</a>
                  </div>
                  <div className="widget-sidebar-item-wrap rld-single-input left-icon">
                    <input type="text" placeholder="Entry Landmark Location" value={this.state.landmark} onChange={(e) => {
                      this.setState({ landmark: e.target.value }, () => {
                        this.findProperty()
                      })
                    }} />
                  </div>
                  <div className="widget-sidebar-item-wrap rld-single-select">
                    <select className="select single-select" onChange={(e) => {
                      this.setState({ propertyType: e.target.value }, () => {
                        this.findProperty()
                      })
                    }}>
                      <option value={0} selected={this.state.propertyType == "0" ? "true" : null}>Property Type</option>
                      <option value={1} selected={this.state.propertyType == "1" ? "true" : null}>Apartment</option>
                      <option value={2} selected={this.state.propertyType == "2" ? "true" : null}>Villa</option>
                      <option value={3} selected={this.state.propertyType == "3" ? "true" : null}>Townhouse</option>
                      <option value={4} selected={this.state.propertyType == "4" ? "true" : null}>Penthouse</option>
                      <option value={5} selected={this.state.propertyType == "5" ? "true" : null}>Land</option>
                      <option value={6} selected={this.state.propertyType == "6" ? "true" : null}>Full Building</option>
                      <option value={7} selected={this.state.propertyType == "7" ? "true" : null}>Hotel Apartment</option>
                      <option value={8} selected={this.state.propertyType == "8" ? "true" : null}>Compound</option>
                      <option value={9} selected={this.state.propertyType == "9" ? "true" : null}>Duplex</option>
                      <option value={10} selected={this.state.propertyType == "10" ? "true" : null}>Full Floor</option>
                    </select>
                  </div>
                  <div className="widget-sidebar-item-wrap rld-price-slider-wrap">
                    <div className="title">Any Price</div>
                    <RangeSlider setValue={this.state.priceRange} getValue={(e) => {
                      this.setState({ priceRange: e }, () => {
                        this.findProperty()
                      })
                    }} />

                  </div>
                  <div className="widget-sidebar-item-wrap rld-price-slider-wrap">
                    <div className="title">Size (sq feet)</div>
                    <RangeSize getValue={(e) => {
                      this.setState({ rangeSize: e }, () => {
                        this.findProperty()
                      })
                    }} />
                  </div>
                  <div className="row">
                    <div className='col'>
                      <FormGroup>
                        <FormControlLabel control={<Checkbox onClick={() => {
                          this.setState({ 'pets': !this.state.pets }, () => {
                            this.findProperty()
                          })
                        }}
                          sx={{
                            color: "#fda94f",
                            '&.Mui-checked': {
                              color: "#fda94f",
                            },
                          }}
                        />} label="Pets" />
                      </FormGroup>
                    </div>
                    <div className='col'>
                      <FormGroup>
                        <FormControlLabel control={<Checkbox onClick={() => {
                          this.setState({ 'pool': !this.state.pool }, () => {
                            this.findProperty()
                          })
                        }}
                          sx={{
                            color: "#fda94f",
                            '&.Mui-checked': {
                              color: "#fda94f",
                            },
                          }}
                        />} label="Pool" />
                      </FormGroup>
                    </div>
                    <div className='col'>
                      <FormGroup>
                        <FormControlLabel control={<Checkbox onClick={() => {
                          this.setState({ 'ac': !this.state.ac }, () => {
                            this.findProperty()
                          })
                        }}
                          sx={{
                            color: "#fda94f",
                            '&.Mui-checked': {
                              color: "#fda94f",
                            },
                          }}
                        />} label="AC" />
                      </FormGroup>
                    </div>
                    <div className='col'>
                      <FormGroup>
                        <FormControlLabel control={<Checkbox onClick={() => {
                          this.setState({ 'waterView': !this.state.waterView }, () => {
                            this.findProperty()
                          })
                        }}
                          sx={{
                            whiteSpace: 'nowrap',
                            color: "#fda94f",
                            '&.Mui-checked': {
                              color: "#fda94f",
                            },
                          }}
                        />} label={<span style={{ whiteSpace: 'nowrap' }}>Water View</span>} />
                      </FormGroup>
                    </div>
                  </div>


                  <div className="widget-sidebar-item-wrap rld-single-select-wrap mt-4">
                    <div className="title d-inline-block float-left mb-0 pt-2">Bedroom</div>
                    <div className="rld-single-select d-inline-block float-right">
                      <select onChange={(e) => {
                        this.setState({ bedroom: e.target.value }, () => {
                          this.findProperty()
                        })
                      }} className="select single-select">
                        <option value={0} selected={this.state.bedroom == "0" ? "true" : null}>Any</option>
                        <option value={1} selected={this.state.bedroom == "1" ? "true" : null}>1</option>
                        <option value={2} selected={this.state.bedroom == "2" ? "true" : null}>2</option>
                        <option value={3} selected={this.state.bedroom == "3" ? "true" : null}>3</option>
                        <option value={4} selected={this.state.bedroom == "4" ? "true" : null}>4</option>
                        <option value={5} selected={this.state.bedroom == "5" ? "true" : null}>5</option>
                      </select>
                    </div>
                  </div>
                  <div className="widget-sidebar-item-wrap rld-single-select-wrap">
                    <div className="title d-inline-block float-left mb-0 pt-2">Bathroom</div>
                    <div className="rld-single-select d-inline-block float-right">
                      <select onChange={(e) => {
                        this.setState({ bathroom: e.target.value }, () => {
                          this.findProperty()
                        })
                      }} className="select single-select">
                        <option value={0}>Any</option>
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                        <option value={4}>4</option>
                        <option value={5}>5</option>
                      </select>
                    </div>
                  </div>

                </div>
                <div className="btn-wrap text-center">
                  <div className="btn btn-yellow" onClick={() => this.findProperty()}><span className="left"><i className="fa fa-search" /></span>Find Property</div>
                </div>
              </form>
            </div>
            <div className="col-xl-9 col-lg-9">
              <div className="row mb-3">
                <div className="col-md-9 col-sm-8">
                  <h6 className="filter-title mt-3 mb-lg-0">{`${this.state.data.length} Properties`}</h6>
                </div>
                <div className="col-md-3 col-sm-4">
                  <div className='justify-content-end d-flex'>
                    <Link to={{ pathname: "/properties", query: "" }}>
                      <img className='mx-1' src={Grid} width="20px" />
                    </Link>
                    <Link to={{ pathname: "/properties-list", query: "" }}>
                      <img className='mx-1' src={List} width="20px" />
                    </Link>
                  </div>
                </div>
              </div>

              {this.state.data.map((item, i) =>
                <div key={i} className="single-feature style-two">
                  <div className="thumb">
                    <img src="https://images-cdn2.houza.com/images/properties/2021/12/06/AxJyEBFwb4_sKHh.jpg?profile=xs" alt="img" />
                  </div>
                  <div className="details">
                    <div className="details-wrap">
                      <a href="" className="feature-logo">
                        <img src="https://images-cdn2.houza.com/images/properties/2021/12/06/AxJyEBFwb4_sKHh.jpg?profile=xs" alt="img" />
                      </a>
                      <p className="author"><i className="fa fa-user" /> {item.location}</p>
                      <h6 className="title readeal-top"><Link to={{
                        pathname: `/property-details/${item._id}/${JSON.stringify(this.state)}`,
                        state: item
                      }}>{item.house_title.length<50?item.house_title: item.house_title.slice(0,50)+"..."}</Link></h6>
                      <h6 className="price">AED {item.price}</h6><del> {item.olderprice}</del>
                      <ul className="info-list border-0 pt-0">
                        <div className='row'>
                          <div className='col'><i className={"fa fa-bed"} style={{color:"#fda94f",fontSize:"22px"}} /> {item.bed_rooms} Bedrooms</div>
                          <div className='col'><i className={"fa fa-bath"} style={{color:"#fda94f",fontSize:"22px"}} /> {item.bathrooms} Bathroom</div>
                          <div className='col'><img src={publicUrl + "/assets/img/icons/7.png"} width="18px" alt={imagealt} /> {item.area} sq.ft</div>
                        </div>
                      </ul>
                      <ul className="contact-list">
                        <li><a className="phone" href=""><i className="fa fa-phone" /></a></li>
                        <li><a className="message" href=""><img src={publicUrl + "/assets/img/icons/8.png"} alt="img" /></a></li>
                        <FavoriteBtn value={item._id}/>
                        <li className="readeal-top"><Link className="btn btn-yellow" to={{
                          pathname: `/property-details/${item._id}/${JSON.stringify(this.state)}`,
                          state: item
                        }} >View Details</Link></li>
                      </ul>
                    </div>
                  </div>
                </div>
              )}

            </div>
          </div>
        </div>
      </div>
    </div>
  }
}

export default SearchList