import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import FaqSection from './section-components/faq';
import Footer from './global-components/footer';
import banner from "./../assets/img/faq.png"


const Faq = () => {
    return <div>
        <img src={banner} width="100%" style={{position:'relative',top:"0px"}}/>
        <Navbar />
        <FaqSection />
        <Footer />
    </div>
}

export default Faq

