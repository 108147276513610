import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import PropertyComponent from './section-components/property-details';
import Footer from './global-components/footer';
import { useEffect } from 'react';
import main from "./../assets/img/bannerImg5.jpeg"
import banner from "./../assets/img/realestate.jpg"

const Registration = (props) => {
    useEffect(() => {
      
        console.log("Agencies:",props.location.state.data)
      })
    return <div>
        <PageHeader video={main} headertitle="" />
        {/* <img src={main} width="100%" height="800px" style={{position:'absolute',top:"0px",objectFit:"cover"}}/> */}
        <Navbar />
        {/* <PageHeader video={main} headertitle="Add Property" /> */}
        <PropertyComponent item = {props.location.state.data} agency = {props.location.state.data.agency} />

        
        <Footer />
    </div>
}

export default Registration

