import React from 'react'
import SearchGrid from './search-grid'
import { useEffect,useMemo  } from 'react';



export default function Buy(props) {

    
  return (
    <>
        <SearchGrid location={props.location}/>
    </>
  )
}
