import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import WhatsappLead from './section-components/WhatsappLead-Component';
import Footer from './global-components/footer';
import banner from "./../assets/img/whatsapp.png"

const whatsappLead = () => {
    return <div>
        <img src={banner} width="100%" style={{position:'relative',top:"0px"}}/>
        <Navbar />
        <WhatsappLead />

        
        <Footer />
    </div>
}

export default whatsappLead

