import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';
import main from "./../../video/main.mp4"
import RangeSlider from '../../components/section-components/rangeSlider'
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import { Rotate } from 'react-reveal';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';



class Banner extends Component {

  constructor(props) {
    super(props);

    this.state = {
      landmark: "",
      propertyType: "",
      minbeds: "",
      minbath: "",
      price: [0, 9900000],
      rent: false,
      commercial:false
    };
  }

  componentDidMount() {



    const $ = window.$;

    if ($('.single-select').length) {
      $('.single-select').niceSelect();
    }


  }

  render() {

    let publicUrl = ''
    let imagealt = 'image'
    let data = sectiondata.banner

    const inlineStyle = {
      height: '800px'
    }

    return <div className="banner-area jarallax" style={inlineStyle}>
      {/* <video width="100%" height="976" loop muted autoplay="true" style={{position:'absolute',top:"0px"}}>
            <source src={main} type="video/mp4" />
          </video> */}
      <div className="container">
        <div className="banner-inner-wrap">
          <div className="row">
            <Slide left>
              <div className="col-12">
                <div className="banner-inner">
                  <h5 className="sub-title" style={{color:"#ef907e"}}>{data.subtitle}</h5>
                  <h1 className="title" style={{color:"#ff8300ad"}}>{data.title1} <br /> {data.title2}</h1>
                </div>
              </div>
            </Slide>
              <div className="col-12">
                <div className="banner-search-wrap">
                  <ul className="nav nav-tabs rld-banner-tab">
                    <li className="nav-item">
                      <a className="nav-link active " data-toggle="tab" href="#tabs_1" onClick={() => this.setState({ rent: false })}>For Buy</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" data-toggle="tab" href="#tabs_2" onClick={() => this.setState({ rent: true })}>For Rent </a>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div className="tab-pane fade show active" id="tabs_1">
                    <div className="rld-main-search" style={{backgroundColor:"#fffffff0"}}>
                        <div className="row">
                          {/* <div className=" col-lg-3 col-md-6">
                            <div className="rld-single-input left-icon">
                              <input type="text" placeholder="Entry Landmark Location" onChange={(e) => {
                                this.setState({ landmark: e.target.value })
                              }} />
                            </div>
                          </div> */}

                          <div className="col-lg-4 col-md-6 pl-0 pb-md-0 pb-2">
                            <TextField fullWidth 
                              id=""
                              label="Enter a neighborhood"
                              variant="outlined"
                              value={this.state.landmark}
                              InputProps={{
                                startAdornment: <InputAdornment position="start"><AddLocationAltIcon /></InputAdornment>
                              }}
                              onChange={(e) => {
                                this.setState({ landmark: e.target.value })
                              }}

                            />

                          </div>

                          <div className="col-lg-2 col-md-6 pl-0 pb-md-0 pb-2">
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">Property Type</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={this.state.propertyType}
                                label="Property Type"
                                onChange={(event) => { this.setState({ propertyType: event.target.value }) }}
                              >
                                <MenuItem value={"Apartment"}>Apartment</MenuItem>
                                <MenuItem value={"Compound"}>Compound</MenuItem>
                                <MenuItem value={"Duplex"}>Duplex</MenuItem>
                                <MenuItem value={"Full Building"}>Full Building</MenuItem>
                                <MenuItem value={"Full Floor"}>Full Floor</MenuItem>
                                <MenuItem value={"Hotel Apartment"}>Hotel Apartment</MenuItem>
                                <MenuItem value={"Land"}>Land</MenuItem>
                                <MenuItem value={"Penthouse"}>Penthouse</MenuItem>
                                <MenuItem value={"Townhouse"}>Townhouse</MenuItem>
                                <MenuItem value={"Villa"}>Villa</MenuItem>
                              </Select>
                            </FormControl>
                          </div>

                          <div className="col-lg-2 col-md-3 col-6 pl-0">
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">Beds</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={this.state.minbeds}
                                label="Min Beds"
                                
                                onChange={(event) => { this.setState({ minbeds: event.target.value }) }}
                              >
                                <MenuItem value={1}>1</MenuItem>
                                <MenuItem value={2}>2</MenuItem>
                                <MenuItem value={3}>3</MenuItem>
                                <MenuItem value={4}>4</MenuItem>
                                <MenuItem value={5}>5</MenuItem>
                                <MenuItem value={6}>6</MenuItem>
                                <MenuItem value={7}>7+</MenuItem>
                              </Select>
                            </FormControl>
                          </div>

                          <div className="col-lg-2 col-md-3 col-6 pl-0">
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">Bath</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={this.state.minbath}
                                label="Bath"
                                onChange={(event) => { this.setState({ minbath: event.target.value }) }}
                              >
                                <MenuItem value={1}>1</MenuItem>
                                <MenuItem value={2}>2</MenuItem>
                                <MenuItem value={3}>3</MenuItem>
                                <MenuItem value={4}>4</MenuItem>
                                <MenuItem value={5}>5</MenuItem>
                                <MenuItem value={6}>6</MenuItem>
                                <MenuItem value={7}>7+</MenuItem>
                              </Select>
                            </FormControl>
                          </div>

                          <div className="col-lg-2 col-md-6">
                            <div className="widget-sidebar-item-wrap rld-price-slider-wrap">
                              <div className="title">Price</div>
                              <RangeSlider setValue={this.state.price} getValue={(e) => {
                                this.setState({ price: e });
                              }} />

                            </div>
                          </div>
                          {/* <div className="col-xl-9 col-lg-8 col-md-8 d-md-block d-none"></div> */}
                          <div className="col-xl-4 col-lg-5 col-md-5 readeal-top" style={{margin:"auto"}}>
                            <Link className="btn btn-yellow" to={{ pathname: "/properties", state: { rent: this.state.rent ,commercial:this.state.commercial, minbath:this.state.minbath,minbeds:this.state.minbeds,landmark:this.state.landmark,propertyType:this.state.propertyType,price:this.state.price} }}
                            >SEARCH NOW</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="tabs_2">
                      <div className="rld-main-search">
                        <div className="row">
                          {/* <div className=" col-lg-3 col-md-6">
                            <div className="rld-single-input left-icon">
                              <input type="text" placeholder="Entry Landmark Location" onChange={(e) => {
                                this.setState({ landmark: e.target.value })
                              }} />
                            </div>
                          </div> */}

                          <div className="col-lg-4 col-md-6 pl-0">
                            <TextField fullWidth 
                              id=""
                              label="Enter a neighborhood"
                              variant="outlined"
                              value={this.state.landmark}
                              InputProps={{
                                startAdornment: <InputAdornment position="start"><AddLocationAltIcon /></InputAdornment>
                              }}
                              onChange={(e) => {
                                this.setState({ landmark: e.target.value })
                              }}

                            />

                          </div>

                          <div className="col-lg-2 col-md-6 pl-0">
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">Property Type</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={this.state.propertyType}
                                label="Property Type"
                                onChange={(event) => { this.setState({ propertyType: event.target.value }) }}
                              >
                                <MenuItem value={"Apartment"}>Apartment</MenuItem>
                                <MenuItem value={"Compound"}>Compound</MenuItem>
                                <MenuItem value={"Duplex"}>Duplex</MenuItem>
                                <MenuItem value={"Full Building"}>Full Building</MenuItem>
                                <MenuItem value={"Full Floor"}>Full Floor</MenuItem>
                                <MenuItem value={"Hotel Apartment"}>Hotel Apartment</MenuItem>
                                <MenuItem value={"Land"}>Land</MenuItem>
                                <MenuItem value={"Penthouse"}>Penthouse</MenuItem>
                                <MenuItem value={"Townhouse"}>Townhouse</MenuItem>
                                <MenuItem value={"Villa"}>Villa</MenuItem>
                              </Select>
                            </FormControl>
                          </div>

                          <div className="col-lg-2 col-md-3 col-6 pl-0">
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">Beds</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={this.state.minbeds}
                                label="Min Beds"
                                onChange={(event) => { this.setState({ minbeds: event.target.value }) }}
                              >
                                <MenuItem value={1}>1</MenuItem>
                                <MenuItem value={2}>2</MenuItem>
                                <MenuItem value={3}>3</MenuItem>
                                <MenuItem value={4}>4</MenuItem>
                                <MenuItem value={5}>5</MenuItem>
                                <MenuItem value={6}>6</MenuItem>
                                <MenuItem value={7}>7+</MenuItem>
                              </Select>
                            </FormControl>
                          </div>

                          <div className="col-lg-2 col-md-3 col-6 pl-0">
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">Bath</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={this.state.minbath}
                                label="Bath"
                                onChange={(event) => { this.setState({ minbath: event.target.value }) }}
                              >
                                <MenuItem value={1}>1</MenuItem>
                                <MenuItem value={2}>2</MenuItem>
                                <MenuItem value={3}>3</MenuItem>
                                <MenuItem value={4}>4</MenuItem>
                                <MenuItem value={5}>5</MenuItem>
                                <MenuItem value={6}>6</MenuItem>
                                <MenuItem value={7}>7+</MenuItem>
                              </Select>
                            </FormControl>
                          </div>

                          <div className="col-lg-2 col-md-6">
                            <div className="widget-sidebar-item-wrap rld-price-slider-wrap">
                              <div className="title">Price</div>
                              <RangeSlider setValue={this.state.price} getValue={(e) => {
                                this.setState({ price: e });
                              }} />

                            </div>
                          </div>
                          {/* <div className="col-xl-9 col-lg-8 col-md-8 d-md-block d-none"></div> */}
                          <div className="col-xl-4 col-lg-5 col-md-5 readeal-top justify-center" style={{margin:"auto"}}>
                            <Link className="btn btn-yellow" to={{ pathname: "/properties", state: { rent: this.state.rent ,commercial:this.state.commercial, minbath:this.state.minbath,minbeds:this.state.minbeds,landmark:this.state.landmark,propertyType:this.state.propertyType,price:this.state.price} }}
                            >SEARCH NOW</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>

  }
}

export default Banner