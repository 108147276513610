import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

export default function TAC() {

    return (
        <>
            <div className="container mt-5">
                <div className="row justify-content-center">
                    <div className="col-xl-9 col-lg-10">
                        <div className="section-title text-center">
                            <h2 className='font-weight-bold pb-2 pt-5'>TERMS & CONDITION</h2>
                        </div>

                        <div className="row">

                            <div className="col-lg-12 mb-3 text-end">
                                <h4 className='font-weight-bold pb-2 pt-5'>Definitions and Interpretation</h4>
                                <ul>
                                    <li>In these terms:</li>
                                    <li><b>Advertisement</b> means any advertisement placed, or sought to be placed, on the Website;</li>
                                    <li><b>Advertiser</b> means any person placing or seeking to place an advertisement or any other content on the Website;</li>
                                    <li><b>Applicable</b> Laws means all applicable national, state, local and municipal legislation, regulations, statutes, by-laws, and other laws and any other instrument or direction from officials having the force of law as may be issued and in force from time to time;</li>
                                    <li><b>Privacy Policy</b> means the privacy policy displayed on the Website;</li>
                                    <li><b>Terms</b> means these terms and conditions of use;</li>
                                    <li><b>User</b> means any user of or visitor to the Website, whether active or passive, including Advertisers; we, us and our refer to Houza.com Limited, the operator of the Website;</li>
                                    <li><b>Website</b> means https://mulqiat.com/ and any subsidiary or related pages; the words ‘including’ and similar should be construed without limitation; references to a ‘person’ includes a natural person, legal person, a firm, a body corporate, an unincorporated association, or an authority, and such entities’ legal successors; a word that suggests one gender includes all genders; a singular word includes the plural and vice versa; and headings are for convenience only and do not affect the interpretation of these Terms.</li>
                                </ul>
                                <h4 className='font-weight-bold pb-2 pt-5'>Application</h4>
                                <p>
                                    These terms apply to all Users of the Website. A User’s right to access the Website is conditional on acceptance of these Terms, and the Privacy Policy, which are not negotiable. By viewing or accessing the Website in any manner, Users agree to these Terms and the Privacy Policy. We reserve the right to update these Terms and the Privacy Policy without notice. Each time a User accesses the Website, by doing so he or she agrees to the current version of the Terms and the Privacy Policy as are displayed on the Website at the time of access. If you do not agree to these Terms or the Privacy Policy, you should not access the Website.
                                </p>
                                <p>If any part of these Terms or the Privacy Policy are found by a court or administrative body of competent jurisdiction to be void or invalid, the remainder of the Terms and the Privacy Policy shall remain in full force and effect.</p>
                                <h4 className='font-weight-bold pb-2 pt-5'>Users’ Rights and Responsibilities</h4>
                                <p>
                                    We grant Users the right to temporarily download copies of information or software on the Website for transitory viewing only.
                                </p>
                                <p>Users must be at least 18 years old (provided that these Terms shall still apply to Users who are in breach of this provision).</p>

                                <ul>
                                    <li>Users may not:</li>
                                    <li>use the Website in any manner which is contrary to Applicable Laws;</li>
                                    <li>share their account details for the Website with any third party;</li>
                                    <li>modify or copy any material on the Website for display or use elsewhere;</li>
                                    <li>attempt to decompile or reverse engineer any software or other material contained on the Website;</li>
                                    <li>remove any copyright or other proprietary notations from materials found on the Website;</li>
                                    <li>transfer materials from the Website to another person or mirror the materials on any other server;</li>
                                    <li>use any automated device, software process or means to access, retrieve, scrape, or index the Website or any content on the Website;</li>
                                    <li>interfere or attempt to interfere with the proper working of the Website;</li>
                                    <li>undertake any action that will impose a burden or make excessive traffic demands on our infrastructure;</li>
                                    <li>
                                        use or index any content or data on the Website for purposes of:
                                        <ul>
                                            <li>constructing or populating a searchable database of properties;</li>
                                            <li>building a database of property information;</li>
                                            <li>competing with us in any manner that we have not specifically authorised; or</li>
                                            <li>transmitting spam, chain letters, junk email, surveys, or other mass messaging, whether commercial in nature or not;</li>
                                        </ul>
                                    </li>
                                    <li>use the Website or any content from the Website in any manner other than the purpose for which we have made it available;</li>
                                    <li>violate the rights of any person, including intellectual property rights and rights of confidentiality, in connection with their use of the Website;</li>
                                    <li>pose as any other person, real or fake, on the Website;</li>
                                    <li>reproduce, republish, retransmit, modify, adapt, distribute, translate, create derivative works or adaptation of, publicly display, sell, trade, or in any way exploit the Website or any content on the Website, except as expressly authorised by us; or</li>
                                    <li>transmit or attempt to transmit any computer viruses, worms, defects or other items of a destructive manner.</li>
                                </ul>
                                <p>We reserve the right to deny access to the Website to or cancel the account of any User at our sole discretion, without notice and without giving a reason.</p>
                                <h4 className='font-weight-bold pb-2 pt-5'>Terms Specific to Advertisers</h4>
                                <p>Advertisers may advertise real estate which is available for sale or rental within the geographic area covered by the Website, subject to payment of applicable fees and strict compliance with these Terms.</p>
                                <ul>
                                    <li>Advertisers must:</li>
                                    <li>ensure that they hold the requisite licences and permissions in order to place their Advertisements on the Website;</li>
                                    <li>ensure that their Advertisements and their conduct are in compliance with all Applicable Laws;</li>
                                    <li>ensure that their Advertisements do not infringe the intellectual property or other rights of any third party;</li>
                                    <li>
                                        ensure that their Advertisements are not:
                                        <ul>
                                            <li>misleading or deceptive;</li>
                                            <li>inappropriate having regard to the purpose of the Website;</li>
                                            <li>inappropriate having regard to the purpose of the Website;</li>
                                            <li>materially incorrect;</li>
                                            <li>obscene;</li>
                                            <li>defamatory;</li>
                                            <li>otherwise unlawful or against the customs or norms of Dubai; or</li>
                                            <li>corrupted, due to the presence of a virus or other disabling code; and</li>
                                        </ul>
                                    </li>
                                    <li>comply with the Privacy Policy in respect of all Personal Information of other Users to which they gain access in connection with the Website.</li>
                                    <li>By accepting these terms and conditions, you are opting-in to receive WhatsApp lead messages directly from Houza FZ LLC to your registered mobile number. The contents of these messages will include information of interested property seekers; you will not receive spam messages from houza by opt-ing in for this service.</li>
                                </ul>
                                <p>
                                    Advertisers grant us a worldwide, non-exclusive, royalty-free, perpetual, transferable and irrevocable license to use, reproduce, modify, adapt, translate, distribute, publish, create derivative works from and display and publicly perform the content of their Advertisements throughout the world in any medium, whether currently in existence or not.
                                </p>
                                <p>Advertisers waive any right they may have to require that any personally identifying information be used in connection with the content of any Advertisement, or any derivative work, upgrade or update of the content of an Advertisement.</p>
                                <p>
                                    Advertisers represent and warrant that they have the lawful right including all necessary licenses, rights, consents and permissions to use and authorise us to display the content of their Advertisements.
                                </p>
                                <p>
                                    We reserve the right to refuse to place or remove from the Website any Advertisement which we consider in our sole discretion to be in breach of these Terms, to report conduct to the relevant authorities where we deem it necessary, and to provide such authorities with any information provided to us by an Advertiser in connection with such report. We do not guarantee that Advertisements will be displayed on the website free from errors, and reserve the right to correct any errors without notice.
                                </p>
                                <h4 className='font-weight-bold pb-2 pt-5'>Linking Our Website</h4>
                                <p>
                                    Users may not include links, including ‘deep links’ to the Website in any other website without our prior written consent. Where such consent is given, Users may not link to the Website in a manner which suggests approval or endorsement from us where none exists. We reserve the right to withdraw linking permission without notice and without giving a reason. The Website must not be framed on any other website.
                                </p>
                                <h4 className='font-weight-bold pb-2 pt-5'>Limitation of Liability</h4>
                                <p>We accept no liability in respect of the use of or inability to use the Website, including any downtime, scheduled or otherwise, of the Website, or any virus transmitted via the Website, regardless of fault.</p>
                                <p>We accept no liability in connection with any loss of profit, revenue, goodwill, reputation, data, contract or opportunity, any indirect, special or consequential loss, or any liability under contracts with third parties.</p>
                                <p>We are not a licenced real estate agency. We give no warranties as to the accuracy of Advertisements and accept no liability in respect of their content. We give no warranties as to the identity, qualifications or licences of Advertisers and accept no liability in respect of their conduct. We give no warranties in respect of the accuracy of information provided or the merit of goods or services advertised through third party links displayed on the Website.</p>
                                <p>We accept no liability for any default caused by matters beyond our reasonable control such as an act of God, war, fire, flood, explosion, civil commotion, natural disaster, epidemic, strike, lock-out, action or decision of a competent authority, or interruption in utilities or internet connection.</p>
                                <p>The above limitations are fundamental terms to the agreement between us and Users, in the absence of which it would not be commercially viable to operate the Website in the manner we do.</p>
                                <p>Notwithstanding the foregoing, nothing in these Terms shall be deemed to exclude or limit our liability where it would be unlawful to do so, including liability for death or personal injury caused by our negligence or the negligence of our employees, agents or subcontractors and for fraud or fraudulent misrepresentation.</p>
                                <h4 className='font-weight-bold pb-2 pt-5'>Intellectual Property</h4>
                                <p>Unless otherwise expressly stated, and excluding content added by Advertisers, all intellectual property rights in the contents of the Website are owned, controlled or licensed by us, or one of our affiliates.</p>
                                <p>We, together with our licensors expressly reserve all intellectual property rights in all text, programs, products, processes, technology, content and other materials, which appear on the Website. Access to the Website does not confer and shall not be considered as conferring upon anyone any license under any of our or any third party's intellectual property rights except as expressly stated in these Terms.</p>
                                <p>Access to the Website does not authorise anyone to use any name, logo or mark in any manner whatsoever.</p>
                                <h4 className='font-weight-bold pb-2 pt-5'>Electronic Communications</h4>
                                <p>We will communicate with Users by email or by posting notices on the Website. Users agree that all agreements, notices, disclosures and other communications that are provided to them electronically satisfy any legal requirement that such communications be in writing. All notices from us intended for receipt by a User shall be deemed delivered and effective when sent to the email address registered to the relevant User’s account.</p>
                                <h4 className='font-weight-bold pb-2 pt-5'>Indemnity</h4>
                                <p>Each User indemnifies and holds us and our affiliates, and our and our affiliates’ officers, agents, partners and employees, harmless against any and all loss, liability, claim, demand, expense or fee, including legal fees, arising out of or in connection with the User’s use of or access to the Website or breach of these Terms. This indemnification obligation will survive termination, modification or expiration of the agreement between us and a User and expiration of the User’s use of the Website.</p>
                                <h4 className='font-weight-bold pb-2 pt-5'>Entire Agreemen</h4>
                                <p>These Terms and the Privacy Policy represent the entire agreement between us and Users. Users may not rely on any representations made which are not set out in these Terms or the Privacy Policy, provided that this shall not relieve us from liability for fraudulent misrepresentation.</p>
                                <h4 className='font-weight-bold pb-2 pt-5'>No Waiver</h4>
                                <p>Any delay or forbearance by us in enforcing our rights shall not be construed as a waiver of such rights.</p>
                                <h4 className='font-weight-bold pb-2 pt-5'>Governing Law and Jurisdiction</h4>
                                <p>These Terms, the Privacy Policy, and Users’ access to the Website are governed by and construed in accordance with the laws appliable in the Dubai International Financial Centre. We and Users submit to the exclusive jurisdiction of the courts of the Dubai International Financial Centre, provided that we may bring proceedings against Users in any court or forum of competent jurisdiction to enforce our intellectual property rights or the indemnity obligation set out in these Terms.</p>
                            </div>



                        </div>

                    </div>
                </div>
            </div>
        </>

    );
}