import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';
import Heart from '../../assets/img/heart.svg';
import FilledHeart from '../../assets/img/heart-filled.svg';
import cookie from 'react-cookies';
import config from "../../config";


class Favorite extends Component {
  constructor(props) {
    super(props);

    this.state = {
      favorite: false,
    };
  }

  render() {

    let publicUrl = ''
    let imagealt = 'image'
    // let data = sectiondata.aboutus
    let data = { product_id: this.props.value ,user_id: cookie.load('user_id')};


    return <>
      <li style={{ cursor: "pointer" }}
        onClick={() => {
          this.setState({ favorite: !this.state.favorite },
            () => {
              {
                this.state.favorite == false ?
                 fetch(config.URL+"users/remove_favorite", {
                  method: 'POST',
                  headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "Authorization": cookie.load('Tokken')
                  },
                  body: JSON.stringify(data)
                }).then((result) => {
                  console.log(result)
                })
                :
                 fetch(config.URL+"users/add_favorite", {
                  method: 'POST',
                  headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "Authorization": cookie.load('Tokken')
                  },
                  body: JSON.stringify(data)
                }).then((result) => {
                  console.log(result)
                })

              }

            }
          )
        }}>
        {this.state.favorite == false ?
          <a className="message" ><img style={{ width: "15px" }} src={Heart} alt="img" /></a>
          :
          <a className="message" ><img style={{ width: "15px" }} src={FilledHeart} alt="img" /></a>

        }

      </li>
    </>

  }
}

export default Favorite