import * as React from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import SearchListSection from './search-list';
import { Link } from 'react-router-dom';
import FavoriteBtn from './../components/section-components/favorite-btn';
import cookie from 'react-cookies';
import config from "../config";






function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}




export default function BasicTabs() {
  const [value, setValue] = React.useState(0);
  const [sale, setSale] = React.useState([]);
  const [rent, setRent] = React.useState([]);
  const [cRent, setCRent] = React.useState([]);
  const [cSale, setCSale] = React.useState([]);
  async function all_favorite() {

    let data = { user_id: cookie.load('user_id') };
    console.log("Data is ",data);
    await fetch(config.URL+"users/all_favorite", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Authorization": cookie.load('Tokken')
      },
      body: JSON.stringify(data)
    })
      .then((res) => res.json())
      .then((result) => {
        console.log("My Favorite Properties ",result);
        // setData(result)
        var favourite = result.user.favourite;
        var sale = favourite.filter((e, i) => {
          if (e.rent == false) {
            return e;
          }
        })
        var rent = favourite.filter((e, i) => {
          if (e.rent == true) {
            return e;
          }
        })
        var csale = favourite.filter((e, i) => {
          if (e.rent == false) {
            return e;
          }
        })
        var crent = favourite.filter((e, i) => {
          if (e.rent == true) {
            return e;
          }
        })
        setSale(sale)
        setRent(rent)
        setCRent(crent)
        setCSale(csale)

      })
  };
  useEffect(() => {
    all_favorite();


  }, []);

  let publicUrl = ''

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };





  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" >
          <Tab label={<b>Properties For Sale ({sale.length})</b>} {...a11yProps(0)} />
          <Tab label={<b>Properties For Rent ({rent.length})</b>} {...a11yProps(1)} />
          <Tab label={<b>Commercial for Sale ({cSale.length})</b>} {...a11yProps(2)} />
          <Tab label={<b>Commercial for Rent ({cRent.length})</b>} {...a11yProps(3)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        {sale.map((item, i) =>
          <div key={i} className="single-feature style-two" style={{ height: "255px" }}>
            <div className="thumb">
              <img src="https://images-cdn2.houza.com/images/properties/2021/12/06/AxJyEBFwb4_sKHh.jpg?profile=xs" alt="img" />
            </div>
            <div className="details bg-white" style={{ height: "255px" }}>
              <div className="details-wrap">
                <a href="" className="feature-logo">
                  <img src="https://images-cdn2.houza.com/images/properties/2021/12/06/AxJyEBFwb4_sKHh.jpg?profile=xs" alt="img" />
                </a>
                <p className="author"><i className="fa fa-user" /> {item.location}</p>
                <h6 className="title readeal-top"><Link to={{
                  pathname: `/property-details/${item._id}/${JSON.stringify('')}`,
                  state: item
                }}>{item.house_title}</Link></h6>
                <h6 className="price">AED {item.price}</h6>
                <ul className="info-list">
                  {/* {item.features.map((features, i) =>
                          <li key={i} ><i className={features.icon} /> {features.title}</li>
                        )} */}
                  {/* <li><img src={publicUrl + "/assets/img/icons/7.png"} alt={imagealt} /> {item.area}  </li> */}
                </ul>
                <ul className="contact-list bg-white">
                  <li><a className="phone" href=""><i className="fa fa-phone" /></a></li>
                  <li><a className="message" href=""><img src={publicUrl + "/assets/img/icons/8.png"} alt="img" /></a></li>
                  <FavoriteBtn value={item._id} />
                  <li className="readeal-top"><Link className="btn btn-yellow" to={{
                    pathname: `/property-details/${item._id}/${JSON.stringify('')}`,
                    state: item
                  }} >View Details</Link></li>
                </ul>
              </div>
            </div>
          </div>
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {rent.map((item, i) =>
          <div key={i} className="single-feature style-two" style={{ height: "255px" }}>
            <div className="thumb">
              <img src="https://images-cdn2.houza.com/images/properties/2021/12/06/AxJyEBFwb4_sKHh.jpg?profile=xs" alt="img" />
            </div>
            <div className="details bg-white" style={{ height: "255px" }}>
              <div className="details-wrap">
                <a href="" className="feature-logo">
                  <img src="https://images-cdn2.houza.com/images/properties/2021/12/06/AxJyEBFwb4_sKHh.jpg?profile=xs" alt="img" />
                </a>
                <p className="author"><i className="fa fa-user" /> {item.location}</p>
                <h6 className="title readeal-top"><Link to={{
                  pathname: `/property-details/${item._id}/${JSON.stringify('')}`,
                  state: item
                }}>{item.title}</Link></h6>
                <h6 className="price">AED {item.price}</h6><del> {item.olderprice}</del>
                <ul className="info-list">
                  {/* {item.features.map((features, i) =>
                          <li key={i} ><i className={features.icon} /> {features.title}</li>
                        )} */}
                  {/* <li><img src={publicUrl + "/assets/img/icons/7.png"} alt={imagealt} /> {item.area}  </li> */}
                </ul>
                <ul className="contact-list bg-white">
                  <li><a className="phone" href=""><i className="fa fa-phone" /></a></li>
                  <li><a className="message" href=""><img src={publicUrl + "/assets/img/icons/8.png"} alt="img" /></a></li>
                  <FavoriteBtn value={item._id} />
                  <li className="readeal-top"><Link className="btn btn-yellow" to={{
                    pathname: `/property-details/${item._id}/${JSON.stringify('')}`,
                    state: item
                  }} >View Details</Link></li>
                </ul>
              </div>
            </div>
          </div>
        )}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {cSale.map((item, i) =>
          <div key={i} className="single-feature style-two" style={{ height: "255px" }}>
            <div className="thumb">
              <img src="https://images-cdn2.houza.com/images/properties/2021/12/06/AxJyEBFwb4_sKHh.jpg?profile=xs" alt="img" />
            </div>
            <div className="details bg-white" style={{ height: "255px" }}>
              <div className="details-wrap">
                <a href="" className="feature-logo">
                  <img src="https://images-cdn2.houza.com/images/properties/2021/12/06/AxJyEBFwb4_sKHh.jpg?profile=xs" alt="img" />
                </a>
                <p className="author"><i className="fa fa-user" /> {item.location}</p>
                <h6 className="title readeal-top"><Link to={{
                  pathname: `/property-details/${item._id}/${JSON.stringify('')}`,
                  state: item
                }}>{item.title}</Link></h6>
                <h6 className="price">AED {item.price}</h6><del> {item.olderprice}</del>
                <ul className="info-list">
                  {/* {item.features.map((features, i) =>
                          <li key={i} ><i className={features.icon} /> {features.title}</li>
                        )} */}
                  {/* <li><img src={publicUrl + "/assets/img/icons/7.png"} alt={imagealt} /> {item.area}  </li> */}
                </ul>
                <ul className="contact-list bg-white">
                  <li><a className="phone" href=""><i className="fa fa-phone" /></a></li>
                  <li><a className="message" href=""><img src={publicUrl + "/assets/img/icons/8.png"} alt="img" /></a></li>
                  <FavoriteBtn value={item._id} />
                  <li className="readeal-top"><Link className="btn btn-yellow" to={{
                    pathname: `/property-details/${item._id}/${JSON.stringify('')}`,
                    state: item
                  }} >View Details</Link></li>
                </ul>
              </div>
            </div>
          </div>
        )}
      </TabPanel>
      <TabPanel value={value} index={3}>
        {cRent.map((item, i) =>
          <div key={i} className="single-feature style-two" style={{ height: "255px" }}>
            <div className="thumb">
              <img src="https://images-cdn2.houza.com/images/properties/2021/12/06/AxJyEBFwb4_sKHh.jpg?profile=xs" alt="img" />
            </div>
            <div className="details bg-white" style={{ height: "255px" }}>
              <div className="details-wrap">
                <a href="" className="feature-logo">
                  <img src="https://images-cdn2.houza.com/images/properties/2021/12/06/AxJyEBFwb4_sKHh.jpg?profile=xs" alt="img" />
                </a>
                <p className="author"><i className="fa fa-user" /> {item.location}</p>
                <h6 className="title readeal-top"><Link to={{
                  pathname: `/property-details/${item._id}/${JSON.stringify('')}`,
                  state: item
                }}>{item.title}</Link></h6>
                <h6 className="price">AED {item.price}</h6><del> {item.olderprice}</del>
                <ul className="info-list">
                  {/* {item.features.map((features, i) =>
                          <li key={i} ><i className={features.icon} /> {features.title}</li>
                        )} */}
                  {/* <li><img src={publicUrl + "/assets/img/icons/7.png"} alt={imagealt} /> {item.area}  </li> */}
                </ul>
                <ul className="contact-list bg-white">
                  <li><a className="phone" href=""><i className="fa fa-phone" /></a></li>
                  <li><a className="message" href=""><img src={publicUrl + "/assets/img/icons/8.png"} alt="img" /></a></li>
                  <FavoriteBtn value={item._id} />
                  <li className="readeal-top"><Link className="btn btn-yellow" to={{
                    pathname: `/property-details/${item._id}/${JSON.stringify('')}`,
                    state: item
                  }} >View Details</Link></li>
                </ul>
              </div>
            </div>
          </div>
        )}
      </TabPanel>
    </Box>
  );
}
