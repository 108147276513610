import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';
import cookie from 'react-cookies';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import config from "../../config";



class Delete extends Component {
  constructor(props) {
    super(props);

    this.state = {
      favorite: false,
      sale: [],
      rent:[],
    };
  }
  deleteFunction = () => {
    const data = { product_id: this.props.value ,user_id: cookie.load('user_id')};
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#fda94f',
      cancelButtonColor: '#0e4b46',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
         fetch(config.URL+"product/deleteProperty", {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': cookie.load('Tokken'),
            },
            body: JSON.stringify(data)
          }).then((result) => {
            console.warn(result);
            // this.all_properties();
            this.Alert("success", "Deleted", "Property Deleted Successfully")
            
            
          })
        
      }
    })          
  }

  Alert = (icon, title, msg) => {
    Swal.fire({
      position: 'center',
      icon: icon,
      title: title,
      text: msg,
      showConfirmButton: false,
      timer: 3000,
      width: "500px",
      timerProgressBar: true,

    });
    setTimeout(() => {
      window.location.href = "/account"
    }, 4000);
  };

  all_properties = () => {

    let data = { user_id: cookie.load('user_id') };

     fetch(config.URL+"users/my_properties", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization':cookie.load('Tokken'),
      },
      body: JSON.stringify(data)
    })
      .then((res) => res.json())
      .then((result) => {
        // setData(result)
        var sale = result.filter((e, i) => {
          if (e.rent == false) {
            return e;
          }
        })
        var rent = result.filter((e, i) => {
          if (e.rent == true) {
            return e;
          }
        })
        this.setState({sale : sale})
        this.setState({rent:rent})
        

        console.log('333')
      })
  };

  render() {

    let publicUrl = ''
    let imagealt = 'image'


    return <>
      <li style={{ cursor: "pointer" }}
        onClick={() => {
         this.deleteFunction()
        }}>
        <a className="message" ><i className="fa fa-trash" /></a>
      </li>
    </>

  }
}

export default Delete