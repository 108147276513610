import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import AccountTab from './account_tab';
import Footer from './global-components/footer';
import Cookies from "react-cookies";
import ProfileHelper from './../Helpers/ProfileHelper';
import banner from "./../assets/img/account.png"
import Fade from 'react-reveal/Fade';




const Account = () => {
    const [name, setName] = React.useState("");
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [role, setRole] = React.useState(Cookies.load("role"));
    const user_name = Cookies.load('title');
    React.useEffect(() => {
        setLoading(true)
        ProfileHelper.GetById(Cookies.load('user_id')).then((res)=>{
            if (Cookies.load('role')=="Admin") {
                setName(res.data.data.user.userName);       
            } else {
                setName(res.data.data.user.title); 
            }
            setData(res.data.data.user);
            setLoading(false);
            setTimeout(() => {
                console.warn(loading);
            }, 10000);

        });
        
    }, []);

    return <div>
        <Navbar />
        <PageHeader headertitle="Account" video={banner}/>
        <div className="container" style={{transition: "10s"}}>
            <div className="d-flex justify-content-center mb-5 mt-4">
                <div>
                    <h4>Hi {user_name}</h4>
                    <h5>Welcome to Mulqiat</h5>
                    
                </div>
            </div>
            {
            role == "agency"?
            data.status == "active"?
                null
            :
            data.status == "pending"?
                <div class="alert alert-info">
                    Your account is currently under review while a member of <strong>Mulqiat Team</strong> reviews it.This usually takes less then 48 hours.We'll be in touch soon!
                </div>
            :
            loading == false?
                <div class="alert alert-danger">
                    Your agency is <strong>{data.status}</strong> for some reason.Contact<strong> Mulqiat Support</strong> for more Info.
                </div>
            :
            null
            :
            null
                    }
            {loading == false?(
                <Fade bottom>
                <AccountTab data={data}/>
                </Fade>
            ):<h1>Loading... </h1>}
            
        </div>
        <Footer />
    </div>
}

export default Account

