import React, { Component } from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import PropertyDetailsSection from './section-components/property-details';
import RecomandProperties from './section-components/recomand-properties';
import Footer from './global-components/footer';
import ProductHelper from "../Helpers/ProductHelper";

class PropertyDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: { min: 2, max: 10 },
            data: [],
            flag: false,
            recommended: [],
            recommendedFlag: false
        };

        ProductHelper.GetById(this.props.match.params.id).then((resp) => {
            this.setState({ data: resp.data.data.product }, function () {
                this.setState({ flag: true })

            })
        }).catch((e) => {
            alert(e.msg)
        })

        if(!this.props.match.params.filter){

            ProductHelper.RecommendedFilterProduct(JSON.parse(this.props.match.params.filter)).then((resp) => {
                this.setState({ recommended: resp.data.data.products }, function () {
                    this.setState({ recommendedFlag: true })
                });
            }).catch((e) => {
                alert(e.msg)
            })
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.match.params.id != this.props.match.params.id) {

            ProductHelper.GetById(nextProps.match.params.id).then((resp) => {
                this.setState({ data: resp.data.data.product }, function () {
                    this.setState({ flag: true })
                    window.scrollTo(0, 0)
                    ProductHelper.RecommendedFilterProduct(JSON.parse(nextProps.match.params.filter)).then((resp) => {
                        this.setState({ recommended: resp.data.data.products }, function () {
                            this.setState({ recommendedFlag: true })
                        });
                    }).catch((e) => {
                        alert(e.msg)
                    })
                })
            }).catch((e) => {
                alert(e.msg)
            })




        }
        return true;
    }

    render() {
        return <div>
            <Navbar />
            <PageHeader headertitle="Property Details" />
            {
                this.state.flag == true ? (
                    <PropertyDetailsSection item={this.state.data} />
                ) : null
            }
            {
                this.state.recommendedFlag == true ? (
                    <RecomandProperties data={this.state.recommended} filter={this.props.match.params.filter} />
                ) : null
            }

            <Footer />
        </div>
    }
}

export default PropertyDetails

