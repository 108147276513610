import React from 'react';
import Skeleton from 'react-skeleton-loader';
const skeleton =() => {
    return (
        <div className="col-xl-4 col-sm-6 col-12">
            <div className="single-feature">
                <Skeleton height="100px" width='150%' />
                <div className="details">
                    <a className="feature-logo">
                        <Skeleton height="50px" />
                    </a>

                    <ul className="info-list">
                        <li ><Skeleton count={3} /></li>
                        <li><Skeleton height="50px" /></li>
                    </ul>

                </div>

            </div>
        </div>
    )
}

export default skeleton
