import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import HubComponent from './section-components/hub-component';
import FaqComponent from './section-components/faq-component';
import Footer from './global-components/footer';
import SideNav from './section-components/sidenav_component';
import banner from "./../assets/img/welcomebanner2.png"
    
const hub = () => {
    return <div>
        <img src={banner} width="100%" style={{position:'relative',top:"0px"}}/>
        <Navbar />
        <HubComponent />
        <FaqComponent />
        
        <Footer />
    </div>
}

export default hub

