import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import main from "./../../video/main.mp4";


class Page_header extends Component {
  constructor(props) {
    super(props);
  }
  render() {

    let HeaderTitle = this.props.headertitle;
    let publicUrl = ''

    return (
      <div className='position-relative'>
        <img src={this.props.video} style={{ height: "300px", width: "100%", objectFit: "cover", objectPosition: this.props.positon }} />
        <div id="c-overlay">
          <h5 id="text" style={{ textAlign: "center", position: "relative", bottom: "150px", color: "#fff", fontSize: "30px", fontWeight: "bold" }}>{HeaderTitle}</h5>
        </div>
      </div>
    )
  }
}


export default Page_header