import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, HashRouter, Route, Switch } from "react-router-dom";
import blogdata from './data/blogdata.json';
import Singleblogdata from './data/single-blogdata.json';
import HomeV1 from './components/home-v1';
import HomeV2 from './components/home-v2';
import HomeV3 from './components/home-v3';
import HomeV4 from './components/home-v4';
import Property from './components/property';
import AvilableProperty from './components/availavbe-property';
import PropertiesByCity from './components/properties-by-city';
import RecentProperties from './components/recent-properties';
import PropertyDetails from './components/property-details';
import PropertyDetailsNew from './components/propertyDetails';
import About from './components/about';
import Advisor from './components/advisor';
import Pricing from './components/pricing';
import UserList from './components/user-list';
import Registraion_old from './components/registration_old';
import Error from './components/error';
import Faq from './components/faq';
import News from './components/news';
import NewsDetails from './components/news-details';
import Contact from './components/contact';
import SearchMap from './components/search-map';
import Rent from './components/Rent';
import Buy from './components/Buy';
import commercialRent from './components/CommercialRent';
import commercialBuy from './components/CommercialBuy';
import SearchGrid from './components/search-grid';
import SearchList from './components/search-list';
import AddNewProperty from './components/add-property';
import AddNewAgency from './components/add-agency';
import policy from './components/policy';
import Account from './components/account';
import TAC from './components/terms_and_condition';
import WhatsappLead from './components/whatsappLead';
import AdvertiseWithUs from './components/advertisewithus';
import Hub from './components/hub';
import Registration from './components/registration';
import login from './components/login';
import loginAsAdmin from './components/loginAsAdmin';
import ViewAgencies from './components/ViewAgencies';
import ScrollToTop from './components/global-components/ScrollToTop';
import './index.css'
import forget from './components/forget';
// Agency 



class Root extends Component {
    render() {
        return (
            <Router>
                <ScrollToTop>
                    <Switch>
                        <Route exact path="/" component={HomeV1} />
                        {/* <Route path="/property-details/:id/:filter" component={PropertyDetails} /> */}
                        <Route path="/propertydetails" component={PropertyDetailsNew} />
                        <Route path="/about" component={About} />
                        <Route path="/agencies" component={UserList} />
                        <Route path="/registration_old" component={Registraion_old} />
                        <Route path="/registration" component={Registration} />
                        <Route path="/login" component={login} />
                        <Route path="/forget" component={forget} />
                        <Route path="/Admin" component={loginAsAdmin} />
                        <Route path="/ViewAgencies" component={ViewAgencies} />
                        <Route path="/error" component={Error} />
                        <Route path="/faq" component={Faq} />
                        <Route path="/news" component={News} />
                        <Route path="/news-details" component={NewsDetails} />
                        <Route path="/contact" component={Contact} />
                        <Route path="/search-map" component={SearchMap} />
                        <Route path="/properties" component={SearchGrid} />
                        <Route path="/buy" component={Buy} />
                        <Route path="/rent" component={Rent} />
                        <Route path="/commercial-buy" component={commercialBuy} />
                        <Route path="/commercial-rent" component={commercialRent} />
                        <Route path="/properties-list" component={SearchList} />
                        <Route path="/add-property" component={AddNewProperty} />
                        <Route path="/add-agency" component={AddNewAgency} />
                        <Route path="/policy" component={policy} />
                        <Route path="/account" component={Account} />
                        <Route path="/terms-and-conditions" component={TAC} />
                        <Route path="/whatsapp-lead-component" component={WhatsappLead} />
                        <Route path="/advertise-with-us" component={AdvertiseWithUs} />
                        <Route path="/hub" component={Hub} />
                    </Switch>
                </ScrollToTop>
            </Router>
        )
    }
}

export default Root;

ReactDOM.render(<Root />, document.getElementById('realdeal'));
