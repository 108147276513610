import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import LoginComponent from './section-components/login_component';
import Footer from './global-components/footer';

const login = () => {
    return <div>
        <Navbar />
        {/* <PageHeader headertitle="Add Property" /> */}
        <LoginComponent />

        
        <Footer />
    </div>
}

export default login

