import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';
import { TextField, Checkbox } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import main from "./../../video/main.mp4";
import style from "../../assets/css/style.css";
import AuthHelper from '../../Helpers/AuthHelper';
import Button from '@mui/material/Button';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import cookie from 'react-cookies';
import { Link } from 'react-router-dom';




const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#fda94f',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#fda94f',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#e5e5e9',
    },
    '&:hover fieldset': {
      borderColor: '#fda94f',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#fda94f',
    },
  },
});

class login_component extends Component {

  constructor(props) {
    super(props);

    this.state = {
      agency: false,
      email_login: "",
      password_login: "",
      messages: '',
      loading:false

    };
  }

 
  handleLogin = () => {
    this.setState({loading:true});
    var data = {
      email: this.state.email_login,
      password: this.state.password_login,
    };
    AuthHelper.Login(data).then((resp) => {
      if (resp.data.status != "success") {
        this.setState({ messages: resp.data.status });
        this.alert("error",resp.data.msg);

      }else {
        cookie.save('Tokken', resp.data.data.tokken , { path: '/' })
        cookie.save('user_id', resp.data.data.user_id , { path: '/' })
        cookie.save('title', resp.data.data.user_name , { path: '/' })  
        cookie.save('role', "user" , { path: '/' })  
        window.location.href="/"    

        
      }
    });
    
  };
  handleLoginAsAgency = () => {
    this.setState({loading:true});
    var data = {
      email: this.state.email_login,
      password: this.state.password_login,
    };
    AuthHelper.LoginAsAgency(data).then((resp) => {
      if (resp.data.status != "success") {
        this.setState({ messages: resp.data.status });
        this.alert("error",resp.data.msg);

      }else {
        cookie.save('Tokken', resp.data.data.tokken , { path: '/' })
        cookie.save('user_id', resp.data.data.user_id , { path: '/' })
        cookie.save('title', resp.data.data.user_name , { path: '/' })   
        cookie.save('role', "agency" , { path: '/' })  
        window.location.href="/"    
      }
    });
    
  };

  alert = (icon,msg) =>{
    Swal.fire({
      position: 'center',
      padding:"500px , 0px",
      icon: icon,
      title: msg,
      showConfirmButton: false,
      timer: 3000,
      width:"500px",
      timerProgressBar:true,
      
    });
    this.setState({loading:false})
  };

  validation = () => {
    console.log("In validation");
    console.log(this.state.email_login);
    this.state.email_login == ""?
      this.alert("error","Email can't be empty")
    :
    this.isValidEmail(this.state.email_login) == false?
      this.alert("error","Please enter a valid Email")
    :
    this.state.password_login == ""?
      this.alert("error","Password can't be empty")
    :
    this.state.agency == false?
      this.handleLogin()
    :
      this.handleLoginAsAgency()

    

  }

  isValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    return emailRegex.test(email);
  };
 


  render() {

    let publicUrl = '' + '/'
    let imagealt = 'image'
    let data = sectiondata.aboutus
    const Input = styled('input')({
      display: 'none',
    });


    
    return <div className='log_background_image'>
      <div className="col-xl-4 col-lg-5 col-md-6" style={{ marginLeft: "auto", paddingTop: "100px" }}>
        <div className="contact-form-wrap " style={{marginTop:"150px",marginBottom:'150px'}}>
        <div className="row ">
            <div className="col-lg-4 col-12"><h4>SignIn As</h4></div>
            <div className="col-lg-3 col-5 mb-3">
              <a className={`btn ${this.state.agency == false ? "btn-yellow" : "btn-yellow-outline"} float-right text-white ml-2`} onClick={() => this.setState({ agency: false })}>User</a>
            </div>
            <div className="col-lg-3 col-5 mb-3">
              <a className={`btn ${this.state.agency == true ? "btn-yellow" : "btn-yellow-outline"} float-right text-white`} onClick={() => this.setState({ agency: true })}>Agency</a>
            </div>
          </div>


            <div className="col-md-12">
              <div className="section-title">
                <CssTextField fullWidth label="Email" id="custom-css-outlined-input" onChange={(e) => {
                  this.setState({ email_login: e.target.value }, console.log(e.target.value))
                }} />
                <CssTextField fullWidth type="password" label="Password" id="custom-css-outlined-input" margin="dense" onChange={(e) => {
                  this.setState({ password_login: e.target.value }, console.log(e.target.value))
                }} />
                <div style={{textAlign:"end"}}><Link to="/forget">Forget Password</Link></div>

              </div>
            </div>
            <div>
              <div className="btn-wrap " style={{textAlign:"center"}}>
                {this.state.agency == false ?
                  this.state.loading?
                    <button  className="btn btn-yellow" disabled>
                        <i class="fa fa-refresh fa-spin"></i> Loading...
                    </button>
                  :
                    <button style={{width:"330px",margin:'0px'}} onClick={() => this.validation()} className="btn btn-yellow">Sign In</button>
                  
                  :
                  this.state.loading?
                    <button  className="btn btn-yellow" disabled>
                      <i class="fa fa-refresh fa-spin"></i> Loading...
                    </button>
                :
                    <button style={{width:"330px",margin:'0px'}} onClick={() => this.validation()} className="btn btn-yellow">Sign In As Agency</button>

                }
                <div style={{textAlign:"center"}}><Link to="/registration" style={{fontSize:"17px"}}>Don't Have An Account?</Link></div>

              </div>
            </div>
          
        </div>
      </div>
    </div>

  }
}

export default login_component