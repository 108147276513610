import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FavouriteTab from './account_favourite_propertys_tab';
import MyProperties from './my_properties';
import AllAgencyes from './allAgencyes';
import cookie from 'react-cookies';
import "../assets/css/style.css";
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import { alpha, styled } from '@mui/material/styles';
import config from "../config"
import Swal from 'sweetalert2';
import UploadMedia from "../Helpers/MediaHelper";
import main from "./../assets/img/contact.png"
 






function TabPanel(props) {
    const { children, value, index, ...other } = props;
    const Input = styled('input')({
        display: 'none',
    });

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Box>{children}</Box>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export default function VerticalTabs(props) {
    const [value, setValue] = React.useState(0);
    const [editPass, setEditPass] = React.useState(false);
    const [pass, setPass] = React.useState("");
    const [name, setName] = React.useState("");
    const [phone, setPhone] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [imgPath, setimgPath] = React.useState("https://mulqiat.herokuapp.com/img/users/");
    const [img, setimg] = React.useState("user-undefined-1647204525498.jpg");
    const [role, setRole] = React.useState(cookie.load("role"));
    const [message, setMessage] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [base64file, setBase64file] = React.useState(props.data.img)

    React.useEffect(() => {
        setName(
            role == "user" ? props.data.name : role == "agency" ? props.data.title : props.data.userName
        )
        setEmail(props.data.email)
        setPhone(props.data.phone)
    }, []);


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const updateProfile = () => {
        setLoading(true)
        const data = { password: pass, name: name, phone: phone, email: email, img: imgPath }
        var path = (role == "user" ? "users/update" : role == "agency" ? "users/agency/update" : "users/admin/update");
        fetch(config.URL + path, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': cookie.load('Tokken'),
            },
            body: JSON.stringify(data)
        }).then((result) => {
            // window.location.href = "/account"

            if (result.status == 200) {
                Alert("success", "Updated", "Profile Updated Successfully");
                setLoading(false)
            } else {
                Alert("error", "Oops...", "Something went wrong!");
                setLoading(false)


            }

        })
    }

    const Alert = (icon, title, msg) => {
        Swal.fire({
            position: 'center',
            icon: icon,
            title: title,
            text: msg,
            showConfirmButton: false,
            timer: 3000,
            width: "500px",
            timerProgressBar: true,

        });
        setTimeout(() => {
            window.location.href = "/account"
        }, 4000);
    };


    return (
        <Box
            sx={{ flexGrow: 1, bgcolor: '#f7fafe', display: 'flex', padding: "20px", borderRadius: "5px", minHeight: '400px' }}
        >
            <Tabs
                orientation="vertical"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{ borderRight: 1, borderColor: 'divider' }}
            >
                <Tab label={<b>Profile</b>} {...a11yProps(0)} />
                {role == "user" ? <Tab label={<b>Favourites</b>} {...a11yProps(1)} /> : role == "agency" ? <Tab label={<b>Properties</b>} {...a11yProps(1)} /> : <Tab label={<b>All Agencies</b>} {...a11yProps(1)} />}
                {/* <Tab label={<b>Your Alerts</b>} {...a11yProps(2)} /> */}
                <Tab label={<b>Sign out</b>} {...a11yProps(3)} onClick={() => {
                    cookie.remove('user_id')
                    cookie.remove('Tokken')
                    cookie.remove('title')
                    cookie.remove('role')
                    window.location.href = "/"
                }} />

            </Tabs>
            <TabPanel value={value} index={0} style={{ width: "90%" }}>
                <div className='px-3'>
                    <h5 className='mb-2'>Edit your profile</h5>
                    <div className='row'>
                        <div className='col-md-6'>
                            <h6 className='mt-4'>Email:</h6>
                            <p>{email}</p>
                            <h6 className='mt-4'>Your full name:</h6>
                            <input className='form-control' value={name} onChange={(e) => setName(e.target.value)} />
                            <h6 className='mt-4'>Phone:</h6>
                            <input className='form-control' value={phone} onChange={(e) => setPhone(e.target.value)} />
                            <h6 className='mt-4'>Password:</h6>

                            {editPass ?
                                (<input value={pass} onChange={(e) => setPass(e.target.value)} className='form-control' placeholder='Enter new password here' style={{ width: "350px" }} />) :
                                (<p>*************  <span className='text-primary ml-3' onClick={() => {
                                    setEditPass(true)
                                }}>Edit</span></p>)}
                        </div>
                        {role == "agency" ?
                            <>
                                <div className='col-md-6 '>
                                    <div class="ProfileImgDiv mt-md-0 mt-5 ">
                                        <img src={base64file?base64file:main} alt={base64file} className='ProfileImage' width={"100px"} height={"100px"} />

                                        <div class="overlay">
                                            <label for={`profileLogo`}>
                                                Change profile Picture
                                            </label>
                                        </div>

                                    </div>
                                    <input type="file" onChange={(e) => {
                                        setLoading(true);
                                        var file = e.currentTarget.files[0];
                                        UploadMedia.UploadMedia(file).then((url) => {
                                            console.warn("Url--->:", url);
                                            setimgPath(url);
                                            setLoading(false);
                                        });
                                        var reader = new FileReader();
                                        reader.onloadend = function () {
                                            setBase64file(reader.result)
                                        }
                                        reader.readAsDataURL(file);
                                    }} id={`profileLogo`} className='v-hidden' name={`propertyImages`} />
                                </div>
                                <div className='col-md-6'>

                                </div>
                            </>
                            :
                            null
                        }

                        <div className='col-md-6'>
                            
                        </div>
                    </div>


                    <button className='btn btn-primary mt-4' style={{minWidth:180}} onClick={() => loading == false ? updateProfile() : null}>{!loading ? "Update your profile" : <i class="fa fa-refresh fa-spin"></i>}</button>
                </div>
            </TabPanel>

            {role == "user" ?
                <TabPanel value={value} index={1}>
                    <FavouriteTab />
                </TabPanel>
                :
                role == "agency" ? (
                    <TabPanel value={value} index={1}>
                        <MyProperties status={props.data.status} />
                    </TabPanel>
                ) :
                    <TabPanel value={value} index={1}>
                        <AllAgencyes />
                    </TabPanel>

            }

            <TabPanel value={value} index={2}>
                <div>
                    <div style={{display:"flex" ,alignItems:"center"}}>
                        <i className="fa fa-refresh fa-spin mr-2"></i>
                        <br/>
                        <h4>Signing out ...</h4>
                    </div>
                </div>
            </TabPanel>

        </Box>
    );
}
