import config from "../config";
const Upload = async (data) => {
  return new Promise((resolve, reject) => {
    console.log("qwerqwr",data);
    var path = "media/upload";
    var type = "post";

    var myHeaders = new Headers();
    var formdata = new FormData();
    // for (let i = 0; i < data.length; i++) {
    //   formdata.append(
    //     "image",
    //     data[i],
    //     data[i].name
    //   );
    // }

    formdata.append(
      "image",
      data,
      data.name
    );
    
    console.log('formdata:', formdata)

    var requestOptions = {
      method: type,
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(config.URL + path, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        resolve({
          status: true,
          data: result,
        });
      })
      .catch((error) => console.log("error", error));
  });
};
export default { Upload };