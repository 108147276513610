// import Cookies from "js-cookie";
import cookie from 'react-cookies'
import config from "../config";

const jwt = require('jsonwebtoken');


const GetById = async (id) => {
    return new Promise((resolve, reject) => {
        const role = cookie.load('role');
        var path = role == "user" ? (
            "profile/" + id
        ) :
         (
            role == "agency" ? 
            (
                "profile/agency/" + id
            ) :
                (
                    "profile/admin/" + id
                )
        );
        var type = 'GET'
        fetch(config.URL + path, {
            method: type,
            headers: { "Content-Type": "application/json", "Authorization": cookie.load('Tokken') },
        })
            .then((res) => res.json())
            .then(async (resjson) => {
                if (resjson.status == "success") {
                    resolve({
                        status: true,
                        data: resjson
                    });
                } else {
                    resolve({
                        status: false,
                        data: resjson
                    });
                }

            })
            .catch((err) => {
                console.log("failed", err);
            });
    });
};



export default {
    GetById
};
