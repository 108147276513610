import React from 'react';
import Navbar from './global-components/navbar';
import Banner from './section-components/banner';
import Service from './section-components/service';
import PropertiesByCities from './section-components/properties-by-cities';
import Footer from './global-components/footer';
import banner from "./../assets/img/bannerImg4.jpeg";

const Home_V1 = () => {

  
    return <div>
         {/* <video width="100%" height="800px" loop muted autoplay="true" style={{position:'absolute',top:"0px",objectFit:"cover"}}>
            <source src={main} type="video/mp4" />
          </video> */}
          <div></div>
          <img src={banner} width="100%" height="800px" style={{position:'absolute',top:"0px",objectFit:"cover"}}/>
        <Navbar />
        <Banner />
        <Service />
        <PropertiesByCities />
        <Footer />
    </div>
}

export default Home_V1

