import React,{useRef,useState} from 'react'

function PaperDropzone(props) {
    const inputFile = useRef(null);
    const [base64file, setBase64file] = useState(props.propertyImageURL[props.index])

    return (
        <div>
            {base64file!=undefined?<div className="cancelImage" onClick={()=>{
                props.cancelImageUrl(props.index)
                setBase64file(null);
            }}>×</div>:null}
            <label for={`upload${props.index}`}>
                <div className='ImageUploader' style={{backgroundImage:`url(${base64file})`,backgroundSize: "cover"}}>
                    
                   { !base64file?<div><h5 className='text-center'>Upload File Here {base64file}</h5></div>:<h5 className='text-center updateImageText'>Update Image</h5>}
                </div>
            </label>
            <input  type="file" onChange={(e)=>{
                var file = e.currentTarget.files[0];
                props.getImage(file);
                var reader = new FileReader();
                reader.onloadend = function() {
                    setBase64file(reader.result)
                  console.log('RESULT', reader.result)
                }
                reader.readAsDataURL(file);
            }} id={`upload${props.index}`} className='v-hidden' name={`propertyImages${props.index}`}/>
        </div>
    )
}

export default PaperDropzone;