import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import cookie from 'react-cookies';




class Navbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      login: false,
    };
  }

  render() {
    let publicUrl = ''
    let imgattr = 'logo'
    let anchor = '#'
    let title = cookie.load('title')
    return (
      <div>
        <div className="navbar-area">
          <nav className="navbar navbar-area navbar-expand-lg">
            <div className="container nav-container">
              <div className="responsive-mobile-menu">
                <button className="menu toggle-btn d-block d-lg-none" data-toggle="collapse" data-target="#realdeal_main_menu" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="icon-left" />
                  <span className="icon-right" />
                </button>
              </div>
              <div className="logo readeal-top readeal-top-logo">
                <Link to="/"><img width="190px" src={publicUrl + "/assets/img/logo-w.png"} alt="logo" /></Link>
              </div>
             
              
              
              <div className="collapse navbar-collapse" id="realdeal_main_menu">
                <ul className="navbar-nav menu-open readeal-top">
                
                {window.screen.width<=991?
                  title?
                  <li className="_menu-item">
                    <Link to="/account" className={`${window.screen.width<=991?"":"text-white"}`}>Account</Link>
                  </li>
                  :
                  <li className="_menu-item">
                    <Link to="/login" className={`${window.screen.width<=991?"":"text-white"}`}>SIGN IN</Link>
                  </li>                  
                  :
                  null
                  }

                  <li className="_menu-item">
                    <Link  to={{ pathname: "/buy", state: { rent:false } }} className={`${window.screen.width<=991?"":"text-white"}`}>Buy</Link>
                  </li>
                  <li className="_menu-item">
                    <Link to={{ pathname: "/rent", state: { rent:true} }} className={`${window.screen.width<=991?"":"text-white"}`}>Rent</Link>
                  </li>
                  <li className="_menu-item menu-item-has-children" style={{cursor:"pointer"}}>
                    <a href="" className={`${window.screen.width<=991?"":"text-white"}`}>Commercial</a>
                    <ul className="sub-menu">
                      <li><Link to={{ pathname: "/commercial-buy", state: { rent: false ,commercial:true, } }} className={`${window.screen.width<=991?"":"text-white"}`}>Commercial Sale</Link></li>
                      <li><Link to={{ pathname: "/commercial-rent", state: { rent: true ,commercial:true, } }} className={`${window.screen.width<=991?"":"text-white"}`}>Commercial Rent</Link></li>

                    </ul>
                  </li>
                  {/* <li className="_menu-item">
                    <Link to="/" className={`${window.screen.width<=991?"":"text-white"}`}>Short Term</Link>
                  </li> */}
                  <li className="_menu-item">
                    <Link to="/agencies" className={`${window.screen.width<=991?"":"text-white"}`}>Find Agencies</Link>
                  </li>
                  <li className="_menu-item">
                    <Link to="/contact" className={`${window.screen.width<=991?"":"text-white"}`}>Contact Us</Link>
                  </li>
                  {window.screen.width<=991?
                  title?
                  <>
                  
                  <li className="_menu-item" onClick={()=>{
                        cookie.remove('user_id')
                        cookie.remove('Tokken')
                        cookie.remove('title')
                        cookie.remove('role')
                        window.location.href="/"
                      }}>
                        Sign Out
                  </li>
                  </>
                  :
                  null
                  :
                  null
                  }
                </ul>
              </div>
              {title ?
                <div className="nav-right-part nav-right-part-desktop readeal-top collapse navbar-collapse">
                  <div className="dropdown">
                    <button className="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown" style={{backgroundColor:"#fda94f", boxShadow:"none"}}>Welcome {title.length<10?title:title.slice(0,10)+"..."}
                      <span className="caret"></span></button>
                    <ul className="dropdown-menu" style={{backgroundColor:"#fda94f9e",color:"white",border:"none",textAlign:"center",left:"auto",right:"0px"}}>
                      <li><Link to="/account">Account</Link></li>
                      <li className='pointer' onClick={()=>{
                        cookie.remove('user_id')
                        cookie.remove('Tokken')
                        cookie.remove('title')
                        cookie.remove('role')
                        window.location.href="/"
                      }}>Sign Out</li>
                    </ul>
                  </div>
                </div>
                :
                <div className="nav-right-part nav-right-part-desktop readeal-top">
                  <Link className="btn btn-yellow" to="/login"><i className="fa fa-user"></i> SIGN IN <span className="right"></span></Link>
                </div>


              }
            </div>
          </nav>
        </div>
      </div>
    )
  }
}


export default Navbar