import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';
import config from "../../config";
import "../../assets/css/style.css"
import { Link } from 'react-router-dom';



class UserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],      
      allActiveAgencies: [],    
      loading:true,
    };
    this.agencyInformation();

    
  }

  agencyInformation = () => {
    this.setState({loading:true});

    const data = { product_id: this.props.value };

         fetch(config.URL+"users/agencyInformation", {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
    }).then((res) => res.json())
    .then((result) => {
      var allAgencies = result.agency;
      var activeAgency = result.agency;
       activeAgency = allAgencies.filter((e, i) => {
        if (e.status == "active") {
          return e;
        }
      })
      this.setState({allActiveAgencies:activeAgency});
      this.setState({loading:false});
      console.log(activeAgency);
      console.log(this.state.allActiveAgencies);
    })
  }  

    render() {

        let imagealt = 'image'


    return <div className="user-list-area">
        
          <div className="container">
          {this.state.loading == true?
            <div style={{paddingTop:30}}>
              <h1 style={{fontWeight:"bold",color:"rgb(253 170 79)"}}>
                Loading...
              </h1>
            </div>
          :
          
            <div className="row">
              { this.state.allActiveAgencies.map( ( item, i )=>
                <div key={ i } className="col-lg-4 col-md-6">
                  <div className="single-user-list text-center">
                  <img width="150px" src={ item.img } alt={ imagealt } />
                    <div className="details">
                      <h4 className="pt-4 allAgencies-agencyTitle"><Link to={{ pathname: "/ViewAgencies", state: {data:item} }} >{ item.title }</Link></h4>
                      <p style={{padding:"0px 50px"}}>{item.location}</p>
                      <span className="phone"><i className="fa fa-phone" />{ item.phone }</span>
                      <span className="fax"><i className="fa fa-fax" />{ item.phone }</span>
                      {/* <div className="social-list">
                         { item.social.map( ( socialitem, i )=>
                            <a key={ i } href={ socialitem.url }><i className={ socialitem.icon } /></a>
                          ) }
                      </div> */}
                    </div>
                  </div>
                </div>
               ) }
              
            </div>
          }
            </div>
          </div>
        }
}

export default UserList