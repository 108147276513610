import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import AdvertiseWithUs from './section-components/advertisewithus-component';
import Contactus from './section-components/contactus-form';
import Footer from './global-components/footer';
import banner from "./../assets/img/welcomebanner2.png"

const advertisewithus = () => {
    return <div>
        <img src={banner} width="100%" style={{position:'relative',top:"0px"}}/>
        <Navbar />
        <AdvertiseWithUs />
        {/* <Contactus /> */}

        
        <Footer />
    </div>
}

export default advertisewithus

