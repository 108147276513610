import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';
import { TextField, Checkbox } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import Banner from '../../assets/img/WhatsAppLeadTrackingBanner.jpg'

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#fda94f',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#fda94f',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#e5e5e9',
    },
    '&:hover fieldset': {
      borderColor: '#fda94f',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#fda94f',
    },
  },
});

class AddNew extends Component {

  constructor(props) {
    super(props);


  }




  render() {

    let publicUrl = '' + '/'
    let imagealt = 'image'
    let data = sectiondata.aboutus

    function saving_data() {
      alert("hi")
    }
    return <div className="add-new-property-area pd-top-90 mg-bottom-100">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-9 col-lg-10">
            <div className="section-title text-center">
              <h3>Whatsapp Lead Tracking</h3>
            </div>

            <div className="row">

              <div className="col-lg-12 mb-3 text-end">
                <p>
                  At mulqiat, we are committed to delivering you quality leads to bring further value to your business. While launching the mulqiat hub, we wanted to go beyond showing you clicks for leads, and show you real buyers/renters with names and numbers. Therefore, we have introduced WhatsApp tracking. With more of the region moving towards WhatsApp as a communication channel, we knew this was an important step to take.
                </p>
                <p>
                  As an agent, not much changes for you. You will still receive your WhatsApp leads, the moment the property seeker inquires. However, you'll start receiving your leads from the mulqiat WhatsApp business account to the mobile number linked to your property listings. All you need to do, is simply click on the link in the WhatsApp 👉[link] 👈 to message the client directly.
                </p>
                <p>
                  See example:
                </p>
                <p>
                  Hey John<br />
                  You’ve received a new lead for one of your properties on mulqiat.<br />
                  Price: AED 62,000<br />
                  Location: Murjan 2 • Murjan • Jumeirah Beach Residence • Dubai<br />
                  Ref number: LNP-8613<br />
                  What are you waiting for? Tap the link below to connect with them on WhatsApp.<br />
                  👉 https://rply.link/l/????? 👈<br />
                  Click here to view this property on mulqiat: https://mulqiat.com/propertylink<br />
                </p>
                <p>
                  As an agent, this only enhances your experience on the mulqiat hub. We now have the ability to automatically show all of your WhatsApp leads, with their names and numbers attached to you specific properties. Curious to have a look? Check it out on the mulqiat hub here: https://hub.mulqiat.com/login
                </p>
              </div>



            </div>

          </div>
        </div>
      </div>
    </div>

  }
}

export default AddNew