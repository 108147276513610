import React from 'react'
import SearchGrid from './search-grid'


export default function CommercialBuy(props) {
  return (
    <>
        <SearchGrid location={props.location}/>
    </>
  )
}
