import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';
import cookie from 'react-cookies';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import config from "../../config";



class DeclineBtn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      favorite: false,
      sale: [],
      rent: [],
    };
  }
  decline = () => {
    const data = { product_id: this.props.data._id, status: "declined" };
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#fda94f',
      cancelButtonColor: '#0e4b46',
      confirmButtonText: 'Yes, Decline This Agency!',
    }).then((result) => {
      if (result.isConfirmed) {
         fetch(config.URL+"product/agencyStatusChange", {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': cookie.load('Tokken'),

          },
          body: JSON.stringify(data)
        }).then((result) => {
          Swal.fire(
            'Declined!',
            'Agency Declined Successfully',
            'success'
          ).then(() => {
            window.location.href = "/account"
          })
        })

      }
    })
  }

  render() {

    let publicUrl = ''
    let imagealt = 'image'


    return <>
      {this.props.data.status == "pending" ?
        <button style={{ borderBottomRightRadius: "25px", width: "50%" }}
          type="button" class="btn btn-yellow mt-2"
          onClick={() => { this.decline() }}>
          Decline Agency
        </button>
        :
        <button style={{ borderRadius: "25px", width: "100%" }}
          type="button" class="btn btn-yellow mt-2"
          onClick={() => { this.decline() }}>
          Decline Agency
        </button>
      }


    </>

  }
}

export default DeclineBtn