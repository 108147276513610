import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import LoginAsAdminComponent from './section-components/loginAsAdmin_component';
import Footer from './global-components/footer';

const loginAsAdmin = () => {
    return <div>
        {/* <Navbar /> */}
        <LoginAsAdminComponent />    
        <Footer />
    </div>
}

export default loginAsAdmin

