import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';
import { TextField, Checkbox } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import Banner from '../../assets/img/WhatsAppLeadTrackingBanner.jpg';
import "../../assets/css/agenciescards.css"
import { Link } from 'react-router-dom';
import config from '../../config'

class AllAgenciesComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      title: String(this.props.data.title),
    };
  }

  render() {
    
    return (
      <div class="flip-card">
        <div class="flip-card-inner">
          <div class="flip-card-front">
          <img className='cardImage' src={this.props.data.img} alt={this.props.data.img} />
          </div>
          <div class="flip-card-back pt-4 ">
            <div className=''>
            <h5 style={{color:"white"}}>{this.state.title.length<40?this.state.title: this.state.title.slice(0,40)+"..."}</h5>
              <p style={{color:"white"}}>{this.props.sale} Properties for sale</p>
              <p style={{color:"white"}}>{this.props.rent} Properties for rent</p>
            </div>
            <Link style={{width:"300px",margin:'0px'}} className="btn btn-yellow-outline" to={{ pathname: "/ViewAgencies", state: {data:this.props.data} }}>View Agency</Link>
          </div>
        </div>
      </div>
    )

  }
}

export default AllAgenciesComponent