import React, { Component } from "react";
import sectiondata from "../../data/sections.json";
import parse from "html-react-parser";
import { TextField, Checkbox } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import InputAdornment from "@mui/material/InputAdornment";
import cookie from "react-cookies";
import config from "../../config";
import algoliasearch from "algoliasearch";
import Dropzone from "./dropzone";
import Swal from "sweetalert2";
import Fade from "react-reveal/Fade";
import { error } from "jquery";

// const client = algoliasearch('14KGKZU3TO', '6474cd7bd67e366edd2958bdcfdecf38');
// const index = client.initIndex('Location');
const client = algoliasearch("14KGKZU3TO", "6474cd7bd67e366edd2958bdcfdecf38");
const index = client.initIndex("Location");

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#fda94f",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#fda94f",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#e5e5e9",
    },
    "&:hover fieldset": {
      borderColor: "#fda94f",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#fda94f",
    },
  },
});

class AddNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product_id: this.props.update == "true" ? this.props.data._id : "",
      rent: this.props.update == "true" ? this.props.data.rent : false,
      commercial:
        this.props.update == "true" ? this.props.data.commercial : false,
      property_Type:
        this.props.update == "true" ? this.props.data.property_Type : "",
      house_title:
        this.props.update == "true" ? this.props.data.house_title : "",
      short_description:
        this.props.update == "true" ? this.props.data.short_description : "",
      explanation:
        this.props.update == "true" ? this.props.data.explanation : "",
      price: 
        this.props.update == "true" ? this.props.data.price : 0,
      bed_rooms: 
        this.props.update == "true" ? this.props.data.bed_rooms : 0,
      bathrooms: 
        this.props.update == "true" ? this.props.data.bathrooms : 0,
      area: 
        this.props.update == "true" ? this.props.data.area : 0,
      permit: 
        this.props.update == "true" ? this.props.data.permit : 0,
      rara: 
        this.props.update == "true" ? this.props.data.rara : 0,
      reference: 
        this.props.update == "true" ? this.props.data.reference : "",
      location: 
        this.props.update == "true" ? this.props.data.location : "",
      country: 
        this.props.update == "true" ? this.props.data.country : 0,
      state: 
        this.props.update == "true" ? this.props.data.state : 0,
      city: 
        this.props.update == "true" ? this.props.data.city : 0,
      zip_code: 
        this.props.update == "true" ? this.props.data.zip_code : 0,
      AC: 
        this.props.update == "true" ? this.props.data.AC : false,
      FrontYard:
        this.props.update == "true" ? this.props.data.FrontYard : false,
      Balcony: 
        this.props.update == "true" ? this.props.data.Balcony : false,
      Pets: 
        this.props.update == "true" ? this.props.data.Pets : false,
      Pool: 
        this.props.update == "true" ? this.props.data.Pool : false,
      SeaView: 
        this.props.update == "true" ? this.props.data.SeaView : false,
      status: 
        this.props.update == "true" ? this.props.data.status : "pending",
      propertyImage: [],
      propertyImageURL:
        this.props.update == "true"
          ? this.props.data.propertyImages
            ? this.props.data.propertyImages != ""
              ? JSON.parse(this.props.data.propertyImages)
              : []
            : []
          : [],
      agency_id: cookie.load("user_id"),
      loading: false,
    };
    console.log("propertyImagessssss", this.state.propertyImageURL);
  }

  saving_data = () => {
    console.warn(this.state.propertyImage);
    const data = this.state;
    fetch(config.URL + "product/add_property", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: cookie.load("Tokken"),
      },
      body: JSON.stringify(data),
    }).then((result) => {
      if (result.status != 500) {
        console.log("add_property result", result);
        const objects = [
          {
            location: this.state.location,
          },
        ];
        index
          .saveObjects(objects, {
            autoGenerateObjectIDIfNotExist: true,
          })
          .then((hints) => {
            console.log("Alog:", hints);
            this.Alert("success", "Added", "Property Added Successfully");
            // window.location.href = "/account"
          });
      } else {
        this.Alert("error", "Oops", "Something went wrong");
        this.setState({ loading: true });
      }
    });
  };
  updating_data = () => {
    this.setState({ loading: true });
    console.warn("propertyImage:", this.state.propertyImage);
    const data = this.state;
    fetch(config.URL + "product/updateProperty", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: cookie.load("Tokken"),
      },
      body: JSON.stringify(data),
    }).then((result) => {
      this.setState({ loading: false });
      console.log("add_property result", result);
      window.location.href = "/account";
    });
  };
  uploadMedia = async () => {
    this.setState({ loading: true });
    console.warn(this.state.propertyImage);
    var x = 0;
    if (this.state.propertyImage.length > 0) {
      this.state.propertyImage.forEach((e, i) => {
        this.Upload(e).then(() => {
          x++;
          if (x == this.state.propertyImage.length) {
            this.props.update == "true"
              ? this.updating_data()
              : this.saving_data();
          }
          console.warn(
            "x value:",
            x,
            ", length:",
            this.state.propertyImage.length
          );
        });
      });
    } else {
      this.props.update == "true" ? this.uploadMedia() : this.saving_data();
    }
  };

  Upload = async (data) => {
    return new Promise((resolve, reject) => {
      var path = "product/upload-property-images";
      var type = "post";

      var myHeaders = new Headers();
      myHeaders.append("Authorization", cookie.load("Tokken"));

      var formdata = new FormData();
      formdata.append(
        "media",
        data,
        "WhatsApp Video 2021-05-19 at 12.54.25 AM.jpg"
      );

      var requestOptions = {
        method: type,
        headers: myHeaders,
        body: formdata,
      };

      fetch(config.URL + path, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          this.setState(
            { propertyImageURL: [...this.state.propertyImageURL, result.data] },
            () => {
              console.warn(this.state.propertyImageURL);
              resolve(true);
            }
          );
        })
        .catch((error) => {
          console.log("error", error);
          reject(false);
        });
    });
  };

  Alert = (icon, title, msg) => {
    Swal.fire({
      position: "center",
      icon: icon,
      title: title,
      text: msg,
      showConfirmButton: false,
      timer: 3000,
      width: "500px",
      timerProgressBar: true,
    });
    if (icon === "success") {
      setTimeout(() => {
        window.location.href = "/account";
      }, 4000);
    } else {
    }
  };

  alertError = (icon,msg) =>{
    Swal.fire({
      position: 'center',
      padding:"500px , 0px",
      icon: icon,
      title: msg,
      showConfirmButton: false,
      timer: 3000,
      width:"500px",
      timerProgressBar:true,
      
    });
    this.setState({loading:false})
  };

  validation = () => {
    const DigitsRegex = /^\d+$/;

    if (this.state.propertyImage.length + this.state.propertyImageURL.length < 4) {
      
      this.alertError("error", "Please add a minimum of 4 property images")
    }
    else if (this.state.property_Type == "") {
      this.alertError("error", "Please select a Property Type.")
    }
    else if (this.state.house_title == "") {
      this.alertError("error", "Title can't be empty.")
    }
    else if (this.state.short_description.length < 20) {
      this.alertError("error", "Please add Short Description minimum of 20 letters.")
    }
    else if (this.state.explanation.length < 50) {
      this.alertError("error", "Please add Explanation minimum of 50 letters.")
    }
    else if (this.state.price == 0) {
      this.alertError("error", "Price can't be empty.")
    }
    else if (!DigitsRegex.test(this.state.price)) {
      this.alertError("error", "Price should consist of only numbers.")
    }
    else if (this.state.commercial == false && this.state.bed_rooms == 0) {
        this.alertError("error", "Please specify the number of Bedrooms.")
    }
    else if (this.state.commercial == false && this.state.bathrooms == 0) {
        this.alertError("error", "Please specify the number of Bathrooms.")
    }
    else if (this.state.area == 0) {
      this.alertError("error", "Please specify the Area of property.")
    }
    else if (!DigitsRegex.test(this.state.area)) {
      this.alertError("error", "Area should consist of only numbers.")
    }
    else if (this.state.permit == 0) {
      this.alertError("error", "Please specify the Permit of property.")
    }
    else if (!DigitsRegex.test(this.state.permit)) {
      this.alertError("error", "Permit should consist of only numbers.")
    }
    else if (this.state.rara == 0) {
      this.alertError("error", "Please specify the RERA of property.")
    }
    else if (!DigitsRegex.test(this.state.rara)) {
      this.alertError("error", "RERA should consist of only numbers.")
    }
    else if (this.state.reference == "") {
      this.alertError("error", "Please specify the Reference of property.")
    }
    else if (this.state.location == "") {
      this.alertError("error", "Please add the Location of property.")
    }
    else if (this.state.city == 0) {
      this.alertError("error", "Please specify the City.")
    }
    else {
      if (this.props.update == "true" && this.state.propertyImage.length == 0) {
        this.updating_data()
      } else {
        this.uploadMedia()
      }
      
    }

  };

  render() {
    let publicUrl = "" + "/";
    let imagealt = "image";
    let data = sectiondata.aboutus;

    return (
      <div className="add-new-property-area pd-top-90 mg-bottom-100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-9 col-lg-10">
              <div className="section-title text-center">
                {this.props.update == "true" ? (
                  <h3>Update Property </h3>
                ) : (
                  <h3>
                      Add New Property
                  </h3>
                )}
              </div>
              <div className="border-bottom mb-4">
                <div className="row">
                    <div className="col-md-4">
                      <div className="single-intro style-two text-center">
                        <div className="thumb">1</div>
                        <div className="details">
                          <h4 className="title">Choose Listing</h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="single-intro style-two text-center">
                        <div className="thumb">2</div>
                        <div className="details">
                          <h4 className="title">Add Information</h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="single-intro style-two text-center">
                        <div className="thumb">3</div>
                        <div className="details">
                          <h4 className="title">Publish</h4>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
              {/* <h5>{this.state.propertyImage.length}</h5> */}
              <div className="row">
                  <div className="col-sm-2">
                    <Dropzone
                      cancelImageUrl={(i) => {
                        console.warn(i);
                        var temparray = this.state.propertyImageURL.splice(
                          i,
                          1
                        );
                        this.setState({ propertyImageURL: temparray }, () => {
                          console.warn(this.state.propertyImageURL);
                        });
                      }}
                      propertyImageURL={this.state.propertyImageURL}
                      getImage={(e) =>
                        this.setState({
                          propertyImage: [...this.state.propertyImage, e],
                        })
                      }
                      index={0}
                    />
                  </div>
                  <div className="col-sm-2">
                    <Dropzone
                      cancelImageUrl={(i) => {
                        console.warn(i);
                        var temparray = this.state.propertyImageURL.splice(
                          i,
                          1
                        );
                        this.setState({ propertyImageURL: temparray }, () => {
                          console.warn(this.state.propertyImageURL);
                        });
                      }}
                      propertyImageURL={this.state.propertyImageURL}
                      getImage={(e) =>
                        this.setState({
                          propertyImage: [...this.state.propertyImage, e],
                        })
                      }
                      index={1}
                    />
                  </div>
                  <div className="col-sm-2">
                    <Dropzone
                      cancelImageUrl={(i) => {
                        console.warn(i);
                        var temparray = this.state.propertyImageURL.splice(
                          i,
                          1
                        );
                        this.setState({ propertyImageURL: temparray }, () => {
                          console.warn(this.state.propertyImageURL);
                        });
                      }}
                      propertyImageURL={this.state.propertyImageURL}
                      getImage={(e) =>
                        this.setState({
                          propertyImage: [...this.state.propertyImage, e],
                        })
                      }
                      index={2}
                    />
                  </div>
                  <div className="col-sm-2">
                    <Dropzone
                      cancelImageUrl={(i) => {
                        console.warn(i);
                        var temparray = this.state.propertyImageURL.splice(
                          i,
                          1
                        );
                        this.setState({ propertyImageURL: temparray }, () => {
                          console.warn(this.state.propertyImageURL);
                        });
                      }}
                      propertyImageURL={this.state.propertyImageURL}
                      getImage={(e) =>
                        this.setState({
                          propertyImage: [...this.state.propertyImage, e],
                        })
                      }
                      index={3}
                    />
                  </div>
                  <div className="col-sm-2">
                    <Dropzone
                      cancelImageUrl={(i) => {
                        console.warn(i);
                        var temparray = this.state.propertyImageURL.splice(
                          i,
                          1
                        );
                        this.setState({ propertyImageURL: temparray }, () => {
                          console.warn(this.state.propertyImageURL);
                        });
                      }}
                      propertyImageURL={this.state.propertyImageURL}
                      getImage={(e) =>
                        this.setState({
                          propertyImage: [...this.state.propertyImage, e],
                        })
                      }
                      index={4}
                    />
                  </div>
                  <div className="col-sm-2">
                    <Dropzone
                      cancelImageUrl={(i) => {
                        console.warn(i);
                        var temparray = this.state.propertyImageURL.splice(
                          i,
                          1
                        );
                        this.setState({ propertyImageURL: temparray }, () => {
                          console.warn(this.state.propertyImageURL);
                        });
                      }}
                      propertyImageURL={this.state.propertyImageURL}
                      getImage={(e) =>
                        this.setState({
                          propertyImage: [...this.state.propertyImage, e],
                        })
                      }
                      index={5}
                    />
                  </div>
                  <div className="col-sm-2">
                    <Dropzone
                      cancelImageUrl={(i) => {
                        console.warn(i);
                        var temparray = this.state.propertyImageURL.splice(
                          i,
                          1
                        );
                        this.setState({ propertyImageURL: temparray }, () => {
                          console.warn(this.state.propertyImageURL);
                        });
                      }}
                      propertyImageURL={this.state.propertyImageURL}
                      getImage={(e) =>
                        this.setState({
                          propertyImage: [...this.state.propertyImage, e],
                        })
                      }
                      index={6}
                    />
                  </div>
                  <div className="col-sm-2">
                    <Dropzone
                      cancelImageUrl={(i) => {
                        console.warn(i);
                        var temparray = this.state.propertyImageURL.splice(
                          i,
                          1
                        );
                        this.setState({ propertyImageURL: temparray }, () => {
                          console.warn(this.state.propertyImageURL);
                        });
                      }}
                      propertyImageURL={this.state.propertyImageURL}
                      getImage={(e) =>
                        this.setState({
                          propertyImage: [...this.state.propertyImage, e],
                        })
                      }
                      index={7}
                    />
                  </div>
                  <div className="col-sm-2">
                    <Dropzone
                      cancelImageUrl={(i) => {
                        console.warn(i);
                        var temparray = this.state.propertyImageURL.splice(
                          i,
                          1
                        );
                        this.setState({ propertyImageURL: temparray }, () => {
                          console.warn(this.state.propertyImageURL);
                        });
                      }}
                      propertyImageURL={this.state.propertyImageURL}
                      getImage={(e) =>
                        this.setState({
                          propertyImage: [...this.state.propertyImage, e],
                        })
                      }
                      index={8}
                    />
                  </div>
                  <div className="col-sm-2">
                    <Dropzone
                      cancelImageUrl={(i) => {
                        console.warn(i);
                        var temparray = this.state.propertyImageURL.splice(
                          i,
                          1
                        );
                        this.setState({ propertyImageURL: temparray }, () => {
                          console.warn(this.state.propertyImageURL);
                        });
                      }}
                      propertyImageURL={this.state.propertyImageURL}
                      getImage={(e) =>
                        this.setState({
                          propertyImage: [...this.state.propertyImage, e],
                        })
                      }
                      index={9}
                    />
                  </div>
                  <div className="col-sm-2">
                    <Dropzone
                      cancelImageUrl={(i) => {
                        console.warn(i);
                        var temparray = this.state.propertyImageURL.splice(
                          i,
                          1
                        );
                        this.setState({ propertyImageURL: temparray }, () => {
                          console.warn(this.state.propertyImageURL);
                        });
                      }}
                      propertyImageURL={this.state.propertyImageURL}
                      getImage={(e) =>
                        this.setState({
                          propertyImage: [...this.state.propertyImage, e],
                        })
                      }
                      index={10}
                    />
                  </div>
                  <div className="col-sm-2">
                    <Dropzone
                      cancelImageUrl={(i) => {
                        console.warn(i);
                        var temparray = this.state.propertyImageURL.splice(
                          i,
                          1
                        );
                        this.setState({ propertyImageURL: temparray }, () => {
                          console.warn(this.state.propertyImageURL);
                        });
                      }}
                      propertyImageURL={this.state.propertyImageURL}
                      getImage={(e) =>
                        this.setState({
                          propertyImage: [...this.state.propertyImage, e],
                        })
                      }
                      index={11}
                    />
                  </div>
              </div>
              <div className="row">
                  <div className="col-12">
                    <div className="section-title mb-md-0">
                      <h1>{this.state.commercial}</h1>
                      <h4 className="pt-lg-1 pt-2">Categories</h4>
                    </div>
                  </div>
                <div className="col-md-4 d-md-block d-none  mb-3 "></div>
                <div className="col-md-8 d-flex justify-content-end mb-3">
                    <a
                      className={`btn ${
                        this.state.commercial == true
                          ? "btn-yellow"
                          : "btn-yellow-outline"
                      } float-right text-white`}
                      onClick={() =>
                        this.setState(
                          { commercial: true },
                          console.log(this.state.commercial)
                        )
                      }
                    >
                      Commercial Property
                    </a>
                    <a
                      className={`btn ${
                        this.state.commercial == false
                          ? "btn-yellow"
                          : "btn-yellow-outline"
                      } float-right text-white ml-2`}
                      onClick={() => this.setState({ commercial: false })}
                    >
                      Noncommercial Property{" "}
                    </a>
                </div>
                <div className="col-md-4 d-md-block d-none mb-3 "></div>
                  <div className="col-lg-4 col-md-8 mb-3">
                    <a
                      className={`btn ${
                        this.state.rent == false
                          ? "btn-yellow"
                          : "btn-yellow-outline"
                      } float-right text-white ml-2`}
                      onClick={() => this.setState({ rent: false })}
                    >
                      For Buy
                    </a>
                    <a
                      className={`btn ${
                        this.state.rent == true
                          ? "btn-yellow"
                          : "btn-yellow-outline"
                      } float-right text-white`}
                      onClick={() => this.setState({ rent: true })}
                    >
                      For Rent
                    </a>
                  </div>
                <div className="col-4 d-lg-none d-block mb-3 "></div>
                  <div className="col-lg-4 col-md-8 mb-3 text-end">
                    <div className="rld-single-select">
                      <select
                        className="select single-select"
                        onChange={(e) => {
                          this.setState(
                            { property_Type: e.target.value },
                            console.log(e.target.value)
                          );
                        }}
                      >
                        {this.state.commercial ? (
                          <>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.property_Type == ""
                                  ? true
                                  : false
                              }
                              value={""}
                            >
                              Property Type
                            </option>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.property_Type == "Office"
                                  ? true
                                  : false
                              }
                              value={"Office"}
                            >
                              Office
                            </option>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.property_Type == "Shop"
                                  ? true
                                  : false
                              }
                              value={"Shop"}
                            >
                              Shop
                            </option>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.property_Type == "Warehouse"
                                  ? true
                                  : false
                              }
                              value={"Warehouse"}
                            >
                              Warehouse
                            </option>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.property_Type == "Factory"
                                  ? true
                                  : false
                              }
                              value={"Factory"}
                            >
                              Factory
                            </option>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.property_Type == "Building"
                                  ? true
                                  : false
                              }
                              value={"Building"}
                            >
                              Building
                            </option>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.property_Type == "Other"
                                  ? true
                                  : false
                              }
                              value={"Other"}
                            >
                              Other
                            </option>
                          </>
                        ) : (
                          <>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.property_Type == ""
                                  ? true
                                  : false
                              }
                              value={""}
                            >
                              Property Type
                            </option>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.property_Type == "Apartment"
                                  ? true
                                  : false
                              }
                              value={"Apartment"}
                            >
                              Apartment
                            </option>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.property_Type == "Compound"
                                  ? true
                                  : false
                              }
                              value={"Compound"}
                            >
                              Compound
                            </option>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.property_Type == "Duplex"
                                  ? true
                                  : false
                              }
                              value={"Duplex"}
                            >
                              Duplex
                            </option>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.property_Type == "Full Building"
                                  ? true
                                  : false
                              }
                              value={"Full Building"}
                            >
                              Full Building
                            </option>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.property_Type == "Full Floor"
                                  ? true
                                  : false
                              }
                              value={"Full Floor"}
                            >
                              Full Floor
                            </option>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.property_Type == "Hotel Apartment"
                                  ? true
                                  : false
                              }
                              value={"Hotel Apartment"}
                            >
                              Hotel Apartment
                            </option>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.property_Type == "Land"
                                  ? true
                                  : false
                              }
                              value={"Land"}
                            >
                              Land
                            </option>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.property_Type == "Penthouse"
                                  ? true
                                  : false
                              }
                              value={"Penthouse"}
                            >
                              Penthouse
                            </option>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.property_Type == "Townhouse"
                                  ? true
                                  : false
                              }
                              value={"Townhouse"}
                            >
                              Townhouse
                            </option>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.property_Type == "Villa"
                                  ? true
                                  : false
                              }
                              value={"Villa"}
                            >
                              Villa
                            </option>
                          </>
                        )}
                      </select>
                    </div>
                  </div>

                <div className="col-4 d-md-block d-none mb-3 "></div>
              </div>
                <div className="row pd-top-60">
                  <div className="col-md-4">
                    <div className="section-title">
                      <h4>
                        <img
                          src={publicUrl + "assets/img/icons/28.png"}
                          alt="img"
                        />
                        House Name
                      </h4>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="section-title">
                      <CssTextField
                        value={
                          this.props.update == "true"
                            ? this.state.house_title
                            : null
                        }
                        fullWidth
                        label="Title"
                        id="custom-css-outlined-input"
                        onChange={(e) => {
                          this.setState(
                            { house_title: e.target.value },
                            console.log(e.target.value)
                          );
                        }}
                      />
                      <CssTextField
                        value={
                          this.props.update == "true"
                            ? this.state.short_description
                            : null
                        }
                        fullWidth
                        label="Short Description"
                        id="custom-css-outlined-input"
                        margin="dense"
                        onChange={(e) => {
                          this.setState(
                            { short_description: e.target.value },
                            console.log(e.target.value)
                          );
                        }}
                      />
                      <CssTextField
                        value={
                          this.props.update == "true"
                            ? this.state.explanation
                            : null
                        }
                        multiline
                        rows={5}
                        fullWidth
                        label="Explanation"
                        id="custom-css-outlined-input"
                        margin="dense"
                        onChange={(e) => {
                          this.setState(
                            { explanation: e.target.value },
                            console.log(e.target.value)
                          );
                        }}
                      />
                      <CssTextField
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">AED</InputAdornment>
                          ),
                        }}
                        fullWidth
                        value={
                          this.props.update == "true" ? this.state.price : null
                        }
                        label="Price"
                        id="custom-css-outlined-input"
                        margin="dense"
                        onChange={(e) => {
                          this.setState(
                            { price: e.target.value },
                            console.log(e.target.value)
                          );
                        }}
                      />
                    </div>
                    <div className="row">
                      {this.state.commercial ? null : (
                        <>
                            <div className="col-lg-6 mb-3">
                              <div className="rld-single-select">
                                <select
                                  className="select single-select"
                                  onChange={(e) => {
                                    this.setState(
                                      { bed_rooms: e.target.value },
                                      console.log(e.target.value)
                                    );
                                  }}
                                >
                                  <option
                                    selected={
                                      this.props.update == "true" &&
                                      this.state.bed_rooms == 0
                                        ? true
                                        : false
                                    }
                                    value={0}
                                  >
                                    Bedrooms
                                  </option>
                                  <option
                                    selected={
                                      this.props.update == "true" &&
                                      this.state.bed_rooms == 1
                                        ? true
                                        : false
                                    }
                                    value={1}
                                  >
                                    Bedrooms 1
                                  </option>
                                  <option
                                    selected={
                                      this.props.update == "true" &&
                                      this.state.bed_rooms == 2
                                        ? true
                                        : false
                                    }
                                    value={2}
                                  >
                                    Bedrooms 2
                                  </option>
                                  <option
                                    selected={
                                      this.props.update == "true" &&
                                      this.state.bed_rooms == 3
                                        ? true
                                        : false
                                    }
                                    value={3}
                                  >
                                    Bedrooms 3
                                  </option>
                                  <option
                                    selected={
                                      this.props.update == "true" &&
                                      this.state.bed_rooms == 4
                                        ? true
                                        : false
                                    }
                                    value={4}
                                  >
                                    Bedrooms 4
                                  </option>
                                  <option
                                    selected={
                                      this.props.update == "true" &&
                                      this.state.bed_rooms == 5
                                        ? true
                                        : false
                                    }
                                    value={5}
                                  >
                                    Bedrooms 5
                                  </option>
                                  <option
                                    selected={
                                      this.props.update == "true" &&
                                      this.state.bed_rooms == 6
                                        ? true
                                        : false
                                    }
                                    value={6}
                                  >
                                    Bedrooms 6
                                  </option>
                                  <option
                                    selected={
                                      this.props.update == "true" &&
                                      this.state.bed_rooms == 7
                                        ? true
                                        : false
                                    }
                                    value={7}
                                  >
                                    Bedrooms 7+
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div className="col-lg-6 mb-3">
                              <div className="rld-single-select">
                                <select
                                  className="select single-select"
                                  onChange={(e) => {
                                    this.setState(
                                      { bathrooms: e.target.value },
                                      console.log(e.target.value)
                                    );
                                  }}
                                >
                                  <option
                                    selected={
                                      this.props.update == "true" &&
                                      this.state.bathrooms == 0
                                        ? true
                                        : false
                                    }
                                    value={0}
                                  >
                                    Bathrooms
                                  </option>
                                  <option
                                    selected={
                                      this.props.update == "true" &&
                                      this.state.bathrooms == 1
                                        ? true
                                        : false
                                    }
                                    value={1}
                                  >
                                    Bathrooms 1
                                  </option>
                                  <option
                                    selected={
                                      this.props.update == "true" &&
                                      this.state.bathrooms == 2
                                        ? true
                                        : false
                                    }
                                    value={2}
                                  >
                                    Bathrooms 2
                                  </option>
                                  <option
                                    selected={
                                      this.props.update == "true" &&
                                      this.state.bathrooms == 3
                                        ? true
                                        : false
                                    }
                                    value={3}
                                  >
                                    Bathrooms 3
                                  </option>
                                  <option
                                    selected={
                                      this.props.update == "true" &&
                                      this.state.bathrooms == 4
                                        ? true
                                        : false
                                    }
                                    value={4}
                                  >
                                    Bathrooms 4
                                  </option>
                                  <option
                                    selected={
                                      this.props.update == "true" &&
                                      this.state.bathrooms == 5
                                        ? true
                                        : false
                                    }
                                    value={5}
                                  >
                                    Bathrooms 5
                                  </option>
                                  <option
                                    selected={
                                      this.props.update == "true" &&
                                      this.state.bathrooms == 6
                                        ? true
                                        : false
                                    }
                                    value={6}
                                  >
                                    Bathrooms 6
                                  </option>
                                  <option
                                    selected={
                                      this.props.update == "true" &&
                                      this.state.bathrooms == 7
                                        ? true
                                        : false
                                    }
                                    value={7}
                                  >
                                    Bathrooms 7+
                                  </option>
                                </select>
                              </div>
                            </div>
                        </>
                      )}
                      <div className="col-lg-6 mb-3">
                        <CssTextField
                          value={
                            this.props.update == "true" ? this.state.area : null
                          }
                          fullWidth
                          label="Area Sq.f"
                          id="custom-css-outlined-input"
                          margin="dense"
                          onChange={(e) => {
                            this.setState(
                              { area: e.target.value },
                              console.log(e.target.value)
                            );
                          }}
                        />
                      </div>
                      <div className="col-lg-6 mb-3">
                        <CssTextField
                          value={
                            this.props.update == "true" ? this.state.permit : null
                          }
                          fullWidth
                          label="Permit"
                          id="custom-css-outlined-input"
                          margin="dense"
                          onChange={(e) => {
                            this.setState(
                              { permit: e.target.value },
                              console.log(e.target.value)
                            );
                          }}
                        />
                      </div>
                      <div className="col-lg-6 mb-3">
                        <CssTextField
                          value={
                            this.props.update == "true" ? this.state.rara : null
                          }
                          fullWidth
                          label="RERA"
                          id="custom-css-outlined-input"
                          margin="dense"
                          onChange={(e) => {
                            this.setState(
                              { rara: e.target.value },
                              console.log(e.target.value)
                            );
                          }}
                        />
                      </div>
                      <div className="col-lg-6 mb-3">
                        <CssTextField
                          value={
                            this.props.update == "true"
                              ? this.state.reference
                              : null
                          }
                          fullWidth
                          label="Reference"
                          id="custom-css-outlined-input"
                          margin="dense"
                          onChange={(e) => {
                            this.setState(
                              { reference: e.target.value },
                              console.log(e.target.value)
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row pd-top-80">
                  <div className="col-md-4">
                    <div className="section-title">
                      <h4>
                        <img
                          src={publicUrl + "assets/img/icons/29.png"}
                          alt="img"
                        />
                        Address
                      </h4>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="section-title">
                      <h4>Address Here</h4>
                      {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing </p> */}
                    </div>
                    <div className="row">
                      <div className="col-lg-12 mb-3">
                        <CssTextField
                          value={
                            this.props.update == "true"
                              ? this.state.location
                              : null
                          }
                          fullWidth
                          label="Location"
                          id="custom-css-outlined-input"
                          margin="dense"
                          onChange={(e) => {
                            this.setState(
                              { location: e.target.value },
                              console.log(e.target.value)
                            );
                          }}
                        />
                      </div>
                      {/* <div className="col-lg-6 mb-3">
                      <div className="rld-single-select">
                        <select className="select single-select" onChange={(e) => {
                          this.setState({ country: e.target.value }, console.log(e.target.value))
                        }}>
                          <option selected={this.props.update == "true" && this.state.country == 0 ? true : false} value={0}>Country</option>
                          <option selected={this.props.update == "true" && this.state.country == 1 ? true : false} value={1} >Usa</option>
                          <option selected={this.props.update == "true" && this.state.country == 2 ? true : false} value={2}>Canada</option>
                          <option selected={this.props.update == "true" && this.state.country == 3 ? true : false} value={3}>China</option>
                        </select>
                      </div>
                    </div> */}
                      {/* <div className="col-lg-6 mb-3">
                      <div className="rld-single-select">
                        <select className="select single-select" onChange={(e) => {
                          this.setState({ state: e.target.value }, console.log(e.target.value))
                        }}>
                          <option selected={this.props.update == "true" && this.state.state == 0 ? true : false} value={0}>State</option>
                          <option selected={this.props.update == "true" && this.state.state == 1 ? true : false} value={1} >State 1</option>
                          <option selected={this.props.update == "true" && this.state.state == 2 ? true : false} value={2}>State 2</option>
                          <option selected={this.props.update == "true" && this.state.state == 3 ? true : false} value={3}>State 3</option>
                        </select>
                      </div>
                    </div> */}
                      <div className="col-lg-6 mb-3">
                        <div className="rld-single-select">
                          <select
                            className="select single-select"
                            onChange={(e) => {
                              this.setState(
                                { city: e.target.value },
                                console.log(e.target.value)
                              );
                            }}
                          >
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.city == 0
                                  ? true
                                  : false
                              }
                              value={0}
                            >
                              City
                            </option>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.city == 1
                                  ? true
                                  : false
                              }
                              value={1}
                            >
                              Dubai
                            </option>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.city == 2
                                  ? true
                                  : false
                              }
                              value={2}
                            >
                              Abu Dhabi
                            </option>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.city == 3
                                  ? true
                                  : false
                              }
                              value={3}
                            >
                              Sharjah
                            </option>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.city == 4
                                  ? true
                                  : false
                              }
                              value={4}
                            >
                              Ras Al-Khaimah
                            </option>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.city == 5
                                  ? true
                                  : false
                              }
                              value={5}
                            >
                              Ajman
                            </option>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.city == 6
                                  ? true
                                  : false
                              }
                              value={6}
                            >
                              Al Ain
                            </option>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.city == 7
                                  ? true
                                  : false
                              }
                              value={7}
                            >
                              Umm Al Quwain
                            </option>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.city == 8
                                  ? true
                                  : false
                              }
                              value={8}
                            >
                              Fujairah
                            </option>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.city == 9
                                  ? true
                                  : false
                              }
                              value={9}
                            >
                              Khor Fakkan
                            </option>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.city == 10
                                  ? true
                                  : false
                              }
                              value={10}
                            >
                              Fujairah
                            </option>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.city == 11
                                  ? true
                                  : false
                              }
                              value={11}
                            >
                              Dibba Al Fujairah
                            </option>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.city == 12
                                  ? true
                                  : false
                              }
                              value={12}
                            >
                              Dibba Al Hisn
                            </option>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.city == 13
                                  ? true
                                  : false
                              }
                              value={13}
                            >
                              Zayed City
                            </option>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.city == 14
                                  ? true
                                  : false
                              }
                              value={14}
                            >
                              Kalba
                            </option>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.city == 15
                                  ? true
                                  : false
                              }
                              value={15}
                            >
                              Al Dhaid
                            </option>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.city == 16
                                  ? true
                                  : false
                              }
                              value={16}
                            >
                              Al Ruways Industrial City
                            </option>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.city == 17
                                  ? true
                                  : false
                              }
                              value={17}
                            >
                              Hatta
                            </option>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.city == 18
                                  ? true
                                  : false
                              }
                              value={18}
                            >
                              Ras al Khaimah
                            </option>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.city == 19
                                  ? true
                                  : false
                              }
                              value={19}
                            >
                              Umm Al Quawain
                            </option>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.city == 20
                                  ? true
                                  : false
                              }
                              value={20}
                            >
                              Mina Jebel Ali
                            </option>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.city == 21
                                  ? true
                                  : false
                              }
                              value={21}
                            >
                              Masfut
                            </option>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.city == 22
                                  ? true
                                  : false
                              }
                              value={22}
                            >
                              Ghiyathi
                            </option>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.city == 23
                                  ? true
                                  : false
                              }
                              value={23}
                            >
                              Mirbah
                            </option>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.city == 24
                                  ? true
                                  : false
                              }
                              value={24}
                            >
                              Al Madam
                            </option>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.city == 25
                                  ? true
                                  : false
                              }
                              value={25}
                            >
                              Musaffah
                            </option>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.city == 26
                                  ? true
                                  : false
                              }
                              value={26}
                            >
                              Al Bidya
                            </option>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.city == 27
                                  ? true
                                  : false
                              }
                              value={27}
                            >
                              Al Sila
                            </option>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.city == 28
                                  ? true
                                  : false
                              }
                              value={28}
                            >
                              Al Faqa
                            </option>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.city == 29
                                  ? true
                                  : false
                              }
                              value={29}
                            >
                              Lahbab
                            </option>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.city == 30
                                  ? true
                                  : false
                              }
                              value={30}
                            >
                              Al Aweer
                            </option>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.city == 31
                                  ? true
                                  : false
                              }
                              value={31}
                            >
                              Dhadna
                            </option>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.city == 32
                                  ? true
                                  : false
                              }
                              value={32}
                            >
                              Al Hamraniyah
                            </option>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.city == 33
                                  ? true
                                  : false
                              }
                              value={33}
                            >
                              Al jeer
                            </option>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.city == 34
                                  ? true
                                  : false
                              }
                              value={34}
                            >
                              Al Raafah
                            </option>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.city == 35
                                  ? true
                                  : false
                              }
                              value={35}
                            >
                              Sha'am
                            </option>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.city == 36
                                  ? true
                                  : false
                              }
                              value={36}
                            >
                              Al Lisaili
                            </option>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.city == 37
                                  ? true
                                  : false
                              }
                              value={37}
                            >
                              Muzayri
                            </option>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.city == 38
                                  ? true
                                  : false
                              }
                              value={38}
                            >
                              Al Bithnah
                            </option>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.city == 39
                                  ? true
                                  : false
                              }
                              value={39}
                            >
                              Asimah
                            </option>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.city == 40
                                  ? true
                                  : false
                              }
                              value={40}
                            >
                              Al Halah
                            </option>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.city == 41
                                  ? true
                                  : false
                              }
                              value={41}
                            >
                              Zubara
                            </option>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.city == 42
                                  ? true
                                  : false
                              }
                              value={42}
                            >
                              Al Jazirah Al Hamra
                            </option>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.city == 43
                                  ? true
                                  : false
                              }
                              value={43}
                            >
                              Al Batayih
                            </option>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.city == 44
                                  ? true
                                  : false
                              }
                              value={44}
                            >
                              Maleha
                            </option>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.city == 45
                                  ? true
                                  : false
                              }
                              value={45}
                            >
                              Al Manama
                            </option>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.city == 46
                                  ? true
                                  : false
                              }
                              value={46}
                            >
                              Masdar City
                            </option>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.city == 47
                                  ? true
                                  : false
                              }
                              value={47}
                            >
                              Adhen Village
                            </option>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.city == 48
                                  ? true
                                  : false
                              }
                              value={48}
                            >
                              Qur
                            </option>
                            <option
                              selected={
                                this.props.update == "true" &&
                                this.state.city == 49
                                  ? true
                                  : false
                              }
                              value={49}
                            >
                              Diqdaqah
                            </option>
                          </select>
                        </div>
                      </div>

                      {/* <div className="col-lg-6 mb-41">
                      <div className="rld-single-select">
                        <select className="select single-select" onChange={(e) => {
                          this.setState({ zip_code: e.target.value }, console.log(e.target.value))
                        }}>
                          <option selected={this.props.update == "true" && this.state.zip_code == 0 ? true : false} value={0}>Zip Code</option>
                          <option selected={this.props.update == "true" && this.state.zip_code == 1 ? true : false} value={1} >Zip 1</option>
                          <option selected={this.props.update == "true" && this.state.zip_code == 2 ? true : false} value={2}>Zip 2</option>
                          <option selected={this.props.update == "true" && this.state.zip_code == 3 ? true : false} value={3}>Zip 3</option>
                        </select>
                      </div>
                    </div> */}
                    </div>
                  </div>
                </div>
                <div className="row pd-top-80">
                  <div className="col-md-4">
                    <div className="section-title">
                      <h4>
                        <img
                          src={publicUrl + "assets/img/icons/31.png"}
                          alt="img"
                        />
                        Amenities
                      </h4>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="row">
                      <div className="col-sm-4">
                        <ul className="rld-list-style mb-3 mb-sm-0">
                          <li>
                            <Checkbox
                              checked={this.state.AC == true ? true : false}
                              onClick={() => {
                                this.setState({ AC: !this.state.AC });
                              }}
                              icon={<i className="fa fa-chec" />}
                              checkedIcon={<i className="fa fa-check" />}
                            />{" "}
                            AC
                          </li>
                          <li>
                            <Checkbox
                              checked={this.state.Pets == true ? true : false}
                              onClick={() => {
                                this.setState({ Pets: !this.state.Pets });
                              }}
                              icon={<i className="fa fa-chec" />}
                              checkedIcon={<i className="fa fa-check" />}
                            />{" "}
                            Pets
                          </li>
                        </ul>
                      </div>
                      <div className="col-sm-4">
                        <ul className="rld-list-style mb-3 mb-sm-0">
                          <li>
                            <Checkbox
                              checked={
                                this.state.FrontYard == true ? true : false
                              }
                              onClick={() => {
                                this.setState({
                                  FrontYard: !this.state.FrontYard,
                                });
                              }}
                              icon={<i className="fa fa-chec" />}
                              checkedIcon={<i className="fa fa-check" />}
                            />{" "}
                            Front Yard
                          </li>
                          <li>
                            <Checkbox
                              checked={this.state.Pool == true ? true : false}
                              onClick={() => {
                                this.setState({ Pool: !this.state.Pool });
                              }}
                              icon={<i className="fa fa-chec" />}
                              checkedIcon={<i className="fa fa-check" />}
                            />{" "}
                            Pool
                          </li>
                        </ul>
                      </div>
                      <div className="col-sm-4">
                        <ul className="rld-list-style mb-3 mb-sm-0">
                          <li>
                            <Checkbox
                              checked={this.state.Balcony == true ? true : false}
                              onClick={() => {
                                this.setState({ Balcony: !this.state.Balcony });
                              }}
                              icon={<i className="fa fa-chec" />}
                              checkedIcon={<i className="fa fa-check" />}
                            />{" "}
                            Balcony
                          </li>
                          <li>
                            <Checkbox
                              checked={this.state.SeaView == true ? true : false}
                              onClick={() => {
                                this.setState({ SeaView: !this.state.SeaView });
                              }}
                              icon={<i className="fa fa-chec" />}
                              checkedIcon={<i className="fa fa-check" />}
                            />{" "}
                            Sea View
                          </li>
                        </ul>
                      </div>
                      <div className="col-12 mt-5">
                        {this.state.loading == true ? (
                          <button className="btn btn-yellow" disabled>
                            <i class="fa fa-refresh fa-spin"></i> Loading...
                          </button>
                        ) : (
                          <button
                            className="btn btn-yellow"
                            onClick={() => {
                              this.validation();
                            }}
                          >
                            {this.props.update == "true"?
                            "Update Property"
                            :
                            "Publish Property"
                            }
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddNew;
