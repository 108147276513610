import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';
import { TextField, Checkbox } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import main from "./../../video/main.mp4";
import style from "../../assets/css/style.css";
import AuthHelper from '../../Helpers/AuthHelper';
import Button from '@mui/material/Button';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import cookie from 'react-cookies';
import { Link } from 'react-router-dom';






const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#fda94f',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#fda94f',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#e5e5e9',
    },
    '&:hover fieldset': {
      borderColor: '#fda94f',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#fda94f',
    },
  },
});

class loginAsAdmin_component extends Component {

  constructor(props) {
    super(props);

    this.state = {
      agency: false,
      email_login: '',
      password_login: '',
      messages: '',

    };
  }

 
 
  handleLoginAsAdmin = () => {
    var data = {
      email: this.state.email_login,
      password: this.state.password_login,
    };
    AuthHelper.LoginAsAdmin(data).then((resp) => {
      if (resp.data.status != "success") {
        this.setState({ messages: resp.data.status });
        this.alert("error",resp.data.msg);

      }else {
        cookie.save('Tokken', resp.data.data.tokken , { path: '/' })
        cookie.save('user_id', resp.data.data.user_id , { path: '/' })
        cookie.save('title', resp.data.data.userName , { path: '/' })   
        cookie.save('role', "Admin" , { path: '/' })  
        window.location.href="/"

             
      }
    });
    
  };

  alert = (icon,msg) =>{
    Swal.fire({
      position: 'center',
      padding:"500px , 0px",
      icon: icon,
      title: msg,
      showConfirmButton: false,
      timer: 3000,
      width:"500px",
      timerProgressBar:true,
      
    });
  };
 


  render() {

    let publicUrl = '' + '/'
    let imagealt = 'image'
    let data = sectiondata.aboutus
    const Input = styled('input')({
      display: 'none',
    });


    function saving_data() {
      alert("hi")
    }
    return <div className='log_background_image'>
      <div className="col-xl-4 col-lg-5 col-md-6" style={{ marginLeft: "auto", paddingTop: "100px" }}>
        <div className="contact-form-wrap " style={{marginTop:"150px",marginBottom:'150px'}}>
        <div className="row ">
            <div className="col-lg-4"><h4>Signup As</h4></div>
            <div className="col-lg-3 mb-3">
              <a className={`btn ${this.state.agency == false ? "btn-yellow" : "btn-yellow-outline"} float-right text-white ml-2`} onClick={() => this.setState({ agency: false })}>Admin</a>
            </div>
          </div>


            <div className="col-md-12">
              <div className="section-title">
                <CssTextField fullWidth label="Email" id="custom-css-outlined-input" onChange={(e) => {
                  this.setState({ email_login: e.target.value }, console.log(e.target.value))
                }} />
                <CssTextField fullWidth label="Password" id="custom-css-outlined-input" margin="dense" onChange={(e) => {
                  this.setState({ password_login: e.target.value }, console.log(e.target.value))
                }} />
                <div style={{textAlign:"end"}}><Link to="/">Forget Password</Link></div>

              </div>
            </div>
            <div>
              <div className="btn-wrap " style={{textAlign:"center"}}>
                  <button style={{width:"330px",margin:'0px'}} onClick={() => this.handleLoginAsAdmin()} className="btn btn-yellow">Sign In As Admin</button>
              </div>
            </div>
          
        </div>
      </div>
    </div>

  }
}

export default loginAsAdmin_component