import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import UserListSection from './section-components/user-list';
import Footer from './global-components/footer';
import banner from "./../assets/img/agencies.jpg"

const UserList = () => {
    return <div>
        {/* <img src={banner} width="100%" style={{position:'relative',top:"0px"}}/> */}
        <Navbar />
        <PageHeader video={banner} headertitle="Find Agencies" />
        <UserListSection />
        <Footer />
    </div>
}

export default UserList

