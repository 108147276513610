import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';

export default function RangeSlider(props) {
  const [value, setValue] = React.useState([600]);

  const handleChange = (event, newValue) => {
    props.getValue(newValue);
    setValue(newValue);
  };

  const valuetext = (value) => {
    return `AED ${value}`;
  }


  return (
    <Box>
      <Slider
        min={0}
        step={100}
        max={50000}
        getAriaLabel={() => 'Range Size'}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        color="secondary"
      />
    </Box>
  );
}
