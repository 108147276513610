import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import RegistraionSection from './section-components/registration';
import Footer from './global-components/footer';
import main from "./../video/main.mp4"

const Registration = () => {
    return <div>
        <Navbar />
        <PageHeader positon="0% 102%" video={main} headertitle="Registetion" />
        <RegistraionSection />
        <Footer />
    </div>
}

export default Registration

