import React, { Component } from "react";
import sectiondata from "../../data/sections.json";
import InputRange from "react-input-range";
import ProductHelper from "../../Helpers/ProductHelper";
import { Box, Slider } from "@mui/material";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import "react-input-range/lib/css/index.css";
import RangeSlider from "../../components/section-components/rangeSlider";
import RangeSize from "../../components/section-components/rangeSize";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import List from "../../assets/img/list.png";
import Grid from "../../assets/img/grid.png";
import notFound from "../../assets/img/notFound.png";
import FavoriteBtn from "./favorite-btn";
import config from "../../config";
import algoliasearch from "algoliasearch";
import Skeleton from "react-skeleton-loader";
import SkeletonItem from "./skeleton";
import Bounce from 'react-reveal/Slide';


const client = algoliasearch("14KGKZU3TO", "6474cd7bd67e366edd2958bdcfdecf38");
const index = client.initIndex("Location");

class SearchGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: { min: 2, max: 10 },
      data: [],
      priceRange: [0, 0],
      landmark: "",
      propertyType: 0,
      rangeSize: [0, 0],
      parking: 1,
      bathroom: 0,
      bedroom: 0,
      pool: false,
      pets: false,
      ac: false,
      waterView: false,
      rent:
        this.props.rent == true
          ? true
          : this.props.rent == false
            ? false
            : window.location.pathname.includes("buy")
              ? false
              : window.location.pathname.includes("rent")
                ? true
                : false,
      commercial: window.location.pathname.includes("commercial")
        ? true
        : false,
      searchList: [],
      landmark_search: false,
      loading: true,
      pageNo: 1,
    };
  }

  // shouldComponentUpdate(nextProps, nextState){
  //   console.warn("nextProps:",nextProps);
  //   console.warn("nextState:",nextState);
  //   if (nextProps.rent !== this.props.rent) {
  //     this.findProperty()
  //     return true;
  //   } else {
  //     this.findProperty()
  //     return false;
  //   }
  // }

  componentDidMount = () => {
    console.log("this.props.rent", this.state.rent);
    this.algoliaSeach();
    console.log("this.props.datathis.props.data", this.props.data);
    if (this.props.data) {
      //todo will do props filter later
      // this.setState({
      //   bedroom: this.props.data.NumberOfRooms,
      //   priceRange: this.props.data.Price,
      //   landmark: this.props.data.landmark_location,
      //   propertyType: this.props.data.property_Type,
      // }, () => {
      //   this.findProperty()
      // })
    }
    // ProductHelper.Get().then((resp) => {
    //   this.setState({ data: resp.data.data.product, loading: false })
    // })
    this.findProperty();
  };

  algoliaSeach = () => {
    var searchList = [];
    index.search(this.state.landmark, {}).then(({ hits }) => {
      if (hits.length > 0) {
        hits.forEach((e) => {
          searchList.push({
            id: e.objectID,
            location: e.location,
            text: e._highlightResult.location.value,
          });
        });
        this.setState({
          searchList: searchList,
        });
      }
    });
  };

  findProperty = () => {
    this.setState({
      loading: true,
    });
    var data = {
      landmark: this.state.landmark,
      propertyType: this.state.propertyType,
      priceRange: this.state.priceRange,
      rangeSize: this.state.rangeSize,
      bathroom: this.state.bathroom,
      bedroom: this.state.bedroom,
      parking: this.state.parking,
      pool: this.state.pool,
      pets: this.state.pets,
      ac: this.state.ac,
      waterView: this.state.waterView,
      rent: this.state.rent,
      pageNo: this.state.pageNo,
      commercial: this.state.commercial,
    };
    console.log("data.....", data);
    ProductHelper.FilterProduct(data)
      .then((resp) => {
        if (this.state.pageNo > 1) {
          this.setState({
            data: [...this.state.data, ...resp.data.data.products],
            loading: false,
          });
        } else {
          this.setState({ data: resp.data.data.products, loading: false });
        }
      })
      .catch((e) => { });
  };

  render() {
    let publicUrl = process.env.PUBLIC_URL;
    let imagealt = "image";
    let data = sectiondata.searchgrid;
    console.log(this.state.data);

    return (
      <div className="search-page-wrap pd-bottom-70">
        {this.state.landmark_search ? (
          <div
            onClick={() => this.setState({ landmark_search: false })}
            className="landmark_searchbox_overlay"
          ></div>
        ) : null}
        <div className="search-container">
          <div className="container mt-5">
            <div className="row ">
              <div className="col-xl-3 col-lg-4 sitebar">
                <h6 className="filter-title mb-4">
                  <img
                    className="mr-3"
                    src={publicUrl + "/assets/img/icons/18.png"}
                    alt="img"
                  />
                  Filter
                </h6>
                <form className="widget widget-sidebar-search-wrap col-12">
                  <div className="widget-sidebar-search col-12 p-0">
                    <div className="title">Any Price</div>
                    <div className="widget-sidebar-item-wrap btn-area col-12 ">
                      <div className="row d-flex justify-content-around">
                        <div className="col-lg-5 col-12 p-0">
                          <Link
                            to={this.state.commercial ? "/commercial-buy" : "/buy"}
                            // onClick={() => this.findProperty()}
                            className={`btn ${this.state.rent == false
                              ? "btn-yellow"
                              : "btn-yellow-outline"
                              } float-right text-white w-100`}
                          >
                            For Buy
                          </Link>
                        </div>
                        <div className="col-lg-5 col-12 p-0 mt-lg-0 mt-1">
                          <Link
                            to={
                              this.state.commercial ? "/commercial-rent" : "/rent"
                            }
                            // onClick={() => this.findProperty()}
                            className={`btn ${this.state.rent == true
                              ? "btn-yellow"
                              : "btn-yellow-outline"
                              } float-right text-white w-100`}
                          >
                            For Rent
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="title">Commercial</div>
                    <div className="widget-sidebar-item-wrap btn-area col-12 ">
                      <div className="row d-flex justify-content-around">
                        <div className=" col-12 p-0">
                          <Link
                            to={this.state.rent ? "/rent" : "/buy"}
                            // onClick={() => this.findProperty()}
                            className={`btn ${this.state.commercial == false
                              ? "btn-yellow"
                              : "btn-yellow-outline"
                              } float-right text-white w-100`}
                          >
                            Non Commercial
                          </Link>
                        </div>
                        <div className=" col-12 p-0 mt-1">
                          <Link
                            to={
                              this.state.rent
                                ? "/commercial-rent"
                                : "/commercial-buy"
                            }
                            // onClick={() => this.findProperty()}
                            className={`btn ${this.state.commercial == true
                              ? "btn-yellow"
                              : "btn-yellow-outline"
                              } float-right text-white w-100`}
                          >
                            Commercial
                          </Link>
                        </div>
                      </div>

                    </div>
                    <div className="widget-sidebar-item-wrap rld-single-input left-icon position-relative">
                      <input
                        type="text"
                        placeholder="Entry Landmark Location"
                        value={this.state.landmark}
                        onClick={() => {
                          this.setState({ landmark_search: true });
                        }}
                        onChange={(e) => {
                          this.setState({ landmark: e.target.value }, () => {
                            this.algoliaSeach();
                          });
                        }}
                      />
                      {this.state.landmark_search ? (
                        <div id="landmark_searchbox">
                          <ul className="list-style-none py-2 pl-0">
                            {this.state.searchList.map((e, i) => (
                              <li
                                onClick={() => {
                                  this.setState(
                                    {
                                      landmark: e.location,
                                      landmark_search: false,
                                    },
                                    () => {
                                      this.findProperty();
                                    }
                                  );
                                }}
                                dangerouslySetInnerHTML={{ __html: e.text }}
                              ></li>
                            ))}
                          </ul>
                        </div>
                      ) : null}
                    </div>
                    <div className="widget-sidebar-item-wrap rld-single-select">
                      <select
                        className="select single-select"
                        onChange={(e) => {
                          this.setState(
                            { propertyType: e.target.value });
                        }}
                      >
                        <option
                          value={0}
                          selected={
                            this.state.propertyType == "0" ? true : null
                          }
                        >
                          Property Type
                        </option>
                        <option
                          value={"Apartment"}
                          selected={
                            this.state.propertyType == "1" ? true : null
                          }
                        >
                          Apartment
                        </option>
                        <option
                          value={"Villa"}
                          selected={
                            this.state.propertyType == "2" ? true : null
                          }
                        >
                          Villa
                        </option>
                        <option
                          value={3}
                          selected={
                            this.state.propertyType == "3" ? true : null
                          }
                        >
                          Townhouse
                        </option>
                        <option
                          value={4}
                          selected={
                            this.state.propertyType == "4" ? true : null
                          }
                        >
                          Penthouse
                        </option>
                        <option
                          value={5}
                          selected={
                            this.state.propertyType == "5" ? true : null
                          }
                        >
                          Land
                        </option>
                        <option
                          value={6}
                          selected={
                            this.state.propertyType == "6" ? true : null
                          }
                        >
                          Full Building
                        </option>
                        <option
                          value={7}
                          selected={
                            this.state.propertyType == "7" ? true : null
                          }
                        >
                          Hotel Apartment
                        </option>
                        <option
                          value={8}
                          selected={
                            this.state.propertyType == "8" ? true : null
                          }
                        >
                          Compound
                        </option>
                        <option
                          value={9}
                          selected={
                            this.state.propertyType == "9" ? true : null
                          }
                        >
                          Duplex
                        </option>
                        <option
                          value={10}
                          selected={
                            this.state.propertyType == "10" ? true : null
                          }
                        >
                          Full Floor
                        </option>
                      </select>
                    </div>
                    <div className="widget-sidebar-item-wrap rld-price-slider-wrap">
                      <div className="title">Any Price</div>
                      <RangeSlider
                        setValue={this.state.priceRange}
                        getValue={(e) => {
                          this.setState({ priceRange: e });
                        }}
                      />
                    </div>
                    <div className="widget-sidebar-item-wrap rld-price-slider-wrap">
                      <div className="title">Size (sq feet)</div>
                      <RangeSlider
                        setValue={this.state.rangeSize}
                        getValue={(e) => {
                          this.setState({ rangeSize: e });
                        }}
                      />
                    </div>
                    <div className="row">
                      <div className="col">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                onClick={() => {
                                  this.setState({ ac: !this.state.ac });
                                }}
                                sx={{
                                  color: "#fda94f",
                                  "&.Mui-checked": {
                                    color: "#fda94f",
                                  },
                                }}
                              />
                            }
                            label="AC"
                          />
                        </FormGroup>
                      </div>
                      <div className="col">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                onClick={() => {
                                  this.setState({ pets: !this.state.pets });
                                }}
                                sx={{
                                  color: "#fda94f",
                                  "&.Mui-checked": {
                                    color: "#fda94f",
                                  },
                                }}
                              />
                            }
                            label="Pets"
                          />
                        </FormGroup>
                      </div>
                      <div className="col">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                onClick={() => {
                                  this.setState({ pool: !this.state.pool });
                                }}
                                sx={{
                                  color: "#fda94f",
                                  "&.Mui-checked": {
                                    color: "#fda94f",
                                  },
                                }}
                              />
                            }
                            label="Pool"
                          />
                        </FormGroup>
                      </div>
                      <div className="col">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                onClick={() => {
                                  this.setState({ waterView: !this.state.waterView });
                                }}
                                sx={{
                                  whiteSpace: "nowrap",
                                  color: "#fda94f",
                                  "&.Mui-checked": {
                                    color: "#fda94f",
                                  },
                                }}
                              />
                            }
                            label={
                              <span style={{ whiteSpace: "nowrap" }}>
                                Water View
                              </span>
                            }
                          />
                        </FormGroup>
                      </div>
                    </div>

                    <div className="widget-sidebar-item-wrap rld-single-select-wrap mt-4 col-12">
                      <div className="row">
                        <div className=" col-md-5 col-12 p-0">
                          <div className="title d-inline-block  mb-0 pt-2 mr-2">
                            Bedroom
                          </div>
                        </div>
                        <div className=" col-md-7 col-12 p-0">
                          <div className="rld-single-select d-inline-block  w-100">
                            <select
                              onChange={(e) => {

                                console.log('e.target.value', typeof e.target.value);
                                this.setState({ bedroom: e.target.value });
                              }}
                              className="select single-select"
                            >
                              <option
                                value={0}
                                selected={this.state.bedroom == 0 ? true : null}
                              >
                                Any
                              </option>
                              <option
                                value={1}
                                selected={this.state.bedroom == 1 ? true : null}
                              >
                                1
                              </option>
                              <option
                                value={2}
                                selected={this.state.bedroom == 2 ? true : null}
                              >
                                2
                              </option>
                              <option
                                value={3}
                                selected={this.state.bedroom == 3 ? true : null}
                              >
                                3
                              </option>
                              <option
                                value={4}
                                selected={this.state.bedroom == 4 ? true : null}
                              >
                                4
                              </option>
                              <option
                                value={5}
                                selected={this.state.bedroom == 5 ? true : null}
                              >
                                5
                              </option>
                              <option
                                value={6}
                                selected={this.state.bedroom == 6 ? true : null}
                              >
                                6
                              </option>
                              <option
                                value={7}
                                selected={this.state.bedroom == 7 ? true : null}
                              >
                                7
                              </option>
                              <option
                                value={8}
                                selected={this.state.bedroom == 8 ? true : null}
                              >
                                8
                              </option>
                              <option
                                value={9}
                                selected={this.state.bedroom == 9 ? true : null}
                              >
                                9
                              </option>
                              <option
                                value={10}
                                selected={this.state.bedroom == 10 ? true : null}
                              >
                                10
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="widget-sidebar-item-wrap rld-single-select-wrap col-12">
                      <div className="row">
                        <div className=" col-md-5 col-12 p-0">
                          <div className="title d-inline-block  mb-0 pt-2 mr-2">
                            Bathroom
                          </div>
                        </div>
                        <div className=" col-md-7 col-12 p-0">
                          <div className="rld-single-select d-inline-block w-100">
                            <select
                              onChange={(e) => {
                                this.setState({ bathroom: e.target.value });
                              }}
                              className="select single-select"
                            >
                              <option value={0}>Any</option>
                              <option value={1}>1</option>
                              <option value={2}>2</option>
                              <option value={3}>3</option>
                              <option value={4}>4</option>
                              <option value={5}>5</option>
                              <option value={6}>6</option>
                              <option value={7}>7</option>
                              <option value={8}>8</option>
                              <option value={9}>9</option>
                              <option value={10}>10</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="btn-wrap text-center">
                    <div
                      className="btn btn-yellow"
                      onClick={() => this.findProperty()}
                    >
                      <span className="left">
                        <i className="fa fa-search" />
                      </span>
                      Find Property
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-xl-9 col-lg-8">
                <div className="row mb-3">
                  <div className="col-md-9 col-sm-8">
                    <h6 className="filter-title mt-3 mb-lg-0">{`${this.state.data.length} Properties`}</h6>
                  </div>
                  <div className="col-md-3 col-sm-4">
                    {/* <div className='justify-content-end d-flex'>
                    <Link to={{ pathname: "/properties", query: "" }}>
                      <img className='mx-1' src={Grid} width="20px" />
                    </Link>
                    <Link to={{ pathname: "/properties/list", query: "" }}>
                      <img className='mx-1' src={List} width="20px" />
                    </Link>
                  </div> */}
                  </div>
                </div>
                <Bounce bottom>
                  <div className="row ">
                    {!this.state.loading ? (
                      this.state.data.length > 0 ? (
                        this.state.data.map((item, i) => (
                          <div key={i} className="col-xl-4 col-sm-6 col-12">
                            <div className="single-feature">
                              <div className="thumb">
                                <img
                                style={{height:"230px"}}
                                  src={
                                    item.propertyImages
                                      ? JSON.parse(item.propertyImages)[0]
                                      : config.product_image_url + item.img
                                  }
                                  alt="img"
                                />
                              </div>
                              <div className="details">
                                <div className="feature-logo">
                                  <img src={item.agency.img} alt={imagealt} />
                                </div>
                                {/* <p className="author"><i className="fa fa-user" /></p> */}
                                <p className="location">
                                  <Link
                                    to={{
                                      pathname: "/propertydetails",
                                      state: { i: i, data: item },
                                    }}
                                  >
                                    {item.location}
                                  </Link>
                                </p>
                                <h6 className="title readeal-top">
                                  <Link
                                    to={{
                                      pathname: "/propertydetails",
                                      state: { i: i, data: item },
                                    }}
                                  >
                                    {item.house_title.length < 60
                                      ? item.house_title
                                      : item.house_title.slice(0, 60) + "..."}
                                  </Link>
                                </h6>
                                <h6 className="price">AED {item.price.toLocaleString()}</h6>
                                <del>{item.olderprice}</del>
                                <ul className="info-list">
                                  {/* { item.features.map( ( features, i )=>
                                  <li key={ i } ><i className={ features.icon } /> { features.title }</li>
                                  ) }  */}
                                  <li>
                                    <i className={"fa fa-bed"} /> {item.bed_rooms}{" "}
                                    Bed
                                  </li>
                                  <li>
                                    <i className={"fa fa-bath"} />{" "}
                                    {item.bathrooms} Bath
                                  </li>
                                  <li>
                                    <img
                                      src={publicUrl + "/assets/img/icons/7.png"}
                                      alt={imagealt}
                                    />{" "}
                                    {item.area} sq.{" "}
                                  </li>
                                </ul>
                                <ul className="contact-list">
                                  <li>
                                    <a
                                      className="phone"
                                      href={`tel:${item.agency.phone}`}
                                    >
                                      <i className="fa fa-phone" />
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="message"
                                      href={`mailto:${item.agency.email}`}
                                    >
                                      <img
                                        src={
                                          publicUrl + "/assets/img/icons/8.png"
                                        }
                                        alt="img"
                                      />
                                    </a>
                                  </li>
                                  <FavoriteBtn value={item._id} />
                                  <li className="readeal-top">
                                    <Link
                                      className="btn btn-yellow"
                                      to={{
                                        pathname: "/propertydetails",
                                        state: { i: i, data: item },
                                      }}
                                    >
                                      View Details
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="d-flex justify-content-center">
                          <img src={notFound} width="80%" />
                        </div>
                      )
                    ) : (
                      <>
                        <SkeletonItem />
                        <SkeletonItem />
                        <SkeletonItem />
                        <SkeletonItem />
                        <SkeletonItem />
                        <SkeletonItem />
                        <SkeletonItem />
                        <SkeletonItem />
                        <SkeletonItem />
                      </>
                    )}
                  </div>
                </Bounce>
                <div className="d-flex justify-content-center">
                  <button
                    style={{ borderRadius: "25px", width: "30%" }}
                    onClick={() => {
                      this.setState({ pageNo: ++this.state.pageNo }, () => {
                        this.findProperty();
                      });
                    }}
                    type="button"
                    class="btn btn-yellow mt-2"
                  >
                    {
                    this.state.data.length > 0 ?
                      "Load More"
                      :
                      "Reload"
                    }
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SearchGrid;
