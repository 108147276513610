import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';
import { TextField, Checkbox } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import Banner from '../../assets/img/welcomebanner.jpg';
import check from '../../assets/img/checksign.png';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#fda94f',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#fda94f',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#e5e5e9',
    },
    '&:hover fieldset': {
      borderColor: '#fda94f',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#fda94f',
    },
  },
});

class AddNew extends Component {

  constructor(props) {
    super(props);


  }




  render() {

    let publicUrl = '' + '/'
    let imagealt = 'image'
    let data = sectiondata.aboutus

    function saving_data() {
      alert("hi")
    }
    return <div className="add-new-property-area pd-top-90 ">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-9 col-lg-10">
            <div className="section-title text-center">
              <h3 style={{marginTop:"0px"}}>Are you a property agent?</h3>
            </div>

            <div className="row">

              <div className="col-lg-12 mb-3 text-end">
                <p>
                  Join the Mulqiat network and start reaching active buyers, sellers and renters in the UAE, as well as gaining access to exclusive benefits to grow your business.
                </p>
                <p>
                  We are committed to offering you
                </p>
              </div>
              <div className="col-lg-12 mb-3 text-end">
                <div className='row'>
                  <div className="col-lg-4 mb-3 text-end">
                    <Card sx={{ maxWidth: 345,height:"190px", backgroundColor:"#f9f9f9"}}>
                      <img src={check} width="50" style={{marginLeft: "16px",marginTop:"15px"}} />

                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          Sustainable pricing package - FREE for a limited time
                        </Typography>
                      </CardContent>
                    </Card>
                  </div>
                  <div className="col-lg-4 mb-3 text-end">
                  <Card sx={{ maxWidth: 345,height:"190px", backgroundColor:"#f9f9f9"}}>
                      <img src={check} width="50" style={{marginLeft: "16px",marginTop:"15px"}} />

                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          Competitive rates for software and PropTech tools
                        </Typography>
                      </CardContent>
                    </Card>
                  </div>
                  <div className="col-lg-4 mb-3 text-end">
                  <Card sx={{ maxWidth: 345,height:"190px", backgroundColor:"#f9f9f9"}}>
                      <img src={check} width="50" style={{marginLeft: "16p",marginTop:"15px"}} />

                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          Consistently accurate and high quality listings
                        </Typography>
                      </CardContent>
                    </Card>
                  </div>
                  <div className="col-lg-4 mb-3 text-end">
                    <Card sx={{ maxWidth: 345,height:"190px", backgroundColor:"#f9f9f9"}}>
                      <img src={check} width="50" style={{marginLeft: "16px",marginTop:"15px"}} />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          Increased market exposure to property seekers
                        </Typography>
                      </CardContent>
                    </Card>
                  </div>
                  <div className="col-lg-4 mb-3 text-end">
                    <Card sx={{ maxWidth: 345,height:"190px", backgroundColor:"#f9f9f9"}}>
                      <img src={check} width="50" style={{marginLeft: "16px",marginTop:"15px"}} />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          High quality insights to help you close your deal
                        </Typography>
                      </CardContent>
                    </Card>
                  </div>
                  <div className="col-lg-4 mb-3 text-end">
                    <Card sx={{ maxWidth: 345,height:"190px", backgroundColor:"#f9f9f9"}}>
                      <img src={check} width="50" style={{marginLeft: "16px",marginTop:"15px"}} />

                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          A portal created for, and by, real estate agencies
                        </Typography>
                      </CardContent>
                    </Card>
                  </div>
                </div>

              </div>

              <p style={{padding:"0px 15px"}}>
                If you want to list your properties on Mulqiat.com and begin generating new leads straight away, please <Link to="/login" className='text-primary'>Sign Up</Link> as an Agent.
              </p>




            </div>

          </div>
        </div>
      </div>
    </div>

  }
}

export default AddNew